import { useTranslate } from "infrastructure/hooks/useTranslate"

export const validate = (values) => {
  const errors = {}

  if (!values.name) errors.name = useTranslate("validation.required")
  if (!values.surname) errors.surname = useTranslate("validation.required")
  if (!values.groups) errors.groups = useTranslate("validation.required")

  return errors
}
