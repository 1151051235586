import React, { useEffect } from "react"
import { useContextSelector } from "use-context-selector"

import { dataProvider } from "domain/core/dataProvider"

export const FetchEntityContainer = ({
  url,
  resourceContext,
  resourceSelectors,
  params,
  options = {},
}) => {
  const item = useContextSelector(resourceContext, resourceSelectors.item)
  const update = useContextSelector(resourceContext, resourceSelectors.update)

  useEffect(() => {
    update({
      item: {
        ...item,
        error: null,
        isLoading: true,
      },
    })

    dataProvider
      .getEntity(url, params, options)
      .then((response) => {
        update({
          item: {
            data: response.data,
            error: null,
            isLoading: false,
          },
        })
      })
      .catch((err) => {
        update({
          item: {
            ...item,
            error: err,
            isLoading: false,
          },
        })
      })
  }, [JSON.stringify(params), update])

  return null
}
