import React from "react"
import { useContextSelector } from "use-context-selector"

import { useTranslate } from "infrastructure/hooks/useTranslate"

import { TransactionContext } from "domain/transaction/TransactionContext"
import { transactionSelectors } from "domain/transaction/selectors"
import { formatDatetime } from "domain/core/utils"

import * as S from "./styled"

export const TransactionDetails = () => {
  const item = useContextSelector(TransactionContext, transactionSelectors.item)
  const data = item?.data || {}

  return (
    <S.Wrapper>
      <S.Header>
        <S.Title>{useTranslate("pages.transaction.subTitle")}</S.Title>
      </S.Header>

      <S.Items>
        <div>
          <S.Label>{useTranslate("pages.transaction.grid.startedAt")}</S.Label>
          <S.Value>
            {data.startedAt ? formatDatetime(data.startedAt) : "—"}
          </S.Value>
        </div>
        <div>
          <S.Label>{useTranslate("pages.transaction.grid.finalAt")}</S.Label>
          <S.Value>{data.finalAt ? formatDatetime(data.finalAt) : "—"}</S.Value>
        </div>
        <div>
          <S.Label>{useTranslate("pages.transaction.grid.operation")}</S.Label>
          <S.Value>{data.operation || "—"}</S.Value>
        </div>
        <div>
          <S.Label>{useTranslate("pages.transaction.grid.orderId")}</S.Label>
          <S.Value>{data.orderId || "—"}</S.Value>
        </div>
        <div>
          <S.Label>{useTranslate("pages.transaction.grid.externalId")}</S.Label>
          <S.Value>{data.externalId || "—"}</S.Value>
        </div>
        <div>
          <S.Label>
            {useTranslate("pages.transaction.grid.paymentSystemOrderId")}
          </S.Label>
          <S.Value>{data.paymentSystemOrderId || "—"}</S.Value>
        </div>
        <div>
          <S.Label>{useTranslate("pages.transaction.grid.customerId")}</S.Label>
          <S.Value>{data.customerId || "0"}</S.Value>
        </div>
        <div>
          <S.Label>
            {useTranslate("pages.transaction.grid.customerEmail")}
          </S.Label>
          <S.Value>{data.customerEmail || "—"}</S.Value>
        </div>
        <div>
          <S.Label>{useTranslate("pages.transaction.grid.country")}</S.Label>
          <S.Value>{data.country || "—"}</S.Value>
        </div>
        <div>
          <S.Label>
            {useTranslate("pages.transaction.grid.paymentMethodIn")}
          </S.Label>
          <S.Value>{data.paymentMethodIn || "—"}</S.Value>
        </div>
        <div>
          <S.Label>{useTranslate("pages.transaction.grid.cardMethod")}</S.Label>
          <S.Value>{data.cardBrand || "—"}</S.Value>
        </div>
        <div>
          <S.Label>{useTranslate("pages.transaction.grid.cardPan")}</S.Label>
          <S.Value>{data.cardMaskedPan || "—"}</S.Value>
        </div>
        <div>
          <S.Label>
            {useTranslate("pages.transaction.grid.cardCountry")}
          </S.Label>
          <S.Value>{data.cardIssuingCountry || "—"}</S.Value>
        </div>
        <div>
          <S.Label>{useTranslate("pages.transaction.grid.fullName")}</S.Label>
          <S.Value>{data.fullName || "—"}</S.Value>
        </div>
        <div>
          <S.Label>
            {useTranslate("pages.transaction.grid.countryOfResidence")}
          </S.Label>
          <S.Value>{data.countryOfResidence || "—"}</S.Value>
        </div>
        <div>
          <S.Label>
            {useTranslate("pages.transaction.grid.paymentMethodOut")}
          </S.Label>
          <S.Value>{data.paymentMethodOut || "—"}</S.Value>
        </div>
        <div>
          <S.Label>{useTranslate("pages.transaction.grid.status")}</S.Label>
          <S.Value>{data.status || "—"}</S.Value>
        </div>
        <div>
          <S.Label>{useTranslate("pages.transaction.grid.failReason")}</S.Label>
          <S.Value>{data.failReason || "—"}</S.Value>
        </div>
        <div>
          <S.Label>
            {useTranslate("pages.transaction.grid.clientPayAmount")}
          </S.Label>
          <S.Value>
            {`${data.clientPayAmount || "—"} ${data.clientPayCurrency || "—"}`}
          </S.Value>
        </div>
        <div>
          <S.Label>
            {useTranslate("pages.transaction.grid.clientGetAmount")}
          </S.Label>
          <S.Value>
            {`${data.clientGetAmount || "—"} ${data.clientGetCurrency || "—"}`}
          </S.Value>
        </div>
        <div>
          <S.Label>
            {useTranslate("pages.transaction.grid.clientPaySystemAmount")}
          </S.Label>
          <S.Value>
            {`${data.clientPaySystemAmount || "—"} ${
              data.clientPaySystemCurrency || "—"
            }`}
          </S.Value>
        </div>
        <div>
          <S.Label>
            {useTranslate("pages.transaction.grid.merchantPnl")}
          </S.Label>
          <S.Value>{data.merchantPnl || "—"}</S.Value>
        </div>
        <div>
          <S.Label>
            {useTranslate("pages.transaction.grid.merchantPnlSystem")}
          </S.Label>
          <S.Value>{data.merchantPnlSystem || "—"}</S.Value>
        </div>
      </S.Items>
    </S.Wrapper>
  )
}
