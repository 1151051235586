import { styled } from "@mui/material"
import { palette } from "view/themes/palette"

export const Wrapper = styled("div")(() => ({
  position: "relative",
  width: "100%",

  "&:hover > div": {
    display: "block",
  },
}))

export const Copy = styled("div")(() => ({
  position: "absolute",
  display: "none",
  top: -2,
  right: -12,
  cursor: "pointer",
  padding: 4,
  lineHeight: 0,
  background: palette.grey100,
  borderRadius: "50%",
  zIndex: 1000,

  svg: {
    fontSize: "1rem",
  },

  "&:hover": {
    background: palette.primary500,
  },
}))
