import { errorToText } from "infrastructure/utils/errorToText"

export const mapToErrorDetails = (data) => {
  return Object.keys(data).reduce((prev, key) => {
    return {
      ...prev,
      [key]: errorToText(data[key]),
    }
  }, {})
}
