import React, { useCallback, useEffect } from "react"
import {
  Button,
  Dialog,
  EmptyScreen,
  AlertTypeEnum,
} from "@unlimint/admin-ui-kit"
import { useContextSelector } from "use-context-selector"

import { useTranslate } from "infrastructure/hooks/useTranslate"
import { ApiMethodEnum } from "infrastructure/enums/ApiMethodEnum"

import { webhookSelectors } from "domain/webhook/selectors"
import { useOpenable } from "domain/hooks/useOpenable"
import { useTitle } from "domain/application/hooks/useTitle"
import { WebhookContext, WebhookProvider } from "domain/webhook/WebhookContext"
import { WebhookEventsFetchContainer } from "domain/webhook/containers/WebhookEventsFetchContainer"
import { WebhookEventsProvider } from "domain/webhook/webhookEvents/WebhookEventsContext"
import { AddWebhookContainer } from "domain/webhook/AddWebhookContainer"
import { dataToRow } from "domain/webhook/mappers"
import { FetchListContainer } from "domain/core/containers/FetchListContainer"
import { useRequest } from "domain/core/hooks/useRequest"
import { useAlert } from "domain/application/hooks/useAlert"
import { useFetchWebhookStat } from "domain/webhook/hooks/useFetchWebhookStat"

import { WebhookTabs } from "./components/WebhookTabs"
import * as S from "./styled"

const Page = () => {
  const title = useTranslate("pages.webhooks.title")
  const addButton = useTranslate("pages.webhooks.addButton")
  const resendButton = useTranslate("pages.webhooks.resendAllButton")
  const allHooksResendedMessage = useTranslate(
    "pages.webhooks.allHooksResendedMessage"
  )
  const resendError = useTranslate("pages.webhooks.resendError")

  const sendAlert = useAlert()

  const {
    isLoading: isLoadingResend,
    status,
    error,
    send,
  } = useRequest({
    url: "/v1/webhooks/resend",
    method: ApiMethodEnum.Post,
  })

  const { send: sendStat } = useFetchWebhookStat()

  const { list, isLoading } = useContextSelector(
    WebhookContext,
    webhookSelectors.gridData
  )

  const isEmptyData = !list.length

  const addModal = useOpenable()

  useTitle(title)

  useEffect(() => {
    if (error) sendAlert(resendError, AlertTypeEnum.Error)
    if (status === 204) {
      sendAlert(allHooksResendedMessage)
      sendStat()
    }
  }, [error, status])

  const onClickResendButton = useCallback(() => {
    send()
  }, [send])

  return (
    <>
      <WebhookEventsFetchContainer />

      <FetchListContainer
        url="/v1/webhooks/list"
        method={ApiMethodEnum.Post}
        resourceContext={WebhookContext}
        resourceSelectors={webhookSelectors}
        params={{}}
        options={{
          mapper: (data) => data.map(dataToRow),
        }}
      />

      <S.Wrapper>
        <S.Buttons>
          <Button size="small" onClick={addModal.onOpen}>
            {addButton}
          </Button>
          {!isEmptyData && !isLoading && (
            <Button
              size="small"
              color="secondary"
              onClick={onClickResendButton}
              disabled={isLoadingResend}
            >
              {resendButton}
            </Button>
          )}
        </S.Buttons>

        <WebhookEventsProvider>
          {isEmptyData && !isLoading ? (
            <S.EmptyScreenWrapp>
              <EmptyScreen />
            </S.EmptyScreenWrapp>
          ) : (
            <WebhookTabs />
          )}
        </WebhookEventsProvider>
      </S.Wrapper>

      <Dialog open={addModal.open} onClose={addModal.onClose}>
        <AddWebhookContainer modal={addModal} />
      </Dialog>
    </>
  )
}

export const WebhooksPage = () => (
  <WebhookProvider>
    <Page />
  </WebhookProvider>
)
