import ReactJson from "react-json-view"
import { formatDatetime } from "domain/core/utils"

import * as S from "./styled"

const getStatus = (value: string, apiCode: number) => {
  return (
    <S.StatusChip status={value}>
      {apiCode ? `${apiCode} ` : ""}
      {value === "succeeded" ? "SUCCESS" : "ERR"}
    </S.StatusChip>
  )
}

export const WebhookLogsInfo = ({ data }) => {
  const responseBlock = (httpStatus: number) => {
    if (!httpStatus)
      return (
        <ReactJson
          src={{ message: "Connection to remote host denied" }}
          name="body"
          displayDataTypes={false}
        />
      )

    if (data.responseBody) {
      try {
        return (
          <ReactJson
            src={JSON.parse(data.responseBody)}
            name="body"
            displayDataTypes={false}
          />
        )
      } catch {
        return <S.ResBodyString>{data.responseBody}</S.ResBodyString>
      }
    }

    return <ReactJson src={{}} name="body" displayDataTypes={false} />
  }

  return (
    <S.Wrapper>
      <S.Title>POST</S.Title>

      <S.InfoBlock>
        <S.Grid>
          <S.GridRow>
            <S.GridRow>
              <S.GridLabel>Status</S.GridLabel>
              <S.GridValue>
                {getStatus(data.status, data.httpStatus)}
              </S.GridValue>
            </S.GridRow>
          </S.GridRow>
          <S.GridRow>
            <S.GridLabel>ID</S.GridLabel>
            <S.GridValue>{data.id}</S.GridValue>
          </S.GridRow>
          <S.GridRow>
            <S.GridLabel>Time</S.GridLabel>
            <S.GridValue>{formatDatetime(data.createdAt)}</S.GridValue>
          </S.GridRow>
          <S.GridRow>
            <S.GridLabel>Resend</S.GridLabel>
            <S.GridValue>{String(data.isResend)}</S.GridValue>
          </S.GridRow>
          <S.GridRow>
            <S.GridLabel>Response time</S.GridLabel>
            <S.GridValue>{data.responseTime} ms</S.GridValue>
          </S.GridRow>
        </S.Grid>
      </S.InfoBlock>
      <S.ResponseBodyBlock>
        <S.BlockTitle>Response body</S.BlockTitle>
        <S.ResponseBodyWrapper>
          {responseBlock(data.httpStatus)}
        </S.ResponseBodyWrapper>
      </S.ResponseBodyBlock>
    </S.Wrapper>
  )
}
