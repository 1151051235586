import { FC, useLayoutEffect, useState } from "react"
import { useContextSelector } from "use-context-selector"

import { PermissionsEnum } from "domain/rbac/PermissionsEnum"
import { UserContext } from "domain/user/UserContext"
import { userSelectors } from "domain/user/selectors"

import { ForbiddenPage } from "view/pages/application/ForbiddenPage"

type TAccessPageContainerProps = {
  permissions: Array<PermissionsEnum>
  children: JSX.Element
}

export const AccessPageContainer: FC<TAccessPageContainerProps> = ({
  permissions,
  children,
}) => {
  const [isAllowContent, setIsAllowContent] = useState(false)

  const userPermissions: string[] = useContextSelector(
    UserContext,
    userSelectors.permissions
  )

  useLayoutEffect(() => {
    for (let i = 0; i < permissions.length; i += 1) {
      const isAccess = userPermissions.indexOf(permissions[i]) > -1

      if (isAccess) {
        setIsAllowContent(true)
        break
      }
    }
  }, [])

  return isAllowContent ? children : <ForbiddenPage />
}
