import { apiService } from "infrastructure/services/ApiService"
import { errorService } from "infrastructure/services/ErrorService"
import { queryToString } from "domain/core/utils"
import { paginationToReq } from "domain/core/mappers"

export const dataProvider = {
  combineUrl: (url, params = {}) => {
    const query = {
      // sort: params.sort,
    }

    if (params.pagination) {
      query.pagination = paginationToReq(params.pagination)
    }

    if (params.filter) {
      query.filter = params.filter
    }

    if (params.merchantId) {
      query.merchantId = params.merchantId
    }

    if (params.methodCode) {
      query.methodCode = params.methodCode
    }

    return `${process.env.REACT_APP_API_URL}${url}?${queryToString(query)}`
  },

  getEntity: (url, params, options) => {
    const uri = dataProvider.combineUrl(url, params)

    return apiService.get(uri).then(({ data }) => {
      const value = options?.mapper ? options?.mapper(data) : data

      return {
        data: value || null,
      }
    })
  },

  getList: (url, params, options, cancelToken) => {
    const uri = dataProvider.combineUrl(url, params, {
      cancelToken,
    })

    return apiService
      .get(uri)
      .then(({ data }) => {
        const items =
          options?.mapper && data?.list
            ? options?.mapper(data?.list)
            : data?.list

        return {
          data: items || [],
          pagination: {
            ...data.pagination,
            total: data.total,
          },
        }
      })
      .catch((err) => {
        errorService.send(err)
      })
  },

  getListWithFilter: (url, params, options, cancelToken) => {
    const uri = dataProvider.combineUrl(url)

    return apiService
      .post(uri, params, {
        cancelToken,
      })
      .then(({ data }) => {
        try {
          const items =
            options?.mapper && data?.list
              ? options?.mapper(data?.list)
              : data?.list

          return {
            data: items || [],
            pagination: {
              ...data.pagination,
              total: data.total,
            },
          }
        } catch (e) {
          errorService.send(e)
          return null
        }
      })
      .catch((err) => {
        errorService.send(err)
        throw new Error(err)
      })
  },

  delete: (url, data, options) => {
    return apiService.delete(`${process.env.REACT_APP_API_URL}${url}`, {
      data,
    })
  },
}
