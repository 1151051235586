import { useEffect, useState } from "react"
import { Alert, AlertTypeEnum } from "@unlimint/admin-ui-kit"
import { useContextSelector } from "use-context-selector"
import { AlertContext } from "domain/alerts/AlertContext"
import { alertSelectors } from "domain/alerts/selectors"

export const AlertEvent = () => {
  const [open, setOpen] = useState(false)

  const message = useContextSelector(AlertContext, alertSelectors.message)
  const type = useContextSelector(AlertContext, alertSelectors.type)
  const update = useContextSelector(AlertContext, alertSelectors.update)

  useEffect(() => {
    if (message) setOpen(true)
  }, [message])

  const onClose = () => {
    setOpen(false)
    update({
      message: "",
      type: "",
    })
  }

  return (
    <Alert
      open={open}
      onClose={onClose}
      type={type || AlertTypeEnum.Success}
      anchorOrigin={{
        horizontal: "right",
        vertical: "top",
      }}
    >
      {message}
    </Alert>
  )
}
