import { createContext } from "use-context-selector"

import { createProvider } from "domain/createProvider"

const initState = {
  title: "Dashboard",
  isReady: false,
}

export const AppContext = createContext(initState)

export const AppProvider = createProvider(AppContext, initState)
