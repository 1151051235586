import { createContext } from "use-context-selector"
import { AlertTypeEnum } from "@unlimint/admin-ui-kit"

import { createProvider } from "domain/createProvider"

const initState = {
  message: "",
  type: AlertTypeEnum.Success,
}

export const AlertContext = createContext(initState)

export const AlertProvider = createProvider(AlertContext, initState)
