import { ColorIcon } from "@unlimint/admin-ui-kit"

import { useTranslate } from "infrastructure/hooks/useTranslate"

export const menuConfig = () => [
  {
    label: useTranslate("sidebar.home"),
    icon: <ColorIcon name="home" />,
    to: "/",
  },
  {
    label: useTranslate("sidebar.finance"),
    icon: <ColorIcon name="payments" />,
    childItems: [
      {
        label: useTranslate("sidebar.transactions"),
        to: "/finance/transactions",
      },
      {
        label: useTranslate("sidebar.balance"),
        to: "/finance/balance",
      },
      {
        label: useTranslate("sidebar.cashFlow"),
        to: "/finance/cash-flow",
      },
    ],
  },
  {
    label: useTranslate("sidebar.settings"),
    icon: <ColorIcon name="analytics" />,
    childItems: [
      {
        label: useTranslate("sidebar.details"),
        to: "/settings/details",
      },
      {
        label: useTranslate("sidebar.networkFees"),
        to: "/settings/promotion-network-fees",
      },
      {
        label: useTranslate("sidebar.processingFees"),
        to: "/settings/promotion-processing-fees",
      },
      {
        label: useTranslate("sidebar.paymentTapOnFees"),
        to: "/settings/payment-methods-tap-on-fees",
      },
      {
        label: useTranslate("sidebar.payoutTapOnFees"),
        to: "/settings/payout-methods-tap-on-fees",
      },
      // @TODO: Uncomment code below when we need markup rules page
      // {
      //   label: useTranslate("sidebar.markupRules"),
      //   to: "/settings/markup-rules",
      // },
    ],
  },

  {
    label: useTranslate("sidebar.devSpace"),
    icon: <ColorIcon name="link" />,
    childItems: [
      {
        label: useTranslate("sidebar.activityLogs"),
        to: "/activity-logs",
      },
      {
        label: useTranslate("sidebar.apiKey"),
        to: "/api-key",
      },
      {
        label: useTranslate("sidebar.webhooks"),
        to: "/webhooks",
      },
    ],
  },
  {
    label: useTranslate("sidebar.team"),
    icon: <ColorIcon name="customers" />,
    to: "/team",
  },
]
