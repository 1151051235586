import { storageService } from "infrastructure/services/StorageService"

export class GridStateStorageService {
  userId = null

  storageGridKey = null

  constructor(userId: string, storageGridKey: string) {
    this.userId = userId
    this.storageGridKey = storageGridKey
  }

  async getState() {
    const userStorage = await storageService.getItem(this.userId)
    const data = userStorage?.[this.storageGridKey] || null
    return data
  }

  async update(data) {
    const userStorage = await storageService.getItem(this.userId)
    storageService.setItem(this.userId, {
      ...userStorage,
      [this.storageGridKey]: data,
    })
  }
}
