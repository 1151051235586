import { styled } from "@mui/material"

export const Wrapper = styled("div")(() => ({
  width: "100%",
  height: "100%",
}))

export const EmptyScreenWrapp = styled("div")(() => ({
  height: "calc(100vh - 186px)",
}))

export const Buttons = styled("div")(() => ({
  display: "flex",
  gap: 16,
  marginBottom: "32px",
}))
