import { useCallback } from "react"
import { useContextSelector } from "use-context-selector"

import { FormModeEnum } from "domain/enums/FormModeEnum"
import { TeamContext } from "domain/team/TeamContext"
import { teamSelectors } from "domain/team/selectors"
import { dataToRow } from "domain/team/mappers"
import { FormContainer } from "domain/core/containers/FormContainer"
import { insertToGridDataState } from "domain/core/utils"
import { AddUserForm } from "view/pages/TeamPage/components/AddUserForm"
import { useTranslate } from "infrastructure/hooks/useTranslate"

import { validate } from "./validate"

const initialValues = {
  email: "",
  name: "",
  surname: "",
  groups: [],
}

export const AddTeamUserContainer = ({ modal }) => {
  const addtitle = useTranslate("pages.team.modals.addtitle")
  const addButton = useTranslate("pages.team.modals.addButton")

  const gridData = useContextSelector(TeamContext, teamSelectors.gridData)
  const update = useContextSelector(TeamContext, teamSelectors.update)

  const cbSuccess = useCallback(
    (data) => {
      update({
        gridData: insertToGridDataState(gridData, dataToRow(data)),
      })
    },
    [update]
  )

  return (
    <FormContainer
      mode={FormModeEnum.Add}
      validate={validate}
      initialValues={initialValues}
      url="/v1/sso/users"
      Form={AddUserForm}
      formProps={{
        title: addtitle,
        actionButtonTitle: addButton,
        onClose: modal.onClose,
      }}
      cbSuccess={cbSuccess}
    />
  )
}
