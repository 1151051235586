import { FC, useEffect } from "react"
import { Button, AlertTypeEnum } from "@unlimint/admin-ui-kit"
import { useContextSelector } from "use-context-selector"

import { useTranslate } from "infrastructure/hooks/useTranslate"
import { ApiMethodEnum } from "infrastructure/enums/ApiMethodEnum"

import { WebhookEventsContext } from "domain/webhook/webhookEvents/WebhookEventsContext"
import { webhookEventsSelectors } from "domain/webhook/webhookEvents/selectors"
import { useRequest } from "domain/core/hooks/useRequest"
import { useAlert } from "domain/application/hooks/useAlert"
import { useFetchEventLogs } from "domain/webhook/hooks/useFetchEventLogs"

import { WebhookLogs } from "./WebhookLogs"
import * as S from "./styled"

type TResendEventMessageProps = {
  name: string
}

const ResendEventMessage: FC<TResendEventMessageProps> = ({ name }) => {
  const firstPath = useTranslate("pages.webhooks.resendEventMessage.firstPath")
  const secondPath = useTranslate(
    "pages.webhooks.resendEventMessage.secondPath"
  )

  return (
    <div>
      {firstPath}
      <S.AlertEventName>&nbsp;{name}&nbsp;</S.AlertEventName>
      {secondPath}
    </div>
  )
}

export const WebhookAbout = () => {
  const resendButton = useTranslate("pages.webhooks.resendButton")
  const resendErrorMessage = useTranslate("pages.webhooks.resendError")

  const sendAlert = useAlert()

  const { data } = useContextSelector(
    WebhookEventsContext,
    webhookEventsSelectors.item
  )

  const {
    cbLoadMore,
    isLoading,
    isLoadMore,
    list: logsList,
  } = useFetchEventLogs({
    webhookId: data?.webhookId,
    eventId: data?.id,
  })

  const {
    error: resendError,
    status: resendStatus,
    send: sendEventHooks,
    isLoading: isLoadingResed,
  } = useRequest({
    url: `/v1/webhooks/${data?.webhookId}/events/${data?.id}/resend`,
    method: ApiMethodEnum.Post,
  })

  useEffect(() => {
    if (resendError) sendAlert(resendErrorMessage, AlertTypeEnum.Error)
    if (resendStatus === 204) sendAlert(<ResendEventMessage name={data.name} />)
  }, [resendError, resendStatus])

  const onClickResendEventButton = () => {
    sendEventHooks()
  }

  if (!data) return null

  return (
    <S.Wrapper>
      <S.TopContent>
        <S.Title>{data.name}</S.Title>
        <Button
          size="small"
          color="secondary"
          onClick={onClickResendEventButton}
          disabled={isLoadingResed}
        >
          {resendButton}
        </Button>
      </S.TopContent>
      <S.GridRow>
        <S.GridLabel>ID</S.GridLabel>
        <S.GridValue>{data.id}</S.GridValue>
      </S.GridRow>
      <WebhookLogs
        list={logsList}
        isLoading={isLoading}
        isLoadMore={isLoadMore}
        cbLoadMore={cbLoadMore}
      />
    </S.Wrapper>
  )
}
