import React from "react"

import { Field } from "formik"

import { Checkbox } from "@unlimint/admin-ui-kit"

export const CheckboxField = ({
  name,
  label,
  size = "small",
  value = undefined,
  ...rest
}) => {
  return (
    <Field type="checkbox" name={name} value={value}>
      {({ field, form: { setFieldValue, touched, errors }, meta }) => {
        return (
          <Checkbox
            {...rest}
            name={name}
            label={label}
            size={size}
            checked={field.checked}
            value={value}
            onChange={field.onChange}
          />
        )
      }}
    </Field>
  )
}
