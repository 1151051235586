import { styled } from "@mui/material"
import { palette } from "view/themes/palette"

export const Wrapper = styled("div")(() => ({
  width: 350,
}))

export const Title = styled("div")(() => ({
  fontFamily: "Inter",
  fontWeight: 600,
  fontSize: 16,
  lineHeight: "20px",
  marginBottom: 32,
}))

export const FormRow = styled("div")(() => ({
  marginBottom: 32,
}))

export const Buttons = styled("div")(() => ({
  display: "flex",
  gap: 12,
}))

export const CheckboxGroupLabel = styled("div")(() => ({
  fontSize: 14,
  fontFamily: "Inter",
  fontWeight: 600,
  marginBottom: 12,
}))

export const SecretKey = styled("div")(() => ({
  fontSize: 14,
  marginBottom: 12,
}))

export const EventsError = styled("div")(() => ({
  marginTop: "16px",
  fontSize: 13,
  color: palette.error500,
}))
