import React, { useCallback } from "react"
import { useContextSelector } from "use-context-selector"

import { FormModeEnum } from "domain/enums/FormModeEnum"
import { TeamContext } from "domain/team/TeamContext"
import { teamSelectors } from "domain/team/selectors"
import { dataToRow, mapToReqModel } from "domain/team/mappers"
import { FormContainer } from "domain/core/containers/FormContainer"
import { updateToGridDataState } from "domain/core/utils"
import { EditUserForm } from "view/pages/TeamPage/components/EditUserForm"
import { useTranslate } from "infrastructure/hooks/useTranslate"

import { validate } from "./validate"

export const EditTeamUserContainer = ({ modal, formData }) => {
  const editTitle = useTranslate("pages.team.modals.editTitle")
  const editButton = useTranslate("pages.team.modals.editButton")

  const gridData = useContextSelector(TeamContext, teamSelectors.gridData)
  const update = useContextSelector(TeamContext, teamSelectors.update)

  const cbSuccess = useCallback(
    (data) => {
      update({
        gridData: updateToGridDataState(gridData, dataToRow(data)),
      })
    },
    [update]
  )

  return (
    <FormContainer
      mode={FormModeEnum.Edit}
      validate={validate}
      initialValues={formData}
      url={`/v1/sso/users/${formData.id}`}
      Form={EditUserForm}
      mapToReqModel={mapToReqModel}
      formProps={{
        title: editTitle,
        actionButtonTitle: editButton,
        onClose: modal.onClose,
      }}
      cbSuccess={cbSuccess}
    />
  )
}
