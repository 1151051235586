import { styled } from "@mui/material"
import { palette } from "view/themes/palette"

type TStyles = {
  isSelected?: boolean
  status: string
}

const labelColor = (status: string) => {
  if (status === "failed" || status === "pending") return palette.error500
  return palette.black
}

export const ListWrapper = styled("div")(() => ({
  minWidth: "196px",
  maxWidth: "196px",
  borderRight: `1px solid ${palette.grey200}`,
  height: "calc(100vh - 281px)",
}))

export const GroupLabel = styled("div")(() => ({
  backgroundColor: palette.white,
  fontFamily: "Inter",
  fontStyle: "normal",
  fontSize: "13px",
  width: "100%",
  height: "36px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderBottom: `1px dashed ${palette.grey400}`,
  fontWeight: 500,
}))

export const ListComponent = styled("div")<TStyles>(
  ({ isSelected, status }) => ({
    width: "100%",
    height: "36px",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderBottom: `1px dashed ${palette.grey400}`,
    backgroundColor: isSelected ? palette.info200 : palette.transparent,
    color: labelColor(status),
    position: "relative",

    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: isSelected ? 600 : 400,
    fontSize: "13px",
    lineHeight: isSelected ? "16px" : "20px",

    "&:hover": {
      backgroundColor: palette.info200,
    },
  })
)

export const Indicator = styled("div")<{ isSelected: boolean }>(
  ({ isSelected }) => ({
    display: isSelected ? "block" : "none",
    position: "absolute",
    top: 0,
    right: 0,
    width: "5px",
    height: "36px",
    borderRadius: "4px",
    background: palette.info500,
  })
)
