import { styled } from "@mui/material"
import { palette } from "view/themes/palette"

export const Wrapper = styled("div")(() => ({
  width: "100%",
  padding: "40px 0 24px 0",
  borderBottom: `1px solid ${palette.grey200}`,
}))

export const TopContent = styled("div")(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}))

export const LeftSideBlock = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  paddingRight: 15,
  "& > div:first-child": {
    marginRight: "24px",
  },
}))

export const RightSideBlock = styled("div")(() => ({
  width: "68px",
  display: "flex",
  justifyContent: "space-between",
  color: palette.grey500,

  "& > svg": {
    cursor: "pointer",
  },
}))

export const Title = styled("div")(() => ({
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "28px",
  lineHeight: "36px",
}))

export const Key = styled("div")(() => ({
  margin: "8px 0 8px 0",
  display: "flex",
  alignItems: "center",
  gap: 8,
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "13px",
  lineHeight: "20px",
  color: palette.grey500,

  "& > span:first-child": {
    width: "44px",
  },

  "& > svg": {
    cursor: "pointer",
  },
}))

export const Link = styled("a")(() => ({
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "13px",
  lineHeight: "20px",
  color: palette.info500,
  textDecoration: "none",
}))
