import React from "react"
import { BrowserRouter, Route, Routes } from "react-router-dom"

import { Containers } from "domain/Containers"

import { MainLayout } from "view/layouts/MainLayout"
import { TransactionListPage } from "view/pages/transactions/TransactionListPage"
import { TransactionPage } from "view/pages/transactions/TransactionPage"
import { CashFlowListPage } from "view/pages/cashFlow/CashFlowListPage"
import { CashFlowPage } from "view/pages/cashFlow/CashFlowPage"
import { BalancePage } from "view/pages/BalancePage"
import { ApiKeyPage } from "view/pages/ApiKeyPage"
import { TeamPage } from "view/pages/TeamPage"
import { MarkupRulesPage } from "view/pages/settings/MarkupRulesPage"
import { NetworkFeesPage } from "view/pages/settings/NetworkFeesPage"
import { ProcessingFeesPage } from "view/pages/settings/ProcessingFeesPage"
import { TapOnFeesPage } from "view/pages/settings/TapOnFeesPage"
import { NotFoundPage } from "view/pages/application/NotFoundPage"
import { DetailsPage } from "view/pages/settings/DetailsPage"
import { HomePage } from "view/pages/HomePage"
import { WebhooksPage } from "view/pages/devSpace/WebhooksPage"
import { ActivityLogListPage } from "view/pages/activityLog/ActivityLogListPage"
import { PayoutTapOnFeesPage } from "view/pages/settings/PayoutTapOnFeePage"

export const App = () => {
  return (
    <BrowserRouter>
      <Containers />
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route index element={<HomePage />} />
          <Route path="finance">
            <Route index element={<TransactionListPage />} />
            <Route path="transactions">
              <Route index element={<TransactionListPage />} />
              <Route path=":orderId" element={<TransactionPage />} />
            </Route>
            <Route path="balance" element={<BalancePage />} />
            <Route path="cash-flow">
              <Route index element={<CashFlowListPage />} />
              <Route path=":orderId" element={<CashFlowPage />} />
            </Route>
          </Route>

          <Route path="settings">
            <Route index element={<DetailsPage />} />
            <Route path="details" element={<DetailsPage />} />
            <Route
              path="promotion-network-fees"
              element={<NetworkFeesPage />}
            />
            <Route
              path="promotion-processing-fees"
              element={<ProcessingFeesPage />}
            />
            <Route
              path="payment-methods-tap-on-fees"
              element={<TapOnFeesPage />}
            />
            <Route
              path="payout-methods-tap-on-fees"
              element={<PayoutTapOnFeesPage />}
            />
            <Route path="markup-rules" element={<MarkupRulesPage />} />
          </Route>

          <Route path="webhooks" element={<WebhooksPage />} />
          <Route path="api-key" element={<ApiKeyPage />} />
          <Route path="team" element={<TeamPage />} />

          <Route path="activity-logs" element={<ActivityLogListPage />} />

          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}
