import React, { FC, useEffect, useState } from "react"
import { Context, useContextSelector } from "use-context-selector"

import { ApiMethodEnum } from "infrastructure/enums/ApiMethodEnum"
import { apiService } from "infrastructure/services/ApiService"

import { dataProvider } from "domain/core/dataProvider"
import { paginationFromRes } from "domain/core/mappers"
import { TInitDataContext, TUpdate } from "domain/types"
import { TGridContainerParams } from "domain/core/types"

const cancelMessage = "Request is canceled."

type FetchListContainerProps = {
  url: string
  method: ApiMethodEnum
  resourceContext: Context<TInitDataContext<any>>
  resourceSelectors: any
  params: TGridContainerParams
  options: {
    [key: string]: any
  }
}

export const FetchListContainer: FC<FetchListContainerProps> = ({
  url,
  method,
  resourceContext,
  resourceSelectors,
  params,
  options = {},
}) => {
  const update: TUpdate<any> = useContextSelector(
    resourceContext,
    resourceSelectors.update
  )

  const [cancelSource, setCancelSource] = useState(null)

  const hashParams = JSON.stringify({
    ...params,
    pagination: {
      ...params.pagination,
      total: undefined,
    },
  })

  useEffect(() => {
    update({
      gridData: {
        isLoading: true,
      },
    })

    const dataProviderMethod =
      method === ApiMethodEnum.Post
        ? dataProvider.getListWithFilter
        : dataProvider.getList

    let source = cancelSource

    if (source) source.cancel(cancelMessage)
    source = apiService.getSource()
    setCancelSource(source)

    dataProviderMethod(url, params, options, source?.token)
      .then((response) => {
        if (!response) return

        update({
          gridData: {
            list: response.data,
            pagination: paginationFromRes(response.pagination),
            error: null,
            isLoading: false,
          },
        })
      })
      .catch((err) => {
        if (err.message.includes("CanceledError")) return

        update({
          gridData: {
            error: err,
            isLoading: false,
          },
        })
      })
  }, [hashParams])

  return null
}
