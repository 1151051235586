import { useMemo } from "react"

import { useTranslate } from "infrastructure/hooks/useTranslate"

import { useTitle } from "domain/application/hooks/useTitle"
import {
  ProcessingFeesProvider,
  ProcessingFeesContext,
} from "domain/settings/processingFees/ProcessingFeesContext"
import { processingFeesSelectors } from "domain/settings/processingFees/selectors"
import { GridContainer } from "domain/core/containers/GridContainer"
import { dataToRow } from "domain/settings/processingFees/mappers"
import { AccessPageContainer } from "domain/core/containers/AccessPageContainer"
import { PermissionsEnum } from "domain/rbac/PermissionsEnum"

const Page = () => {
  const methodCode = useTranslate("pages.processingFees.grid.method")
  const searchPlaceholder = useTranslate(
    "pages.processingFees.searchPlaceholder"
  )

  const initParams = useMemo(
    () => ({
      sort: [
        {
          columnName: "methodCode",
          direction: "asc",
        },
      ],
    }),
    []
  )

  const columns = useMemo(
    () => [
      {
        field: "methodCode",
        headerName: methodCode,
        minWidth: 120,
        flex: 1,
      },
    ],
    []
  )

  return (
    <ProcessingFeesProvider>
      <GridContainer
        url="/v1/promotions-processing-fees/list"
        context={ProcessingFeesContext}
        selectors={processingFeesSelectors}
        initParams={initParams}
        columns={columns}
        dataToRow={dataToRow}
        searchBlockProps={{
          placeholder: searchPlaceholder,
          columns: ["methodCode"],
        }}
        isServerSearch
      />
    </ProcessingFeesProvider>
  )
}

export const ProcessingFeesPage = () => {
  const title = useTranslate("pages.processingFees.title")

  useTitle(title)

  return (
    <AccessPageContainer permissions={[PermissionsEnum.DetailsView]}>
      <Page />
    </AccessPageContainer>
  )
}
