import { useRequest } from "domain/core/hooks/useRequest"
import { TDictionaryHook } from "domain/core/types"

export const useFetchDictionary = (url): TDictionaryHook => {
  const { data, isLoading, error } = useRequest({
    url,
    sendOnStart: true,
  })

  return {
    isLoading: isLoading || data?.list === undefined,
    data: data?.list || [],
    error,
  }
}
