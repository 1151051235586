import { useEffect } from "react"
import { useContextSelector } from "use-context-selector"

import { storageService } from "infrastructure/services/StorageService"
import { UserContext } from "domain/user/UserContext"
import { userSelectors } from "domain/user/selectors"
import { AppContext } from "domain/application/AppContext"
import { appSelectors } from "domain/application/selectors"
import { userService } from "domain/user/services/UserService"

export const UserContainer = () => {
  const updateApp = useContextSelector(AppContext, appSelectors.update)
  const updateUser = useContextSelector(UserContext, userSelectors.update)

  useEffect(() => {
    userService.fetchMe().then((result) => {
      if (result?.data) {
        const merchant = result.data.merchants[0]
        storageService.setItem("merchantId", merchant.id)
        updateUser({
          ...merchant,
          userId: result.data.userId,
          permissions:
            merchant.permissions && merchant.permissions.length
              ? merchant.permissions
              : [],
        })
      }

      updateApp({ isReady: true })
    })
  }, [])

  return null
}
