import { useCallback } from "react"
import { useContextSelector } from "use-context-selector"

import { useTranslate } from "infrastructure/hooks/useTranslate"

import { FormModeEnum } from "domain/enums/FormModeEnum"
import { FormContainer } from "domain/core/containers/FormContainer"
import { insertToGridDataState } from "domain/core/utils"

import { AddWebhookForm } from "view/pages/devSpace/WebhooksPage/components/AddWebhookForm"
import { validate } from "./validate"
import { WebhookContext } from "../WebhookContext"
import { webhookSelectors } from "../selectors"
import { dataToRow, mapToReqModel } from "../mappers"

const initialValues = {
  url: "https://",
  name: "",
  apiVersion: "v2",
  isActive: true,
  events: [],
}

export const AddWebhookContainer = ({ modal }) => {
  const addTitle = useTranslate("pages.webhooks.modals.addTitle")
  const addButton = useTranslate("pages.webhooks.modals.addButton")

  const gridData = useContextSelector(WebhookContext, webhookSelectors.gridData)
  const update = useContextSelector(WebhookContext, webhookSelectors.update)
  const events = useContextSelector(WebhookContext, webhookSelectors.events)

  const disabled = !events || events.length === 0
  const eventOptions = Object.keys(events).map((key) => ({
    label: key,
    value: key,
  }))

  const cbSuccess = useCallback(
    (data) => {
      update({
        gridData: insertToGridDataState(gridData, dataToRow(data.webhook)),
      })
    },
    [gridData, update]
  )

  return (
    <FormContainer
      mode={FormModeEnum.Add}
      validate={validate}
      initialValues={initialValues}
      url="/v1/webhooks"
      Form={AddWebhookForm}
      mapToReqModel={mapToReqModel}
      formProps={{
        title: addTitle,
        actionButtonTitle: addButton,
        onClose: modal.onClose,
        disabled,
        eventOptions,
        events,
      }}
      cbSuccess={cbSuccess}
    />
  )
}
