import React, { useMemo, useCallback, useEffect, useState } from "react"
import type { ITabContentsProps, ITabProps } from "@unlimint/admin-ui-kit"
import { Tabs } from "@unlimint/admin-ui-kit"
import { useContextSelector } from "use-context-selector"

import { WebhookContext } from "domain/webhook/WebhookContext"
import { webhookSelectors } from "domain/webhook/selectors"
import { WebhookEventsContext } from "domain/webhook/webhookEvents/WebhookEventsContext"
import { webhookEventsSelectors } from "domain/webhook/webhookEvents/selectors"
import { useFetchWebhookStat } from "domain/webhook/hooks/useFetchWebhookStat"

import { TabContent } from "view/pages/devSpace/WebhooksPage/components/WebhookTabs/TabContent"
import { WebhookHeader } from "view/pages/devSpace/WebhooksPage/components/WebhookHeader"

import { TabLabel } from "./TabLabel"

export const WebhookTabs = () => {
  const { list } = useContextSelector(WebhookContext, webhookSelectors.gridData)

  const eventsUpdate = useContextSelector(
    WebhookEventsContext,
    webhookEventsSelectors.update
  )

  const stat = useContextSelector(WebhookContext, webhookSelectors.stat)

  const { send } = useFetchWebhookStat()

  const [selectedItemId, setSelectedItemId] = useState("")

  const tabs = useMemo(() => {
    const data: ITabProps[] = list.map(({ id, name }): ITabProps => {
      const hookStat = stat[id]

      const failedCount = hookStat?.failedCount + hookStat?.pendingCount

      return {
        value: id,
        label: (
          <TabLabel title={name} isError={!!failedCount} count={failedCount} />
        ),
      }
    })

    return data
  }, [list, stat])

  const tabsContents = useMemo(() => {
    const data: ITabContentsProps[] = list.map((item) => ({
      value: item.id,
      content: (
        <>
          <WebhookHeader {...item} />
          {selectedItemId === item.id && (
            <TabContent id={selectedItemId} isEmpty={!stat[item.id]?.total} />
          )}
        </>
      ),
    }))

    return data
  }, [list, selectedItemId, stat])

  const handleOnChange = useCallback(
    (value: string) => {
      eventsUpdate({
        gridData: {
          list: [],
        },
        item: {
          data: null,
        },
      })

      send()
      setSelectedItemId(value)
    },
    [list, eventsUpdate]
  )

  useEffect(() => {
    if (tabs.length && !selectedItemId) {
      handleOnChange(tabs[0].value)
    }
  }, [tabs, selectedItemId])

  if (tabs.length)
    return (
      <Tabs
        tabs={tabs}
        tabsContents={tabsContents}
        initialTab={list[0]?.id}
        indicatorColor="secondary"
        onChange={handleOnChange}
        customControlsConfig={{
          positionHorizontal: "right",
          bottom: 29,
        }}
      />
    )

  return null
}
