import { useCallback, useEffect } from "react"
import { apiService } from "infrastructure/services/ApiService"
import { errorService } from "infrastructure/services/ErrorService"
import { ApiMethodEnum } from "infrastructure/enums/ApiMethodEnum"
import { useSafeState } from "./useSafeState"

export const useRequest = ({
  url,
  method = ApiMethodEnum.Get,
  sendOnStart = false,
  body = {},
  polling = 0, // in seconds
  isCancelMode = false,
}) => {
  const [status, setStatus] = useSafeState(null)
  const [data, setData] = useSafeState(null)
  const [isLoading, setIsLoading] = useSafeState(false)
  const [error, setError] = useSafeState(null)
  const [counter, setCounter] = useSafeState(polling)
  const [cancelSource, setCancelSource] = useSafeState("")

  const send = useCallback(async () => {
    setIsLoading(true)
    setStatus(null)

    let source = cancelSource

    if (isCancelMode) {
      if (source) source.cancel("Request is canceled.")
      source = apiService.getSource()
      setCancelSource(source)
    }

    try {
      const response = await apiService({
        url,
        method,
        data: body,
        cancelToken: source?.token,
      })

      setData(response.data)
      setStatus(response.status)
      setError(null)
    } catch (e) {
      setError(e)

      if (e.response?.status) {
        setStatus(e.response.status)

        if (e.response.status === 500) {
          errorService.send(e)
        }
      } else if (e.code !== "ERR_CANCELED") {
        errorService.send(e)
      }
    } finally {
      setIsLoading(false)
    }
  }, [body, url, method, isCancelMode])

  useEffect(() => {
    if (!sendOnStart) return

    send()
  }, [sendOnStart])

  useEffect(() => {
    if (!polling) return undefined

    const timer = setTimeout(() => {
      if (counter <= 1) {
        setCounter(polling)
      } else {
        setCounter(counter - 1)
      }

      if (counter === 1) send()
    }, 1000)

    return () => {
      clearTimeout(timer)
    }
  }, [counter, polling])

  return { data, isLoading, error, send, counter, status }
}
