import { useMemo } from "react"
import { useContextSelector } from "use-context-selector"

import { amountFormat } from "infrastructure/utils/amountFormat"
import { useTranslate } from "infrastructure/hooks/useTranslate"

import { useTitle } from "domain/application/hooks/useTitle"
import { dataToEntity } from "domain/balance/mappers"
import { BalanceProvider, BalanceContext } from "domain/balance/BalanceContext"
import { balanceSelectors } from "domain/balance/selectors"
import { FetchEntityContainer } from "domain/core/containers/FetchEntityContainer"
import { AccessPageContainer } from "domain/core/containers/AccessPageContainer"
import { PermissionsEnum } from "domain/rbac/PermissionsEnum"

import * as S from "./styled"

export const Page = () => {
  const { data } = useContextSelector(BalanceContext, balanceSelectors.item)

  const options = useMemo(
    () => ({
      mapper: (item) => dataToEntity(item),
    }),
    [dataToEntity]
  )

  return (
    <>
      <FetchEntityContainer
        url="/v1/balance"
        resourceContext={BalanceContext}
        resourceSelectors={balanceSelectors}
        options={options}
      />

      {Object.keys(data).map((key) => (
        <S.Row key={key}>
          <div>{amountFormat(data[key])}</div>
          <div>{key}</div>
        </S.Row>
      ))}
    </>
  )
}

export const BalancePage = () => {
  const title = useTranslate("pages.balance.title")

  useTitle(title)

  return (
    <AccessPageContainer permissions={[PermissionsEnum.BalanceView]}>
      <BalanceProvider>
        <Page />
      </BalanceProvider>
    </AccessPageContainer>
  )
}
