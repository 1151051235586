import { useTranslate } from "infrastructure/hooks/useTranslate"

import { useTitle } from "domain/application/hooks/useTitle"
import {
  HomeDetailsContext,
  HomeDetailsProvider,
} from "domain/home/HomeDetailsContext"
import { FetchEntityContainer } from "domain/core/containers/FetchEntityContainer"
import { homeDetailsSelectors } from "domain/home/selectors"
import { dataToEntity } from "domain/home/mappers"
import { AccessPageContainer } from "domain/core/containers/AccessPageContainer"
import { PermissionsEnum } from "domain/rbac/PermissionsEnum"

import { DetailsGrid } from "view/pages/HomePage/components/DetailsGrid"

const Page = () => {
  return (
    <HomeDetailsProvider>
      <FetchEntityContainer
        url="/v1/me"
        resourceContext={HomeDetailsContext}
        resourceSelectors={homeDetailsSelectors}
        options={{
          mapper: dataToEntity,
        }}
      />

      <DetailsGrid />
    </HomeDetailsProvider>
  )
}

export const HomePage = () => {
  const title = useTranslate("pages.home.title")

  useTitle(title)

  return (
    <AccessPageContainer permissions={[PermissionsEnum.CommonView]}>
      <Page />
    </AccessPageContainer>
  )
}
