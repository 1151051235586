import React, { FC } from "react"
import { Form } from "formik"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { Button } from "@unlimint/admin-ui-kit"

import { useTranslate } from "infrastructure/hooks/useTranslate"

import { ButtonBlock } from "view/components/ButtonBlock"
import { InputField } from "view/components/formik/InputField"

import * as S from "./styled"

const docsLink =
  "https://docs.gatefi.com/docs/gatefi-docs/rkackr79i0qq3-obtaining-an-api-key"

type TApiKeyFormProps = {
  accessKey: string
  secretKey: string
  actionButtonTitle: string
  isLoading: boolean
  isSubmitting: boolean
}

export const ApiKeyForm: FC<TApiKeyFormProps> = ({
  accessKey,
  secretKey,
  actionButtonTitle,
  isLoading,
  isSubmitting,
}) => {
  const inputDisabled = isLoading || isSubmitting

  return (
    <Form>
      <S.Wrapper>
        {secretKey && (
          <S.Notice>{useTranslate("pages.apiKey.formDescription")}</S.Notice>
        )}

        <S.FormRow>
          <InputField
            label={useTranslate("pages.apiKey.accessKey")}
            name="accessKey"
            cleanable={false}
            disabled
          />
          <CopyToClipboard text={accessKey}>
            <Button size="small" color="secondary" disabled={!accessKey}>
              {useTranslate("pages.apiKey.copy")}
            </Button>
          </CopyToClipboard>
        </S.FormRow>

        <S.FormRow>
          <InputField
            label={useTranslate("pages.apiKey.secretKey")}
            name="secretKey"
            cleanable={false}
            disabled
          />
          <CopyToClipboard text={secretKey}>
            <Button size="small" color="secondary" disabled={!secretKey}>
              {useTranslate("pages.apiKey.copy")}
            </Button>
          </CopyToClipboard>
        </S.FormRow>

        <ButtonBlock>
          <Button size="small" type="submit" disabled={inputDisabled}>
            {actionButtonTitle}
          </Button>
          <S.Link href={docsLink} target="_blank">
            {useTranslate("pages.apiKey.docLink")}
          </S.Link>
        </ButtonBlock>
      </S.Wrapper>
    </Form>
  )
}
