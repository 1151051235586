import {
  TPayoutTapOnFee,
  TPayoutTapOnFeeRes,
} from "domain/settings/payoutTapOnFees/types"

export const dataToRow = (item: TPayoutTapOnFeeRes): TPayoutTapOnFee => ({
  id: item.methodCode,
  methodCode: item.methodCode,
  tapOnFee: item.tapOnFee || 0,
})
