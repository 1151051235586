import React from "react"
import { useContextSelector } from "use-context-selector"

import { formatDatetime } from "domain/core/utils"
import { CashFlowContext } from "domain/cashFlow/CashFlowContext"
import { cashFlowSelectors } from "domain/cashFlow/selectors"
import { useTranslate } from "infrastructure/hooks/useTranslate"

import * as S from "./styled"

export const CashFlowDetails = () => {
  const item = useContextSelector(CashFlowContext, cashFlowSelectors.item)
  const data = item?.data || {}

  const title = useTranslate("pages.cashFlowDetails.title")
  const date = useTranslate("pages.cashFlowDetails.date")
  const orderId = useTranslate("pages.cashFlowDetails.orderId")
  const id = useTranslate("pages.cashFlowDetails.id")
  const name = useTranslate("pages.cashFlowDetails.name")
  const operation = useTranslate("pages.cashFlowDetails.operation")
  const initCount = useTranslate("pages.cashFlowDetails.initCount")
  const successCount = useTranslate("pages.cashFlowDetails.successCount")
  const failedCount = useTranslate("pages.cashFlowDetails.failedCount")

  return (
    <S.Wrapper>
      <S.Header>
        <S.Title>{title}</S.Title>
      </S.Header>

      <S.Items>
        <div>
          <S.Label>{date}</S.Label>
          <S.Value>{data.date ? formatDatetime(data.date) : "—"}</S.Value>
        </div>
        <div>
          <S.Label>{orderId}</S.Label>
          <S.Value>{data.id ? data.id : "—"}</S.Value>
        </div>
        <div>
          <S.Label>{id}</S.Label>
          <S.Value>{data.marchantId ? data.marchantId : "—"}</S.Value>
        </div>
        <div>
          <S.Label>{name}</S.Label>
          <S.Value>{data.marchantName ? data.marchantName : "—"}</S.Value>
        </div>
        <div>
          <S.Label>{operation}</S.Label>
          <S.Value>{data.operation ? data.operation : "—"}</S.Value>
        </div>
        <div>
          <S.Label>{initCount}</S.Label>
          <S.Value>{data.initCount ? data.initCount : "—"}</S.Value>
        </div>
        <div>
          <S.Label>{successCount}</S.Label>
          <S.Value>{data.successCount ? data.successCount : "—"}</S.Value>
        </div>
        <div>
          <S.Label>{failedCount}</S.Label>
          <S.Value>{data.failedCount ? data.failedCount : "—"}</S.Value>
        </div>
      </S.Items>
    </S.Wrapper>
  )
}
