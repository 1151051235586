import React, { useCallback, useMemo, useState, useEffect, FC } from "react"
import { useContextSelector } from "use-context-selector"

import {
  Button,
  DataGrid,
  Menu,
  Dialog,
  GridActionsCellItem,
  SelectTypeEnum,
  TGridConfig,
  IFilterConfig,
} from "@unlimint/admin-ui-kit"

import { useTranslate } from "infrastructure/hooks/useTranslate"
import { ApiMethodEnum } from "infrastructure/enums/ApiMethodEnum"
import { compareObjects } from "infrastructure/utils/compareObjects"

import { useOpenable } from "domain/hooks/useOpenable"
import { FetchListContainer } from "domain/core/containers/FetchListContainer"
import {
  mapGridContainerParamsToReq,
  mapDataGridContextToGridConfig,
} from "domain/core/mappers"
import { TFetchListParamReq, TGridContainerParams } from "domain/core/types"
import { TDataGridContext, TUpdate } from "domain/types"

import { ButtonBlock } from "view/components/ButtonBlock"
import { SearchBlock } from "view/components/SearchBlock"

export type TGridContainerProps = {
  url: string
  method?: ApiMethodEnum
  columns: any[]
  context: any
  selectors: any
  dataToRow: (item: any) => void
  filterToReq?: (filters: IFilterConfig) => void
  initParams?: TGridContainerParams
  searchBlockProps?: any
  buttonProps?: any
  actionMenu?: any
  isActionMenu?: boolean
  isDetails?: boolean
  isEditable?: boolean
  isDeletable?: boolean
  submenu?: any
  DetailsModal?: any
  AddForm?: any
  EditForm?: any
  DeleteModal?: any
  onRowClick?: any
  isHeader?: boolean
  selectType?: SelectTypeEnum
  selectedRows?: Array<string | number>
  isServerSearch?: boolean
}

export const GridContainer: FC<TGridContainerProps> = ({
  url,
  method = ApiMethodEnum.Post,
  columns,
  initParams,
  context,
  selectors,
  dataToRow,
  filterToReq,
  searchBlockProps,
  buttonProps,
  actionMenu,
  isActionMenu = true,
  isDetails,
  isEditable,
  isDeletable,
  submenu,
  DetailsModal,
  AddForm,
  EditForm,
  DeleteModal,
  onRowClick,
  isHeader,
  selectType,
  selectedRows,
  isServerSearch,
}) => {
  const showDetails = useTranslate("gridContainer.actions.showDetails")
  const edit = useTranslate("gridContainer.actions.edit")
  const deleteLabel = useTranslate("gridContainer.actions.delete")

  const gridData: TDataGridContext<any> = useContextSelector(
    context,
    selectors.gridData
  )
  const update: TUpdate<any> = useContextSelector(context, selectors.update)

  const detailsModal = useOpenable()
  const addModal = useOpenable()
  const editModal = useOpenable()
  const deleteModal = useOpenable()
  const [editItem, setEditItem] = useState(null)
  const [filteredData, setFilteredData] = useState(null)
  const [searchFilter, setSearchFilter] = useState({})

  useEffect(() => {
    const { sort, filters, pagination } = initParams

    update({
      gridData: {
        isInited: !filters?.items,
        pagination,
        sort,
        filters,
      },
    })
  }, [])

  const params = useMemo<TFetchListParamReq>(() => {
    const mapParams = mapGridContainerParamsToReq(gridData, filterToReq)
    return {
      ...mapParams,
      filters: {
        ...mapParams.filters,
        ...searchFilter,
      },
    }
  }, [gridData])

  const config = useMemo<TGridConfig>(() => {
    if (gridData.isInited) {
      return mapDataGridContextToGridConfig(gridData)
    }
    return {}
  }, [gridData])

  const options = useMemo(
    () => ({
      mapper: (data) => data.map(dataToRow),
    }),
    [dataToRow]
  )

  useEffect(() => {
    if (filteredData) {
      const filteredState = []

      filteredData.forEach(({ id }) => {
        const findeditem = gridData?.list.find((item) => item.id === id)
        if (findeditem) filteredState.push(findeditem)
      })

      setFilteredData(filteredState)
    }
  }, [gridData, filteredData])

  const actionColumn = {
    field: "actions",
    type: "actions",
    width: 28,
    getActions: ({ row }) => {
      const menuItems = []

      if (isDetails)
        menuItems.push(
          <GridActionsCellItem
            label={showDetails}
            showInMenu
            onClick={(e) => {
              setEditItem(row)
              detailsModal.onOpen(e)
            }}
          />
        )

      if (isEditable)
        menuItems.push(
          <GridActionsCellItem
            label={edit}
            showInMenu
            onClick={(e) => {
              setEditItem(row)
              editModal.onOpen(e)
            }}
          />
        )

      if (isDeletable)
        menuItems.push(
          <GridActionsCellItem
            label={deleteLabel}
            showInMenu
            onClick={(e) => {
              setEditItem(row)
              deleteModal.onOpen(e)
            }}
          />
        )

      if (submenu && submenu.length) {
        submenu.forEach((item) => {
          menuItems.push(
            <GridActionsCellItem
              label={item.isSwitchTitle ? item.title(row) : item.title}
              showInMenu
              onClick={() => item.onClick(row)}
            />
          )
        })
      }

      return menuItems
    },
  }

  const handlerChange = useCallback(
    (newParams) => {
      const { sort, filter, pagination } = newParams

      update({
        gridData: {
          pagination,
          sort,
          filters: filter,
        },
      })
    },
    [update]
  )

  const handlerSearch = useCallback(
    (data) => {
      return setFilteredData(data)
    },
    [setFilteredData]
  )

  const handlerServerSearch = useCallback(
    (data) => {
      if (!compareObjects(searchFilter, data)) {
        update({
          gridData: {
            list: [],
            pagination: {
              currentPage: 1,
            },
          },
        })

        setSearchFilter(data)
      }
    },
    [setSearchFilter, update, searchFilter]
  )

  const mergedColumns = useMemo(
    () => (isActionMenu ? [...columns, actionColumn] : columns),
    [columns, actionColumn, isActionMenu]
  )

  const gridLoading = gridData.isLoading

  return (
    <>
      {gridData.isInited && (
        <FetchListContainer
          url={url}
          method={method}
          resourceContext={context}
          resourceSelectors={selectors}
          params={params}
          options={options}
        />
      )}

      <DataGrid
        columns={mergedColumns}
        rows={filteredData || gridData.list}
        loading={gridLoading}
        error={gridData.error}
        config={config}
        onChange={handlerChange}
        onRowClick={onRowClick}
        isHeader={isHeader}
        selectType={selectType}
        selectedRows={selectedRows}
        SearchBlock={
          searchBlockProps && (
            <SearchBlock
              list={gridData.list}
              columns={searchBlockProps.columns}
              placeholder={searchBlockProps.placeholder}
              onChange={handlerSearch}
              onServerSearch={handlerServerSearch}
              isServerSearch={isServerSearch}
            />
          )
        }
        ButtonsBlock={
          (buttonProps || actionMenu) && (
            <ButtonBlock>
              {buttonProps && (
                <Button
                  size="small"
                  onClick={
                    buttonProps.link
                      ? () => {}
                      : buttonProps.onClick || addModal.onOpen
                  }
                  disabled={buttonProps.disabled || gridLoading}
                  href={buttonProps.link}
                >
                  {buttonProps.title}
                </Button>
              )}
            </ButtonBlock>
          )
        }
        ActionMenu={actionMenu && <Menu items={actionMenu} />}
      />

      {DetailsModal && (
        <Dialog open={detailsModal.open} onClose={detailsModal.onClose}>
          <DetailsModal modal={detailsModal} data={editItem} />
        </Dialog>
      )}

      {AddForm && (
        <Dialog open={addModal.open} onClose={addModal.onClose}>
          <AddForm modal={addModal} />
        </Dialog>
      )}

      {EditForm && (
        <Dialog open={editModal.open} onClose={editModal.onClose}>
          <EditForm modal={editModal} formData={editItem} />
        </Dialog>
      )}

      {DeleteModal && (
        <Dialog open={deleteModal.open} onClose={deleteModal.onClose}>
          <DeleteModal modal={deleteModal} formData={editItem} />
        </Dialog>
      )}
    </>
  )
}
