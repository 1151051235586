import { useCallback, useEffect, useMemo, useState } from "react"
import { useContextSelector } from "use-context-selector"
import { SelectTypeEnum } from "@unlimint/admin-ui-kit"

import { WebhookEventsContext } from "domain/webhook/webhookEvents/WebhookEventsContext"
import { GridContainer } from "domain/core/containers/GridContainer"
import { webhookEventsSelectors } from "domain/webhook/webhookEvents/selectors"
import { dataToListRow } from "domain/webhook/mappers"
import { formatTimeDate } from "domain/core/utils"

import * as S from "./styled"

export const WebhooksList = ({ id }) => {
  const { list } = useContextSelector(
    WebhookEventsContext,
    webhookEventsSelectors.gridData
  )

  const { data } = useContextSelector(
    WebhookEventsContext,
    webhookEventsSelectors.item
  )

  const update = useContextSelector(
    WebhookEventsContext,
    webhookEventsSelectors.update
  )

  const [selectedRows, setSelectedRows] = useState([""])

  const initParams = useMemo(
    () => ({
      sort: [
        {
          columnName: "date",
          direction: "desc",
        },
      ],
    }),
    []
  )

  const columns = useMemo(
    () => [
      {
        field: "status",
        headerName: "",
        minWidth: 32,
        maxWidth: 32,
        renderCell: ({ value }) => <S.Indicator status={value} />,
      },
      {
        field: "name",
        headerName: "Name",
        width: 170,
      },
      {
        field: "date",
        headerName: "Date",
        width: 190,
        flex: 1,
        align: "right",
        renderCell: ({ row }) => {
          const isSelected = row.id === selectedRows[0]

          return (
            <S.DateCellContainer>
              {formatTimeDate(row.date)}
              <S.RowIndicator isSelected={isSelected} />
            </S.DateCellContainer>
          )
        },
      },
    ],
    [data, selectedRows]
  )

  const handlerRowClick = useCallback(
    (ids) => {
      const data = list.find((item) => item.id === ids[0])

      update({
        item: {
          data,
        },
      })

      setSelectedRows(ids)
    },
    [list, update]
  )

  useEffect(() => {
    if (list.length && !data && update) {
      handlerRowClick([list[0].id])
    }
  }, [list, data, update])

  return (
    <GridContainer
      url={`/v1/webhooks/${id}/events/list`}
      context={WebhookEventsContext}
      selectors={webhookEventsSelectors}
      columns={columns}
      dataToRow={dataToListRow}
      initParams={initParams}
      onRowClick={handlerRowClick}
      isActionMenu={false}
      isHeader={false}
      selectType={SelectTypeEnum.SingleRow}
      selectedRows={selectedRows}
    />
  )
}
