import React, { useState, useCallback } from "react"
import { mergeWith, isArray } from "lodash"
import { Context } from "use-context-selector"

import { TInitDataContext } from "domain/types"

const customizer = (objValue, srcValue) => {
  if (isArray(objValue)) {
    return srcValue
  }

  return undefined
}

type TCreateProviderCbArgs = {
  children: JSX.Element[] | JSX.Element
}

export const createProvider = (
  Context: Context<TInitDataContext<any>>,
  initState: TInitDataContext<any> | object = {}
) => {
  return ({ children }: TCreateProviderCbArgs) => {
    const [state, setState] = useState(initState)

    const update = useCallback(
      (parts) => {
        setState((innerState) => {
          return mergeWith({}, innerState, parts, customizer)
        })
      },
      [setState]
    )

    const reset = useCallback(() => {
      setState(initState)
    }, [setState])

    return (
      <Context.Provider
        value={{
          ...state,
          update,
          reset,
        }}
      >
        {children}
      </Context.Provider>
    )
  }
}
