import { styled } from "@mui/material"
import { palette } from "view/themes/palette"

export const Wrapper = styled("div")(() => ({
  width: 480,
}))

export const Notice = styled("div")(() => ({
  fontFamily: "Inter",
  fontWeight: 600,
  fontSize: 14,
  lineHeight: "16px",
  marginBottom: 24,
  borderRadius: 6,
  padding: 16,
  backgroundColor: palette.info200,
  borderWidth: 1,
  borderStyle: "solid",
  borderColor: palette.info400,
}))

export const FormRow = styled("div")(() => ({
  display: "flex",
  alignItems: "flex-end",
  whiteSpace: "nowrap",
  gap: 12,
  marginBottom: 24,
}))

export const Link = styled("a")(() => ({
  display: "block",
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "13px",
  lineHeight: "20px",
  color: palette.info500,
  textDecoration: "none",
}))
