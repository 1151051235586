import { useContextSelector } from "use-context-selector"

import { AlertContext } from "domain/alerts/AlertContext"
import { alertSelectors } from "domain/alerts/selectors"

export const useAlert = () => {
  const update = useContextSelector(AlertContext, alertSelectors.update)

  const sendAlert = (message, type?) => {
    update({
      message,
      type,
    })
  }

  return sendAlert
}
