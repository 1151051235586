import Keycloak from "keycloak-js"
import { storageService } from "infrastructure/services/StorageService"
import { apiService } from "infrastructure/services/ApiService"
import { errorService } from "infrastructure/services/ErrorService"

class KeycloakService {
  keycloak

  constructor() {
    this.keycloak = new Keycloak({
      url: process.env.REACT_APP_KEYCLOAK_URL,
      realm: process.env.REACT_APP_KEYCLOAK_REALM,
      clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
    })
  }

  checkAuth() {
    this.keycloak.onTokenExpired = () => {
      this.updateToken()
    }

    return new Promise((resolve, reject) => {
      this.keycloak
        .init({
          onLoad: "login-required",
        })
        .then((authenticated) => {
          storageService.setItem("accessToken", this.keycloak.token)
          storageService.setItem("refreshToken", this.keycloak.refreshToken)

          resolve()
        })
        .catch((e) => {
          reject(e)
        })
    })
  }

  updateToken() {
    return new Promise((resolve, reject) => {
      this.keycloak
        .updateToken()
        .then(() => {
          storageService.setItem("accessToken", this.keycloak.token)
          storageService.setItem("refreshToken", this.keycloak.refreshToken)

          resolve()
        })
        .catch((e) => {
          reject(e)
        })
    })
  }

  async logout() {
    const refreshToken = await storageService.getItem("refreshToken")

    try {
      await apiService.post(
        `${process.env.REACT_APP_KEYCLOAK_URL}/realms/${process.env.REACT_APP_KEYCLOAK_REALM}/protocol/openid-connect/logout`,
        `client_id=${encodeURIComponent(
          process.env.REACT_APP_KEYCLOAK_CLIENT_ID
        )}&refresh_token=${encodeURIComponent(refreshToken)}`,
        {
          transformRequest: (data, headers) => {
            delete headers["X-Merchantid"]

            headers["Content-Type"] = "application/x-www-form-urlencoded"

            return data
          },
        }
      )

      window.open(process.env.REACT_APP_APP_URL, "_self")
    } catch (e) {
      errorService.send(e)
    }
  }
}

export const keycloakService = new KeycloakService()
