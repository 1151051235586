import React, { useMemo } from "react"
import { useContextSelector } from "use-context-selector"

import { useTranslate } from "infrastructure/hooks/useTranslate"
import { apiService } from "infrastructure/services/ApiService"
import { errorService } from "infrastructure/services/ErrorService"
import { ApiMethodEnum } from "infrastructure/enums/ApiMethodEnum"

import { useTitle } from "domain/application/hooks/useTitle"
import {
  MarkupRulesContext,
  MarkupRulesProvider,
} from "domain/settings/markupRules/MarkupRulesContext"
import { markupRuleSelectors } from "domain/settings/markupRules/selectors"
import { GridContainer } from "domain/core/containers/GridContainer"
import { dataToRow } from "domain/settings/markupRules/mappers"
import { updateToGridDataState } from "domain/core/utils"
import { EditMarkupRule } from "domain/settings/markupRules/EditMarkupRule"
import { TUpdate } from "domain/types"
import { AccessPageContainer } from "domain/core/containers/AccessPageContainer"
import { PermissionsEnum } from "domain/rbac/PermissionsEnum"

const Page = () => {
  const gridData = useContextSelector(
    MarkupRulesContext,
    markupRuleSelectors.gridData
  )

  const update: TUpdate<any> = useContextSelector(
    MarkupRulesContext,
    markupRuleSelectors.update
  )

  const initParams = useMemo(
    () => ({
      sort: [
        {
          columnName: "cryptoAssetCode",
          direction: "asc",
        },
      ],
    }),
    []
  )

  const columns = useMemo(
    () => [
      {
        field: "cryptoAssetCode",
        headerName: useTranslate("pages.markupRules.grid.asset"),
        width: 120,
      },
      {
        field: "buyMarkupRate",
        headerName: useTranslate("pages.markupRules.grid.markup"),
        minWidth: 100,
        flex: 1,
      },
    ],
    []
  )

  const resetBuyMarkupRate = (row) => {
    if (row.buyMarkupRate) {
      apiService
        .delete("/v1/markup-rules", { cryptoAssetCode: row.cryptoAssetCode })
        .then(() => {
          update({
            gridData: updateToGridDataState(
              gridData,
              dataToRow({
                ...row,
                buyMarkupRate: undefined,
              })
            ),
          })
        })
        .catch((err) => {
          errorService.send(err)
        })
    }
  }

  return (
    <GridContainer
      url="/v1/markup-rules"
      method={ApiMethodEnum.Get}
      columns={columns}
      initParams={initParams}
      context={MarkupRulesContext}
      selectors={markupRuleSelectors}
      isEditable
      submenu={[
        {
          title: useTranslate("pages.markupRules.grid.reset"),
          onClick: resetBuyMarkupRate,
        },
      ]}
      dataToRow={dataToRow}
      searchBlockProps={{
        placeholder: useTranslate("pages.markupRules.searchPlaceholder"),
        columns: ["cryptoAssetCode"],
      }}
      EditForm={EditMarkupRule}
    />
  )
}

export const MarkupRulesPage = () => {
  useTitle(useTranslate("pages.markupRules.title"))

  return (
    <AccessPageContainer permissions={[PermissionsEnum.DetailsView]}>
      <MarkupRulesProvider>
        <Page />
      </MarkupRulesProvider>
    </AccessPageContainer>
  )
}
