import { useContextSelector } from "use-context-selector"

import { SettingViewProp } from "@unlimint/admin-ui-kit"

import { useTranslate } from "infrastructure/hooks/useTranslate"

import { HomeDetailsContext } from "domain/home/HomeDetailsContext"
import { homeDetailsSelectors } from "domain/home/selectors"
import PlaceholderLogo from "assets/images/placeholderLogo.svg"

import * as S from "./styled"

export const DetailsGrid = () => {
  const logo = useTranslate("pages.home.logo")
  const partnerTitle = useTranslate("pages.home.partnerTitle")
  const id = useTranslate("pages.home.id")
  const hostname = useTranslate("pages.home.hostname")
  const onrampStatus = useTranslate("pages.home.onrampStatus")
  const offrampStatus = useTranslate("pages.home.offrampStatus")
  const partnerStatus = useTranslate("pages.home.partnerStatus")

  const item = useContextSelector(HomeDetailsContext, homeDetailsSelectors.item)
  const data = item.data || {}

  return (
    <S.Wrapper>
      <S.Container>
        <S.Section>
          <div>
            <S.Label>{logo}</S.Label>
            <S.Logo
              src={data.logo || PlaceholderLogo}
              onerror={PlaceholderLogo}
              alt="logo"
            />
          </div>
          <div>
            <S.Label>{partnerTitle}</S.Label>
            <S.Value>{data.shortName || "—"}</S.Value>
          </div>
        </S.Section>

        <S.Section>
          <div>
            <S.Label>{id}</S.Label>
            <S.Value>{data.merchantId || "—"}</S.Value>
          </div>
          <div>
            <S.Label>{hostname}</S.Label>
            <S.Value>{data.host || "—"}</S.Value>
          </div>
        </S.Section>

        <S.Section>
          <div>
            <S.Label>{partnerStatus}</S.Label>
            <S.Value>
              <SettingViewProp
                currentValue={data.isEnabled}
                withoutResultValue
              />
            </S.Value>
          </div>
          <div>
            <S.Label>{onrampStatus}</S.Label>
            <S.Value>
              <SettingViewProp
                currentValue={data.canBuyCrypto}
                resultValue={data?.result?.canBuyCrypto}
              />
            </S.Value>
          </div>
          <div>
            <S.Label>{offrampStatus}</S.Label>
            <S.Value>
              <SettingViewProp
                currentValue={data.canSellCrypto}
                resultValue={data?.result?.canSellCrypto}
              />
            </S.Value>
          </div>
        </S.Section>
      </S.Container>
    </S.Wrapper>
  )
}
