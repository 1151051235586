import { styled } from "@mui/material"
import { palette } from "view/themes/palette"

export const Wrapper = styled("div")(() => ({
  width: 840,
  height: 640,
}))

export const Header = styled("div")(() => ({
  position: "relative",
  display: "flex",
  justifyContent: "space-between",
  paddingBottom: 12,
}))

export const Close = styled("div")(() => ({
  position: "absolute",
  right: 0,
  top: 0,
  width: 32,
  height: 32,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",

  "&:before, &:after": {
    content: '""',
    position: "absolute",
    width: 2,
    height: 12,
    backgroundColor: palette.grey500,
  },
  "&:before": {
    transform: "rotate(45deg)",
  },
  "&:after": {
    transform: "rotate(-45deg)",
  },
}))

export const Title = styled("div")(() => ({
  fontSize: 20,
  lineHeight: "32px",
  fontWeight: 500,
}))

export const Info = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  lineHeight: 1.9,
  paddingBottom: "7px",
  fontSize: 14,

  "& > span:first-of-type": {
    width: 110,
  },
}))

export const Switch = styled("div")(() => ({
  "& > span": {
    margin: 0,
  },
}))

export const EmptyDiff = styled("div")(() => ({
  minHeight: "calc(100% - 280px)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}))

export const EmptyScreenWrapp = styled("div")(() => ({
  height: "calc(100% - 220px)",
}))

export const Rows = styled("div")(() => ({
  display: "grid",
  gridTemplateColumns: "180px minmax(180px, 1fr) minmax(180px, 1fr)",
  gridColumnGap: 5,
  borderBottom: `1px solid ${palette.grey200}`,
  fontSize: 14,
}))

export const Cell = styled("div")(() => ({
  lineHeight: "24px",
  padding: "12px 0",
  wordWrap: "break-word",
}))

export const Key = styled("div")(() => ({
  color: palette.grey500,
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  paddingRight: 12,
}))

export const Label = styled("div")(() => ({
  fontSize: 14,
  lineHeight: "24px",
  fontWeight: 600,
}))
