import { useParams } from "react-router-dom"

import { useTranslate } from "infrastructure/hooks/useTranslate"

import { FetchEntityContainer } from "domain/core/containers/FetchEntityContainer"
import {
  CashFlowContext,
  CashFlowProvider,
} from "domain/cashFlow/CashFlowContext"
import { cashFlowSelectors } from "domain/cashFlow/selectors"
import { dataToEntity } from "domain/cashFlow/mappers"
import { useTitle } from "domain/application/hooks/useTitle"
import { AccessPageContainer } from "domain/core/containers/AccessPageContainer"
import { PermissionsEnum } from "domain/rbac/PermissionsEnum"

import { CashFlowDetails } from "view/pages/cashFlow/CashFlowPage/components/CashFlowDetails"
import { CashFlowTabs } from "view/pages/cashFlow/CashFlowPage/components/CashFlowTabs"

const Page = () => {
  const { orderId } = useParams()

  return (
    <CashFlowProvider>
      <FetchEntityContainer
        url={`/v1/cash-flows/${orderId}`}
        resourceContext={CashFlowContext}
        resourceSelectors={cashFlowSelectors}
        options={{
          mapper: dataToEntity,
        }}
      />
      <CashFlowDetails />
      <CashFlowTabs />
    </CashFlowProvider>
  )
}

export const CashFlowPage = () => {
  const title = useTranslate("pages.cashFlow.title")

  useTitle(title)

  return (
    <AccessPageContainer
      permissions={[PermissionsEnum.FinancereportDetailsView]}
    >
      <Page />
    </AccessPageContainer>
  )
}
