import { useEffect } from "react"
import { useContextSelector } from "use-context-selector"

import { useFetchWebhookEvents } from "../hooks/useFetchWebhookEvents"
import { WebhookContext } from "../WebhookContext"
import { webhookSelectors } from "../selectors"

export const WebhookEventsFetchContainer = () => {
  const update = useContextSelector(WebhookContext, webhookSelectors.update)

  const { data } = useFetchWebhookEvents()

  useEffect(() => {
    if (!data) return

    update({
      events: data,
    })
  }, [update, data])

  return null
}
