import React, { useState, useMemo, useEffect, useCallback } from "react"
import { Link, Outlet } from "react-router-dom"
import { useContextSelector } from "use-context-selector"

import { Header, Sidebar, Button } from "@unlimint/admin-ui-kit"
import { styled } from "@mui/material"

import { useTranslate } from "infrastructure/hooks/useTranslate"

import { menuConfig } from "view/layouts/menu.config"
import { AlertEvent } from "view/components/Alert"

import { AppContext } from "domain/application/AppContext"
import { appSelectors } from "domain/application/selectors"
import { keycloakService } from "domain/identity/KeycloakService"

import SidebarLogo from "assets/images/sidebarLogo.svg"

import * as S from "./styled"

const LinkRef = React.forwardRef((props, ref) => <Link ref={ref} {...props} />)

const LogoIcon = styled("div")(() => ({
  paddingLeft: "6px",
  height: "24px",
  overflow: "hidden",
  display: "flex",
  alignItems: "center",

  img: {
    height: "100%",
  },

  span: {
    paddingLeft: 8,
    fontFamily: "Inter",
    fontSize: 13,
    fontStyle: "normal",
    fontWeight: 700,
    letterSpacing: 0.52,
  },
}))

const Logo = (
  <LogoIcon>
    <img src={SidebarLogo} alt="logo" />
    <span>CRYPTO</span>
  </LogoIcon>
)

const MinLogo = (
  <LogoIcon>
    <img src={SidebarLogo} alt="logo" />
  </LogoIcon>
)

const LogOut = () => {
  const logout = useTranslate("header.logout")

  return (
    <Button size="small" variant="outlined" onClick={keycloakService.logout}>
      {logout}
    </Button>
  )
}

export const MainLayout = () => {
  const [open, setOpen] = useState(true)
  const [showBack, setShowBack] = useState(false)

  const isReady = useContextSelector(AppContext, appSelectors.isReady)
  const title = useContextSelector(AppContext, appSelectors.title)
  const menuConfigData = useMemo(() => menuConfig(), [])

  useEffect(() => {
    setShowBack(window.location.pathname !== "/")
  })

  const onBack = useCallback(() => {
    window.history.back()
  }, [window])

  return (
    <S.Wrapper>
      <S.LeftBlock>
        <Sidebar
          config={menuConfigData}
          menuItemComponent={LinkRef}
          open={open}
          setOpen={setOpen}
          logo={Logo}
          logoMin={MinLogo}
        />
      </S.LeftBlock>
      <S.RightBlock open={open}>
        <Header
          title={title}
          open={open}
          userMenu={<LogOut />}
          onBack={showBack && onBack}
        />
        <S.Content>{isReady && <Outlet />}</S.Content>
      </S.RightBlock>
      <AlertEvent />
    </S.Wrapper>
  )
}
