import React from "react"
import { useParams } from "react-router-dom"

import { useTitle } from "domain/application/hooks/useTitle"
import { FetchEntityContainer } from "domain/core/containers/FetchEntityContainer"
import {
  TransactionContext,
  TransactionProvider,
} from "domain/transaction/TransactionContext"
import { transactionSelectors } from "domain/transaction/selectors"
import { dataToEntity } from "domain/transaction/mappers"
import { AccessPageContainer } from "domain/core/containers/AccessPageContainer"
import { PermissionsEnum } from "domain/rbac/PermissionsEnum"

import { TransactionDetails } from "./components/TransactionDetails"
import { TransactionTabs } from "./components/TransactionTabs"

const Page = () => {
  const { orderId } = useParams()

  return (
    <TransactionProvider>
      <FetchEntityContainer
        url={`/v1/transactions/${orderId}`}
        resourceContext={TransactionContext}
        resourceSelectors={transactionSelectors}
        options={{
          mapper: dataToEntity,
        }}
      />
      <TransactionDetails />
      <TransactionTabs />
    </TransactionProvider>
  )
}

export const TransactionPage = () => {
  useTitle("Transaction")

  return (
    <AccessPageContainer
      permissions={[PermissionsEnum.FinancereportDetailsView]}
    >
      <Page />
    </AccessPageContainer>
  )
}
