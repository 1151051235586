import { useMemo } from "react"

import { useTranslate } from "infrastructure/hooks/useTranslate"

import { useTitle } from "domain/application/hooks/useTitle"
import { GridContainer } from "domain/core/containers/GridContainer"
import { dataToRow } from "domain/settings/payoutTapOnFees/mappers"
import { AccessPageContainer } from "domain/core/containers/AccessPageContainer"
import { PermissionsEnum } from "domain/rbac/PermissionsEnum"
import {
  PayoutTapOnFeesProvider,
  PayoutTapOnFeesContext,
} from "domain/settings/payoutTapOnFees/PayoutTapOnFeesContext"
import { payoutTapOnFeesSelectors } from "domain/settings/payoutTapOnFees/selectors"

const Page = () => {
  const searchPlaceholder = useTranslate(
    "pages.payoutTapOnFees.searchPlaceholder"
  )
  const methodCode = useTranslate("pages.payoutTapOnFees.grid.methodCode")
  const tapOnFee = useTranslate("pages.payoutTapOnFees.grid.tapOnFee")

  const initParams = useMemo(
    () => ({
      sort: [
        {
          columnName: "methodCode",
          direction: "asc",
        },
      ],
    }),
    []
  )

  const columns = useMemo(() => {
    return [
      {
        field: "methodCode",
        headerName: methodCode,
        width: 160,
      },
      {
        field: "tapOnFee",
        headerName: tapOnFee,
        minWidth: 160,
        flex: 1,
        renderCell: ({ value }) => `${value}%`,
      },
    ]
  }, [])

  return (
    <PayoutTapOnFeesProvider>
      <GridContainer
        url="/v1/payout-methods-tap-on-fees/list"
        columns={columns}
        context={PayoutTapOnFeesContext}
        selectors={payoutTapOnFeesSelectors}
        initParams={initParams}
        dataToRow={dataToRow}
        searchBlockProps={{
          placeholder: searchPlaceholder,
          columns: ["methodCode"],
        }}
        isServerSearch
      />
    </PayoutTapOnFeesProvider>
  )
}

export const PayoutTapOnFeesPage = () => {
  const title = useTranslate("pages.payoutTapOnFees.title")

  useTitle(title)

  return (
    <AccessPageContainer permissions={[PermissionsEnum.DetailsView]}>
      <Page />
    </AccessPageContainer>
  )
}
