import { useRequest } from "domain/core/hooks/useRequest"

type TResult = {
  data: {
    list: string[]
    total: number
  }
  isLoading: boolean
}

export const useFetchWebhookEvents = (): TResult => {
  const { data, isLoading } = useRequest({
    url: "/v1/webhooks/events",
    sendOnStart: true,
  })

  return { data, isLoading }
}
