import React from "react"

import { InfinityGroupedList } from "@unlimint/admin-ui-kit"

import { formatTime } from "domain/core/utils"
import { TLogItem } from "domain/webhook/types"

import * as S from "./styled"

type TWebhookLogsListProps = {
  list: {
    data: TLogItem[]
    groupCounts: number[]
    groups: string[]
  }
  selectedId: string
  onClick: (id: string) => void
  isLoading: boolean
  isLoadMore: boolean
  cbLoadMore: () => void
}
export const WebhookLogsList = ({
  list,
  selectedId,
  onClick,
  isLoading,
  isLoadMore,
  cbLoadMore,
}: TWebhookLogsListProps) => {
  return (
    <S.ListWrapper>
      <InfinityGroupedList
        data={list.data}
        groupCounts={list.groupCounts}
        groups={list.groups}
        isLoading={isLoading}
        isLoadMore={isLoadMore}
        cbLoadMore={cbLoadMore}
        GroupLabelComponent={({ label }) => (
          <S.GroupLabel>{label}</S.GroupLabel>
        )}
        ItemComponent={({ id, createdAt, status }) => (
          <S.ListComponent
            onClick={() => onClick(id)}
            key={`item-${id}`}
            isSelected={selectedId === id}
            status={status}
          >
            {formatTime(createdAt)}
            <S.Indicator isSelected={selectedId === id} />
          </S.ListComponent>
        )}
      />
    </S.ListWrapper>
  )
}
