import { useRequest } from "domain/core/hooks/useRequest"
import { useContextSelector } from "use-context-selector"
import { WebhookContext } from "domain/webhook/WebhookContext"
import { webhookSelectors } from "domain/webhook/selectors"
import { useEffect } from "react"

type TResult = {
  isLoading: boolean
  send: () => void
}

type TStatItem = {
  webhookSettingsId: string
  pendingCount: number
  failedCount: number
  successCount: number
}

export const useFetchWebhookStat = (): TResult => {
  const update = useContextSelector(WebhookContext, webhookSelectors.update)

  const { data, isLoading, send } = useRequest({
    url: "/v1/webhooks/stat",
  })

  useEffect(() => {
    if (!data) return
    if (!data.list) return

    const statState = data.list.reduce((acc: any, item: TStatItem) => {
      acc[item.webhookSettingsId] = {
        ...item,
        total: item.pendingCount + item.successCount + item.failedCount,
      }
      return acc
    }, {})

    update({
      stat: statState,
    })
  }, [update, data])

  return { isLoading, send }
}
