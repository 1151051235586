import React from "react"
import * as S from "./styled"

type TTabLabelProps = {
  title: string
  count: string
  isError: boolean
}

export const TabLabel: React.FC<TTabLabelProps> = ({
  title,
  count,
  isError = false,
}) => {
  return (
    <>
      <S.LabelWrapper>
        <S.LabelTitle>
          {title}
          {isError && <S.Badge>{count}</S.Badge>}
        </S.LabelTitle>
      </S.LabelWrapper>
    </>
  )
}
