import { useFetchDictionary } from "domain/dictionary/hooks/useFetchDictionary"

export const usePrepareChangelogDictionaries = () => {
  const typesReq = useFetchDictionary("/v1/dictionary/event-object-types")
  const sourcesReq = useFetchDictionary("/v1/dictionary/event-sources")

  if (
    (!typesReq.isLoading && !sourcesReq.isLoading) ||
    typesReq.error ||
    sourcesReq.error
  )
    return {
      data: {
        types: typesReq.data,
        sources: sourcesReq.data,
      },
      error: typesReq.error || sourcesReq.error,
    }

  return {
    data: null,
    error: null,
  }
}
