import { useFetchDictionary } from "domain/dictionary/hooks/useFetchDictionary"

export const usePrepareTransactionDictionaries = () => {
  const countriesReq = useFetchDictionary("/v1/dictionary/countries")
  const cryptoAssetsReq = useFetchDictionary("/v1/dictionary/crypto-assets")
  const currenciesReq = useFetchDictionary("/v1/dictionary/currencies")
  const operationsReq = useFetchDictionary("/v1/dictionary/operations")
  const paymentMethodsReq = useFetchDictionary("/v1/dictionary/payment-methods")
  const paymentSystemStatusesReq = useFetchDictionary(
    "/v1/dictionary/payment-systems-statuses"
  )
  const paymentSystemSubmethodsReq = useFetchDictionary(
    "/v1/dictionary/payment-systems-submethods"
  )
  const transactionStatusesReq = useFetchDictionary(
    "/v1/dictionary/transaction-statuses"
  )

  if (
    !countriesReq.isLoading &&
    !cryptoAssetsReq.isLoading &&
    !currenciesReq.isLoading &&
    !operationsReq.isLoading &&
    !paymentMethodsReq.isLoading &&
    !paymentSystemStatusesReq.isLoading &&
    !paymentSystemSubmethodsReq.isLoading &&
    !transactionStatusesReq.isLoading
  ) {
    return {
      countries: countriesReq.data,
      cryptoAssets: cryptoAssetsReq.data,
      currencies: currenciesReq.data,
      operations: operationsReq.data,
      paymentMethods: paymentMethodsReq.data,
      paymentSystemStatuses: paymentSystemStatusesReq.data,
      paymentSystemSubmethods: paymentSystemSubmethodsReq.data,
      transactionStatuses: transactionStatusesReq.data,
    }
  }

  return null
}
