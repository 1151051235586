import { useCallback } from "react"
import { useSafeState } from "./useSafeState"

export const useOpenable = (defaultState = null) => {
  const [anchorEl, setAnchorEl] = useSafeState(defaultState)

  const onOpen = useCallback((e) => setAnchorEl(e.currentTarget), [])
  const onClose = useCallback(() => setAnchorEl(null), [])

  return { open: !!anchorEl, anchorEl, onOpen, onClose }
}
