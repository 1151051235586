import { createContext } from "use-context-selector"

import { createProvider } from "domain/createProvider"

const initState = {
  id: null,
  name: "",
  userId: null,
  permissions: [],
}

export const UserContext = createContext(initState)

export const UserProvider = createProvider(UserContext, initState)
