import React, { useCallback, useEffect, useState } from "react"
import { Search } from "@unlimint/admin-ui-kit"

export const SearchBlock = ({
  placeholder,
  columns,
  list,
  onChange,
  onServerSearch,
  isServerSearch,
}) => {
  const [value, setValue] = useState("")

  const filterLocalList = useCallback(
    (searchValue) => {
      if (!searchValue) {
        onChange(null)
        return
      }

      const filteredList = list.filter((item) => {
        const regex = new RegExp(searchValue, "gi")

        return columns.reduce(
          (acc, column) => acc || item[column].search(regex) !== -1,
          false
        )
      })

      if (!!searchValue && filteredList.length !== list.length)
        onChange(filteredList)
    },
    [list, onChange, columns]
  )

  useEffect(() => {
    const searchValue = value.trim()

    if (isServerSearch) {
      const filters = columns.reduce((acc, key) => {
        acc[key] = searchValue
        return acc
      }, {})
      onServerSearch(filters)
      return
    }

    filterLocalList(searchValue)
  }, [value, columns, isServerSearch])

  const handlerSearch = useCallback(
    (e) => {
      setValue(e.target.value)
    },
    [setValue]
  )

  const handlerClear = useCallback(() => {
    setValue("")
  }, [setValue])

  const handlerBlur = useCallback(() => {
    setValue((prev) => prev.trim())
  }, [setValue])

  return (
    <Search
      placeholder={placeholder}
      value={value}
      onChange={handlerSearch}
      onClickEndIcon={handlerClear}
      onBlur={handlerBlur}
    />
  )
}
