export const dataToRow = (item) => ({
  id: item.id,
  merchantId: item.merchantId,
  fullname: `${item.name} ${item.surname}`,
  name: item.name,
  surname: item.surname,
  email: item.email,
  groups: item.groups,
  active: item.active,
})

export const dataToEntity = (data) => {
  return data.groups.reduce((prev, item) => {
    prev.push({ value: item, label: item })
    return prev
  }, [])
}

export const mapToReqModel = (data) => ({
  userId: data.id,
  name: data.name,
  surname: data.surname,
  groups: data.groups,
  active: data.active,
})
