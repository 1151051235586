import { createContext } from "use-context-selector"

import { createProvider } from "domain/createProvider"

const initState = {
  item: {
    data: null,
    isLoading: false,
    error: null,
  },
}

export const DetailsContext = createContext(initState)

export const DetailsProvider = createProvider(DetailsContext, initState)
