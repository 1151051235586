import React, { useState } from "react"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { CopyIcon16, TickIcon16 } from "@unlimint/admin-ui-kit"

import { truncate as onTruncate } from "infrastructure/utils/truncate"
import * as S from "./styled"

export const CopiedField = ({ value, truncate = false, Component = null }) => {
  const [timer, setTimer] = useState(null)
  let title = <div>{value}</div>

  if (truncate) {
    title = <span title={value}>{onTruncate(value)}</span>
  }

  if (Component) {
    title = Component
  }

  return (
    <S.Wrapper>
      {title}
      <S.Copy>
        <CopyToClipboard
          text={value}
          onCopy={() => {
            if (timer) clearTimeout(timer)
            setTimer(setTimeout(() => setTimer(null), 500))
          }}
        >
          {timer ? <TickIcon16 /> : <CopyIcon16 />}
        </CopyToClipboard>
      </S.Copy>
    </S.Wrapper>
  )
}
