import { apiService } from "infrastructure/services/ApiService"
import { errorService } from "infrastructure/services/ErrorService"

class WebhookService {
  async refreshSecret(id) {
    let data = null
    let error = null

    try {
      const response = await apiService.post(
        `/v1/webhooks/${id}/refresh-secret`
      )

      data = response.data.webhook
    } catch (err) {
      errorService.send(err)
      error = err.message
    }

    return { data, error }
  }
}

export const webhookSecret = new WebhookService()
