import {
  IFilterConfig,
  FilterTypeEnum,
  TFilterPropsItem,
  TFilterValue,
} from "@unlimint/admin-ui-kit"

import { TDictionaryList } from "domain/core/types"
import { dateToUTCTimestamp, timestampNow } from "domain/core/utils"
import { mapDictionaryToFilterMultiChoice } from "domain/dictionary/mappers"
import { TActivityLogListFilterReq } from "./types"

export const mapToActivityLogFilterReq = (
  filters: IFilterConfig
): TActivityLogListFilterReq => {
  if (!filters) return {}

  const res = filters.items.reduce((acc, item) => {
    if (!item.value) return acc

    if (item.type === FilterTypeEnum.String) {
      acc[item.field] = item.value
    }

    if (item.type === FilterTypeEnum.DateRange) {
      acc[item.field] = {
        from: item.value.start
          ? dateToUTCTimestamp(item.value.start)
          : dateToUTCTimestamp("2000-01-01T00:00:00"),
        to: item.value.end
          ? dateToUTCTimestamp(item.value.end)
          : timestampNow(),
      }
    }

    if (item.type === FilterTypeEnum.MultiChoice) {
      acc[item.field] = item.value.reduce((accVal, val) => {
        if (val.checked) accVal.push(val.name)
        return accVal
      }, [])
    }

    return acc
  }, {})

  return res
}

export const mergeDictionariesWithFilters = (
  filters: TFilterPropsItem<TFilterValue>[],
  dictionaries: TDictionaryList
) => {
  return filters.map((item) => {
    if (item.field === "objectType") {
      item.defaultValue = mapDictionaryToFilterMultiChoice(dictionaries.types)
    }

    if (item.field === "source") {
      item.defaultValue = mapDictionaryToFilterMultiChoice(dictionaries.sources)
    }

    return item
  })
}
