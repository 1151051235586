import React, { useCallback } from "react"
import { useContextSelector } from "use-context-selector"

import { useTranslate } from "infrastructure/hooks/useTranslate"

import { FormModeEnum } from "domain/enums/FormModeEnum"
import { FormContainer } from "domain/core/containers/FormContainer"
import { updateToGridDataState } from "domain/core/utils"
import { MarkupRulesContext } from "domain/settings/markupRules/MarkupRulesContext"
import { markupRuleSelectors } from "domain/settings/markupRules/selectors"
import { dataToRow, mapToReqModel } from "domain/settings/markupRules/mappers"
import { MarkupRuleForm } from "view/pages/settings/MarkupRulesPage/components/MarkupRuleForm"
import { validate } from "./validate"

export const EditMarkupRule = ({ modal, formData }) => {
  const initialValues = {
    cryptoAssetCode: formData?.cryptoAssetCode,
    buyMarkupRate: formData?.buyMarkupRate,
  }

  const mode = initialValues.buyMarkupRate
    ? FormModeEnum.Edit
    : FormModeEnum.Add

  const gridData = useContextSelector(
    MarkupRulesContext,
    markupRuleSelectors.gridData
  )

  const update = useContextSelector(
    MarkupRulesContext,
    markupRuleSelectors.update
  )

  const cbSuccess = useCallback(
    (data) => {
      update({
        gridData: updateToGridDataState(gridData, dataToRow(data)),
      })
    },
    [update]
  )

  return (
    <FormContainer
      mode={mode}
      validate={validate}
      initialValues={initialValues}
      url="/v1/markup-rules"
      mapToReqModel={mapToReqModel}
      Form={MarkupRuleForm}
      formProps={{
        title: useTranslate("pages.markupRules.modals.editTitle"),
        actionButtonTitle: useTranslate("pages.markupRules.modals.editButton"),
        onClose: modal.onClose,
      }}
      cbSuccess={cbSuccess}
    />
  )
}
