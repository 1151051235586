import { TActivityLog, TActivityLogDetails } from "./types"

export const dataToRow = (item: TActivityLog) => ({
  id: item.id,
  action: item.action,
  createdAt: item.createdAt,
  userEmail: item.userEmail,
  userId: item.userId,
  source: item.source,
  objectType: item.objectType,
  objectId: item.objectId,
})

export const dataToEntity = (item: TActivityLogDetails) => ({
  id: item.id,
  before: item.before,
  after: item.after,
})
