import { styled } from "@mui/material"

import { palette } from "view/themes/palette"

export const Wrapper = styled("div")(() => ({
  width: "100%",
  height: "calc(100vh - 250px)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
}))

export const Title = styled("div")(() => ({
  fontWeight: 500,
  fontSize: 20,
  lineHeight: "30px",
  color: palette.black,
}))

export const Text = styled("span")(() => ({
  textAlign: "center",
  lineHeight: "24px",
  color: palette.grey500,
  whiteSpace: "pre-wrap",
}))
