import { TCheckboxValue } from "@unlimint/admin-ui-kit"
import { TDictionary } from "../core/types"

export const mapDictionaryToFilterMultiChoice = (
  dictionary: TDictionary[]
): TCheckboxValue[] => {
  return dictionary.map((item) => {
    return {
      label: item.title,
      name: item.value,
    }
  })
}
