import React, { useState } from "react"
import { Form } from "formik"
import { Button } from "@unlimint/admin-ui-kit"
import { CopyToClipboard } from "react-copy-to-clipboard"

import { useTranslate } from "infrastructure/hooks/useTranslate"
import { errorService } from "infrastructure/services/ErrorService"

import { InputField } from "view/components/formik/InputField"
import { CheckboxField } from "view/components/formik/CheckboxField"
import { SelectField } from "view/components/formik/SelectField"

import * as S from "./styled"

export const EditWebhookForm = ({
  title,
  actionButtonTitle,
  isSubmitting,
  onClose,
  values,
  refreshSecret,
  setFieldValue,
  errors,
  disabled,
  eventOptions,
  events,
}) => {
  const [refreshIsLoading, setRefreshIsLoading] = useState(false)

  const close = useTranslate("pages.webhooks.modals.close")
  const url = useTranslate("pages.webhooks.modals.url")
  const name = useTranslate("pages.webhooks.modals.name")
  const version = useTranslate("pages.webhooks.modals.version")
  const versionPlaceholder = useTranslate(
    "pages.webhooks.modals.versionPlaceholder"
  )
  const isActive = useTranslate("pages.webhooks.modals.isActive")
  const webhookEventLabel = useTranslate(
    "pages.webhooks.modals.webhookEventLabel"
  )
  const copySecret = useTranslate("pages.webhooks.modals.copySecret")
  const resetSecret = useTranslate("pages.webhooks.resetSecret")

  return (
    <Form>
      <S.Wrapper>
        <S.Title>{title}</S.Title>

        <S.FormRow>
          <CheckboxField label={isActive} name="isActive" />
        </S.FormRow>

        <S.FormRow>
          <InputField label={name} name="name" />
        </S.FormRow>

        <S.FormRow>
          <InputField label={url} name="url" />
        </S.FormRow>

        <S.FormRow>
          <SelectField
            label={version}
            name="apiVersion"
            options={eventOptions}
            placeholder={versionPlaceholder}
          />
        </S.FormRow>

        <S.FormRow>
          <S.SecretKey>{values.secretKey}</S.SecretKey>

          <S.Buttons>
            <CopyToClipboard text={values.secretKey}>
              <Button size="small" color="secondary" disabled={disabled}>
                {copySecret}
              </Button>
            </CopyToClipboard>

            <Button
              size="small"
              color="secondary"
              disabled={disabled || refreshIsLoading}
              onClick={async () => {
                try {
                  setRefreshIsLoading(true)
                  const webhook = await refreshSecret(values.id)
                  setFieldValue("secretKey", webhook.secretKey)
                } catch (e) {
                  errorService.send(e)
                } finally {
                  setRefreshIsLoading(false)
                }
              }}
            >
              {resetSecret}
            </Button>
          </S.Buttons>
        </S.FormRow>

        <S.FormRow>
          <S.CheckboxGroupLabel>{webhookEventLabel}</S.CheckboxGroupLabel>
          {events[values.apiVersion].map((webhookEvent) => {
            return (
              <CheckboxField
                key={webhookEvent}
                label={webhookEvent}
                name="events"
                value={webhookEvent}
                disabled={disabled}
              />
            )
          })}
          <S.EventsError>{errors?.events}</S.EventsError>
        </S.FormRow>

        <S.Buttons>
          <Button size="small" variant="outlined" onClick={onClose}>
            {close}
          </Button>
          <Button size="small" type="submit" disabled={isSubmitting}>
            {actionButtonTitle}
          </Button>
        </S.Buttons>
      </S.Wrapper>
    </Form>
  )
}
