import { createContext } from "use-context-selector"

import { createProvider } from "domain/createProvider"
import { getDataGridInitState } from "domain/utils"

const initState = getDataGridInitState()

export const TransactionContext = createContext(initState)

export const TransactionProvider = createProvider(TransactionContext, initState)
