import React from "react"
import { Form } from "formik"
import { Button } from "@unlimint/admin-ui-kit"

import { useTranslate } from "infrastructure/hooks/useTranslate"

import { InputField } from "view/components/formik/InputField"
import { CheckboxField } from "view/components/formik/CheckboxField"
import { SelectField } from "view/components/formik/SelectField"

import * as S from "./styled"

export const AddWebhookForm = ({
  title,
  actionButtonTitle,
  isSubmitting,
  values,
  onClose,
  errors,
  disabled,
  eventOptions,
  events,
}) => {
  const close = useTranslate("pages.webhooks.modals.close")
  const url = useTranslate("pages.webhooks.modals.url")
  const name = useTranslate("pages.webhooks.modals.name")
  const version = useTranslate("pages.webhooks.modals.version")
  const versionPlaceholder = useTranslate(
    "pages.webhooks.modals.versionPlaceholder"
  )
  const isActive = useTranslate("pages.webhooks.modals.isActive")
  const webhookEventLabel = useTranslate(
    "pages.webhooks.modals.webhookEventLabel"
  )

  return (
    <Form>
      <S.Wrapper>
        <S.Title>{title}</S.Title>

        <S.FormRow>
          <CheckboxField label={isActive} name="isActive" disabled={disabled} />
        </S.FormRow>

        <S.FormRow>
          <InputField label={name} name="name" disabled={disabled} />
        </S.FormRow>

        <S.FormRow>
          <InputField label={url} name="url" disabled={disabled} />
        </S.FormRow>

        <S.FormRow>
          <SelectField
            label={version}
            name="apiVersion"
            options={eventOptions}
            placeholder={versionPlaceholder}
          />
        </S.FormRow>

        <S.FormRow>
          <S.CheckboxGroupLabel>{webhookEventLabel}</S.CheckboxGroupLabel>
          {events[values.apiVersion].map((webhookEvent) => {
            return (
              <CheckboxField
                key={webhookEvent}
                label={webhookEvent}
                name="events"
                value={webhookEvent}
                disabled={disabled}
              />
            )
          })}
          <S.EventsError>{errors?.events}</S.EventsError>
        </S.FormRow>

        <S.Buttons>
          <Button
            size="small"
            variant="outlined"
            onClick={onClose}
            disabled={disabled}
          >
            {close}
          </Button>
          <Button
            size="small"
            type="submit"
            disabled={isSubmitting || disabled}
          >
            {actionButtonTitle}
          </Button>
        </S.Buttons>
      </S.Wrapper>
    </Form>
  )
}
