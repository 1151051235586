import { styled } from "@mui/material"
import { palette } from "view/themes/palette"

export const Wrapper = styled("div")(() => ({
  paddingBottom: 32,
}))
export const Header = styled("div")(() => ({
  display: "flex",
  justifyContent: "space-between",
  paddingBottom: 24,
  borderBottom: `1px solid ${palette.grey200}`,
}))
export const Title = styled("div")(() => ({
  fontSize: 20,
  lineHeight: "32px",
  fontWeight: 500,
}))
export const Items = styled("div")(() => ({
  padding: "16px 0",
  display: "grid",
  gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
  gridGap: "20px",
}))
export const Label = styled("div")(() => ({
  fontSize: 14,
  lineHeight: "24px",
  fontWeight: 500,
}))
export const Value = styled("div")(() => ({
  fontSize: 12,
  lineHeight: "20px",
}))
