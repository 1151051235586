import { useTranslate } from "infrastructure/hooks/useTranslate"

import { isValidUrl } from "domain/core/utils"

import { TWebhookError } from "../types"

export const validate = (values) => {
  const errors: TWebhookError = {}

  if (!values.url || values.url === "https://" || values.url === "http://")
    errors.url = useTranslate("validation.required")
  else if (!isValidUrl(values.url))
    errors.url = useTranslate("validation.invalidUrl")

  if (!values.name) errors.name = useTranslate("validation.required")

  if (!values.events.length)
    errors.events = useTranslate("pages.webhooks.modals.notSelectedEvents")

  return errors
}
