import { useCallback } from "react"
import { useContextSelector } from "use-context-selector"

import { useTranslate } from "infrastructure/hooks/useTranslate"

import { FormModeEnum } from "domain/enums/FormModeEnum"
import { FormContainer } from "domain/core/containers/FormContainer"
import { updateToGridDataState } from "domain/core/utils"

import { EditWebhookForm } from "view/pages/devSpace/WebhooksPage/components/EditWebhookForm"
import { validate } from "./validate"
import { WebhookContext } from "../WebhookContext"
import { webhookSelectors } from "../selectors"
import { dataToRow, mapToReqModel } from "../mappers"
import { webhookSecret } from "../services/WebhookService"

export const EditWebhookContainer = ({ modal, formData }) => {
  const editTitle = useTranslate("pages.webhooks.modals.editTitle")
  const editButton = useTranslate("pages.webhooks.modals.editButton")

  const gridData = useContextSelector(WebhookContext, webhookSelectors.gridData)
  const update = useContextSelector(WebhookContext, webhookSelectors.update)
  const events = useContextSelector(WebhookContext, webhookSelectors.events)

  const disabled = !events || events.length === 0
  const eventOptions = Object.keys(events).map((key) => ({
    label: key,
    value: key,
  }))

  const handlerRefreshSecret = async (id) => {
    const { data, error } = await webhookSecret.refreshSecret(id)

    update({
      gridData: updateToGridDataState(gridData, dataToRow(data)),
    })

    return data
  }

  const cbSuccess = useCallback(
    (data) => {
      update({
        gridData: updateToGridDataState(gridData, dataToRow(data.webhook)),
      })
    },
    [gridData, update]
  )

  return (
    <FormContainer
      mode={FormModeEnum.Edit}
      validate={validate}
      initialValues={formData}
      url={`/v1/webhooks/${formData?.id}`}
      Form={EditWebhookForm}
      mapToReqModel={mapToReqModel}
      formProps={{
        title: editTitle,
        actionButtonTitle: editButton,
        onClose: modal.onClose,
        refreshSecret: handlerRefreshSecret,
        disabled,
        eventOptions,
        events,
      }}
      cbSuccess={cbSuccess}
    />
  )
}
