import { Button } from "@unlimint/admin-ui-kit"

import { useTranslate } from "infrastructure/hooks/useTranslate"

import * as S from "./styled"

export const ConfirmForm = ({
  title,
  text,
  actionButtonTitle,
  onClose,
  onSubmit,
  isSubmitting,
}) => {
  const close = useTranslate("shared.close")

  return (
    <S.Wrapper>
      <S.Title>{title}</S.Title>

      <S.Text>{text}</S.Text>

      <S.Buttons>
        <Button size="small" variant="outlined" onClick={onClose}>
          {close}
        </Button>
        <Button
          size="small"
          color="error"
          onClick={onSubmit}
          disabled={isSubmitting}
        >
          {actionButtonTitle}
        </Button>
      </S.Buttons>
    </S.Wrapper>
  )
}
