import { createContext } from "use-context-selector"

import { createProvider } from "domain/createProvider"

const initState = {
  item: {
    data: {},
    isLoading: false,
    error: null,
  },
}

export const BalanceContext = createContext(initState)

export const BalanceProvider = createProvider(BalanceContext, initState)
