import { useMemo } from "react"
import { Switch } from "@unlimint/admin-ui-kit"

import { useTranslate } from "infrastructure/hooks/useTranslate"

import { useTitle } from "domain/application/hooks/useTitle"
import {
  NetworkFeesProvider,
  NetworkFeesContext,
} from "domain/settings/networkFees/NetworkFeesContext"
import { networkFeesSelectors } from "domain/settings/networkFees/selectors"
import { GridContainer } from "domain/core/containers/GridContainer"
import { dataToRow } from "domain/settings/networkFees/mappers"
import { AccessPageContainer } from "domain/core/containers/AccessPageContainer"
import { PermissionsEnum } from "domain/rbac/PermissionsEnum"

import * as S from "./styled"

const Page = () => {
  const asset = useTranslate("pages.networkFees.grid.asset")
  const enabledStatus = useTranslate("pages.networkFees.grid.enabledStatus")
  const searchPlaceholder = useTranslate("pages.networkFees.searchPlaceholder")

  const initParams = useMemo(
    () => ({
      sort: [
        {
          columnName: "cryptoAssetCode",
          direction: "asc",
        },
      ],
    }),
    []
  )

  const columns = useMemo(
    () => [
      {
        field: "cryptoAssetCode",
        headerName: asset,
        width: 160,
      },
      {
        field: "isEnabled",
        headerName: enabledStatus,
        minWidth: 120,
        flex: 1,
        renderCell: ({ value }) => (
          <S.Switch>
            <Switch checked={value} />
          </S.Switch>
        ),
      },
    ],
    []
  )

  return (
    <NetworkFeesProvider>
      <GridContainer
        url="/v1/crypto-assets/promo/list"
        context={NetworkFeesContext}
        selectors={networkFeesSelectors}
        columns={columns}
        initParams={initParams}
        dataToRow={dataToRow}
        searchBlockProps={{
          placeholder: searchPlaceholder,
          columns: ["code"],
        }}
        isServerSearch
      />
    </NetworkFeesProvider>
  )
}

export const NetworkFeesPage = () => {
  const title = useTranslate("pages.networkFees.title")

  useTitle(title)

  return (
    <AccessPageContainer permissions={[PermissionsEnum.DetailsView]}>
      <Page />
    </AccessPageContainer>
  )
}
