import { styled } from "@mui/material"

export const Title = styled("div")(() => ({
  fontFamily: "Inter",
  fontWeight: 600,
  fontSize: 16,
  lineHeight: "20px",
  marginBottom: 32,
}))

export const FormRow = styled("div")(() => ({
  marginBottom: 32,
}))

export const Buttons = styled("div")(() => ({
  display: "flex",
  gap: 12,
}))
