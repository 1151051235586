import dayjs from "dayjs"

const customParseFormat = require("dayjs/plugin/customParseFormat")
const utc = require("dayjs/plugin/utc")

dayjs.extend(customParseFormat)
dayjs.extend(utc)

export const insertToGridDataState = (gridData, item) => {
  return {
    ...gridData,
    list: [...gridData.list, item],
  }
}

export const updateToGridDataState = (gridData, item) => {
  return {
    ...gridData,
    list: gridData.list.map((i) => (i.id === item.id ? item : i)),
  }
}

export const deleteToGridDataState = (gridData, item) => {
  return {
    ...gridData,
    list: gridData.list.filter(
      (i) => i.id.toLowerCase() !== item.id.toLowerCase()
    ),
  }
}

export const queryToString = (params) => {
  const toString = (obj, prefix) => {
    const keys = Object.keys(obj)

    const result = []
    keys.forEach((key) => {
      const path = prefix ? [prefix, key].join(".") : key

      switch (typeof obj[key]) {
        case "object":
          result.push(...toString(obj[key], path))
          break
        case "undefined":
          break
        case "array":
          break
        default:
          result.push(`${path}=${obj[key]}`)
      }
    })

    return result
  }

  return toString(params).join("&")
}

export const formatShortDate = (value) => {
  if (!value) return "—"

  return dayjs.unix(value).utc().format("MMM DD")
}

export const formatDateWithYear = (value) => {
  if (!value) return "—"

  return dayjs.unix(value).utc().format("MMM, DD YYYY")
}

export const formatDatetime = (value) => {
  if (!value) return "—"

  return dayjs.unix(value).utc().format("DD-MM-YYYY HH:mm:ss")
}

export const formatTime = (value) => {
  if (!value) return "—"

  return dayjs.unix(value).utc().format("HH:mm:ss A")
}

export const formatTimeDate = (value) => {
  if (!value) return "—"

  return dayjs.unix(value).utc().format("HH:mm:ss, DD.MM.YYYY")
}

export const renderDatetimeCell = ({ value }) => formatDatetime(value)

export const dateToUTCTimestamp = (value) => {
  return dayjs(value).utc(true).unix()
}

export const isValidUrl = (string) => {
  try {
    const newUrl = new URL(string)
    return newUrl.protocol === "http:" || newUrl.protocol === "https:"
  } catch (err) {
    return false
  }
}

export const timestampNow = () => dayjs(new Date().setSeconds(0)).unix()

export const timestampToFilterDate = (value) => {
  return dayjs.unix(value).utc().format("YYYY-MM-DDTHH:mm:ss")
}
