import { styled } from "@mui/material"
import { palette } from "view/themes/palette"

export const Empty = styled("div")(() => ({
  fontSize: 15,
}))

export const PnlGroup = styled("div")(() => ({
  marginBottom: 16,
}))

export const Row = styled("div")(() => ({
  display: "flex",
  fontSize: 14,
  lineHeight: "24px",
}))

export const Label = styled("div")(({ length, isBold }) => ({
  minWidth: length * 9,
  maxWidth: length * 9,
  fontWeight: isBold && 700,
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
}))

export const Amount = styled("div")(({ length, isBold }) => ({
  paddingLeft: 9,
  minWidth: length * 9,
  textAlign: "right",
  fontWeight: isBold && 700,
}))

export const Currency = styled("div")(({ length }) => ({
  paddingLeft: 5,
  minWidth: length * 10,
  color: palette.grey400,
}))
