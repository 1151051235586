import { styled } from "@mui/material"

import { palette } from "view/themes/palette"

export const Indicator = styled("div")<{ status: string }>(({ status }) => ({
  width: 8,
  height: 8,
  borderRadius: "50%",
  background: status === "succeeded" ? palette.success500 : palette.error500,
}))

export const DateCellContainer = styled("div")(() => ({
  position: "relative",
  height: 32,
  display: "flex",
  alignItems: "center",
  paddingRight: 5,
}))

export const RowIndicator = styled("div")<{ isSelected: boolean }>(
  ({ isSelected }) => ({
    display: isSelected ? "block" : "none",
    position: "absolute",
    top: 0,
    right: -12,
    width: "5px",
    height: "32px",
    borderRadius: "4px",
    background: palette.info500,
  })
)
