import { styled } from "@mui/material"
import { palette } from "view/themes/palette"
import { ActionEnum } from "./actionEnum"

export const EmptyScreenWrapp = styled("div")(() => ({
  height: "calc(100vh - 186px)",
}))

export const ActionWrapper = styled("div")<{ action: string }>(
  ({ action }) => ({
    backgroundColor: (() => {
      switch (action) {
        case ActionEnum.Created:
          return palette.success100
        case ActionEnum.Updated:
          return palette.info200
        case ActionEnum.Deleted:
          return palette.error100
        default:
          return palette.info100
      }
    })(),
    width: 60,
    padding: "2px 14px",
    borderRadius: 8,
    textTransform: "capitalize",
    textAlign: "center",
  })
)

export const Capitalize = styled("div")(() => ({
  textTransform: "capitalize",
}))

export const Action = styled("span")(() => ({
  color: palette.info500,
  textDecoration: "none",
  cursor: "pointer",

  "&:hover": {
    textDecorationLine: "underline",
  },
}))
