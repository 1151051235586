export const dataToRow = (item) => ({
  id: item.cryptoAssetCode,
  cryptoAssetCode: item.cryptoAssetCode,
  buyMarkupRate: item.buyMarkupRate,
})

export const mapToReqModel = (item) => ({
  cryptoAssetCode: item.cryptoAssetCode,
  buyMarkupRate: item.buyMarkupRate,
})
