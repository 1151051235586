import { styled, Typography } from "@mui/material"
import { palette } from "view/themes/palette"

export const Wrapper = styled("div")(({ theme }) => ({
  zIndex: 1,
  minHeight: "100vh",
  backgroundColor: palette.white,
  position: "relative",
}))

export const LeftSidebar = styled("div")(({ theme, open }) => ({
  display: "flex",
  flexDirection: "column",
  width: open ? 240 : 76,
  background: "linear-gradient(180deg, #F2F2F2 0%, #FFFFFF 100%)",
}))

export const LeftBlock = styled("div")(() => ({
  position: "fixed",
  top: 0,
  left: 0,
  zIndex: 100,
}))

export const RightBlock = styled("div")(({ open }) => ({
  marginLeft: open ? 192 : 76,
  display: "flex",
  flexDirection: "column",
  flexGrow: 2,
}))

export const Content = styled("div")(({ theme }) => ({
  padding: "24px 32px",
}))
