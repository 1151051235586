import { useMemo, useState } from "react"
import { useContextSelector } from "use-context-selector"
import { Dialog } from "@unlimint/admin-ui-kit"

import { apiService } from "infrastructure/services/ApiService"
import { errorService } from "infrastructure/services/ErrorService"
import { useTranslate } from "infrastructure/hooks/useTranslate"

import { useTitle } from "domain/application/hooks/useTitle"
import { TeamContext, TeamProvider } from "domain/team/TeamContext"
import { teamSelectors } from "domain/team/selectors"
import { dataToEntity, dataToRow, mapToReqModel } from "domain/team/mappers"
import { FetchEntityContainer } from "domain/core/containers/FetchEntityContainer"
import { GridContainer } from "domain/core/containers/GridContainer"
import { updateToGridDataState } from "domain/core/utils"
import { AddTeamUserContainer } from "domain/team/AddTeamUserContainer"
import { EditTeamUserContainer } from "domain/team/EditTeamUserContainer"
import { DeleteUserContainer } from "domain/team/DeleteUserContainer"
import { TUpdate } from "domain/types"
import { AccessPageContainer } from "domain/core/containers/AccessPageContainer"
import { PermissionsEnum } from "domain/rbac/PermissionsEnum"

export const Page = () => {
  const searchPlaceholder = useTranslate("pages.team.searchPlaceholder")
  const addButton = useTranslate("pages.team.addButton")
  const name = useTranslate("pages.team.grid.name")
  const email = useTranslate("pages.team.grid.email")
  const roles = useTranslate("pages.team.grid.roles")
  const status = useTranslate("pages.team.grid.status")
  const active = useTranslate("pages.team.grid.active")
  const inactive = useTranslate("pages.team.grid.inactive")
  const resetPass = useTranslate("pages.team.grid.resetPass")
  const deleteUser = useTranslate("pages.team.grid.deleteUser")

  const gridData = useContextSelector(TeamContext, teamSelectors.gridData)
  const update: TUpdate<any> = useContextSelector(
    TeamContext,
    teamSelectors.update
  )

  const [isConfirmModal, setIsConfirmModal] = useState(false)
  const [deleteItem, setDeleteItem] = useState(null)

  const onCloseConfirm = () => setIsConfirmModal(false)

  const initParams = useMemo(
    () => ({
      sort: [],
    }),
    []
  )

  const columns = useMemo(() => {
    return [
      {
        field: "fullname",
        headerName: name,
        width: 250,
      },
      {
        field: "email",
        headerName: email,
        width: 250,
      },
      {
        field: "groups",
        headerName: roles,
        minWidth: 250,
        flex: 1,
        renderCell: ({ value }) => value.join(", "),
      },
      {
        field: "active",
        headerName: status,
        minWidth: 150,
        flex: 1,
        renderCell: ({ value }) => (value ? active : inactive),
      },
    ]
  }, [])

  const switchActiveStatus = (row) => {
    apiService
      .put(`/v1/sso/users/${row.id}`, {
        ...mapToReqModel(row),
        active: !row.active,
      })
      .then(({ data }) => {
        update({
          gridData: updateToGridDataState(gridData, dataToRow(data)),
        })
      })
      .catch((err) => {
        errorService.send(err)
      })
  }

  const resetPassword = (row) => {
    apiService
      .post(`/v1/sso/users/${row.id}/reset`, {
        userId: row.id,
      })
      .catch((err) => {
        errorService.send(err)
      })
  }

  const submenu = [
    {
      title: (row) => (row.active ? inactive : active),
      isSwitchTitle: true,
      onClick: switchActiveStatus,
    },
    {
      title: resetPass,
      onClick: resetPassword,
    },
    {
      title: deleteUser,
      onClick: (row) => {
        setDeleteItem(row)
        setIsConfirmModal(true)
      },
    },
  ]

  return (
    <>
      <FetchEntityContainer
        resourceContext={TeamContext}
        resourceSelectors={teamSelectors}
        url="/v1/sso/groups"
        options={{
          mapper: dataToEntity,
        }}
      />

      <GridContainer
        url="/v1/sso/users/list"
        columns={columns}
        initParams={initParams}
        context={TeamContext}
        selectors={teamSelectors}
        dataToRow={dataToRow}
        submenu={submenu}
        searchBlockProps={{
          placeholder: searchPlaceholder,
          columns: ["search"],
        }}
        isServerSearch
        buttonProps={{ title: addButton }}
        isEditable
        AddForm={AddTeamUserContainer}
        EditForm={EditTeamUserContainer}
      />
      <Dialog open={isConfirmModal} onClose={onCloseConfirm}>
        <DeleteUserContainer deleteItem={deleteItem} onClose={onCloseConfirm} />
      </Dialog>
    </>
  )
}

export const TeamPage = () => {
  const title = useTranslate("pages.team.title")

  useTitle(title)

  return (
    <AccessPageContainer permissions={[PermissionsEnum.RolesEdit]}>
      <TeamProvider>
        <Page />
      </TeamProvider>
    </AccessPageContainer>
  )
}
