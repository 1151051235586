import { FC, useEffect, useState } from "react"
import ReactJson from "react-json-view"
import { useContextSelector } from "use-context-selector"
import { EmptyScreen, Switch } from "@unlimint/admin-ui-kit"

import { compareObjects } from "infrastructure/utils/compareObjects"
import { useTranslate } from "infrastructure/hooks/useTranslate"

import { FetchEntityContainer } from "domain/core/containers/FetchEntityContainer"
import {
  ActivityLogContext,
  ActivityLogProvider,
} from "domain/activityLog/ActivityLogContext"
import { dataToEntity } from "domain/activityLog/mappers"
import { activityLogSelectors } from "domain/activityLog/selectors"
import { TActivityLog } from "domain/activityLog/types"
import { formatDatetime } from "domain/core/utils"

import { GridRow } from "./GridRow"
import * as S from "./styled"
import * as LS from "../../styled"
import { ActionEnum } from "../../actionEnum"

type TGetContent = {
  [key: string]: any
}

const getContent = (key: string, value: TGetContent | string | number) => {
  if (typeof value === "object") {
    return <ReactJson src={value} name={key} displayDataTypes={false} />
  }

  if (key === "createdAt" || key === "updatedAt") {
    return formatDatetime(value)
  }

  return !value && String(value) !== "false" && String(value) !== "0"
    ? "—"
    : String(value)
}

type TActivityLogPageProps = {
  onClose: () => void
  rowData: TActivityLog
}

const Page: FC<TActivityLogPageProps> = ({ onClose, rowData }) => {
  const changes = useTranslate("pages.activityLogs.details.title.changes")
  const withIdTitle = useTranslate("pages.activityLogs.details.title.withId", {
    id: rowData?.objectId,
  })
  const action = useTranslate("pages.activityLogs.details.action")
  const changedBy = useTranslate("pages.activityLogs.details.changedBy")
  const date = useTranslate("pages.activityLogs.details.date")
  const source = useTranslate("pages.activityLogs.details.source")
  const section = useTranslate("pages.activityLogs.details.section")
  const showDiff = useTranslate("pages.activityLogs.details.showDiff")
  const itemlabel = useTranslate("pages.activityLogs.details.item")
  const beforelabel = useTranslate("pages.activityLogs.details.before")
  const afterLabel = useTranslate("pages.activityLogs.details.after")
  const value = useTranslate("pages.activityLogs.details.value")
  const noChangedData = useTranslate("pages.activityLogs.details.noChangedData")

  const item = useContextSelector(ActivityLogContext, activityLogSelectors.item)
  const { before, after } = item?.data || {}
  const beforeData = Object.keys(before || {}).length ? before : null
  const afterData = Object.keys(after || {}).length ? after : null
  const keys = Object.keys(beforeData || afterData || {})

  const [isShowDiff, setIsShowDiff] = useState(true)
  const [isEmptyDiff, setIsEmptyDiff] = useState(false)

  useEffect(() => {
    if (
      rowData.action === ActionEnum.Updated &&
      beforeData &&
      afterData &&
      compareObjects(beforeData, afterData)
    ) {
      setIsEmptyDiff(true)
    }
  }, [beforeData, afterData, rowData.action])

  const showDiffSwitchHandler = () => {
    setIsShowDiff((prev) => !prev)
  }

  return (
    <S.Wrapper>
      <S.Header>
        <S.Title>
          {changes} {rowData?.objectId && withIdTitle}
        </S.Title>
        <S.Close onClick={onClose} />
      </S.Header>

      <S.Info>
        <span>{action}</span>
        <LS.ActionWrapper action={rowData.action}>
          {rowData.action}
        </LS.ActionWrapper>
      </S.Info>
      <S.Info>
        <span>{changedBy}</span>
        <span>{rowData.userEmail}</span>
      </S.Info>
      <S.Info>
        <span>{date}</span>
        <span>{formatDatetime(rowData.createdAt)}</span>
      </S.Info>
      <S.Info>
        <span>{source}</span>
        <LS.Capitalize>{rowData.source}</LS.Capitalize>
      </S.Info>
      <S.Info>
        <span>{section}</span>
        <span>{rowData.objectType}</span>
      </S.Info>
      {rowData.action === ActionEnum.Updated && (
        <S.Info>
          <span>{showDiff}</span>
          <S.Switch>
            <Switch checked={isShowDiff} onChange={showDiffSwitchHandler} />
          </S.Switch>
        </S.Info>
      )}

      {!keys.length && !item.isLoading ? (
        <S.EmptyScreenWrapp>
          <EmptyScreen />
        </S.EmptyScreenWrapp>
      ) : (
        <>
          <S.Rows>
            <S.Cell>
              <S.Label>{itemlabel}</S.Label>
            </S.Cell>
            {rowData.action === ActionEnum.Updated ? (
              <>
                <S.Cell>
                  <S.Label>{beforelabel}</S.Label>
                </S.Cell>
                <S.Cell>
                  <S.Label>{afterLabel}</S.Label>
                </S.Cell>
              </>
            ) : (
              <S.Cell>
                <S.Label>{value}</S.Label>
              </S.Cell>
            )}
          </S.Rows>

          {isEmptyDiff && isShowDiff && (
            <S.EmptyDiff>{noChangedData}</S.EmptyDiff>
          )}

          {keys.map((key) => {
            const valueBefore = before[key]
            const valueAfter = after[key]
            const label = `${key.charAt(0).toUpperCase()}${key.slice(1)}`

            const contentBefore = getContent(key, valueBefore)
            const contentAfter = getContent(key, valueAfter)

            if (rowData.action === ActionEnum.Created) {
              return (
                <GridRow key={key} label={label} contentAfter={contentAfter} />
              )
            }

            if (rowData.action === ActionEnum.Deleted) {
              return (
                <GridRow
                  key={key}
                  label={label}
                  contentBefore={contentBefore}
                />
              )
            }

            if (!compareObjects(valueBefore, valueAfter) || !isShowDiff)
              return (
                <GridRow
                  key={key}
                  label={label}
                  contentBefore={contentBefore}
                  contentAfter={contentAfter}
                />
              )

            return null
          })}
        </>
      )}
    </S.Wrapper>
  )
}

type TActivityLogDetailsProps = {
  onClose: () => void
  data: TActivityLog
}

export const ActivityLogDetails: FC<TActivityLogDetailsProps> = ({
  data,
  onClose,
}) => {
  return (
    <ActivityLogProvider>
      <FetchEntityContainer
        url={`/v1/events/${data.id}`}
        resourceContext={ActivityLogContext}
        resourceSelectors={activityLogSelectors}
        options={{ mapper: dataToEntity }}
      />
      <Page onClose={onClose} rowData={data} />
    </ActivityLogProvider>
  )
}
