import { useState } from "react"

import { apiService } from "infrastructure/services/ApiService"
import { errorToText } from "infrastructure/utils/errorToText"
import { errorService } from "infrastructure/services/ErrorService"
import { ConfirmForm } from "view/components/ConfirmForm"
import { FormError } from "view/components/FormError"

export const DeleteContainer = ({
  url,
  formData,
  mapToReqModel,
  cbSuccess,
  formProps,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [commonError, setCommonError] = useState("")

  const handleSubmit = () => {
    setIsSubmitting(true)

    const data = mapToReqModel ? mapToReqModel(formData) : formData

    apiService
      .delete(url, { data })
      .then(() => {
        formProps.onClose?.()
        cbSuccess()
        setIsSubmitting(false)
      })
      .catch((err) => {
        if (err?.response?.data) {
          const { message } = err.response.data
          const errorMessage = errorToText(message)
          setCommonError(errorMessage)
        } else setCommonError(err.message)

        errorService.send(err)
        setIsSubmitting(false)
      })
  }

  return (
    <>
      <ConfirmForm
        onSubmit={handleSubmit}
        isSubmitting={isSubmitting}
        {...formProps}
      />
      <FormError error={commonError} />
    </>
  )
}
