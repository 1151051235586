export const getDataGridInitState = () => {
  return {
    gridData: {
      list: [],
      pagination: {
        total: 0,
        currentPage: 1,
        limit: 100,
      },
      sort: [],
      filters: null,
      isInited: false,
      isLoading: true,
      error: null,
    },
    item: {
      data: null,
      isLoading: false,
      error: null,
    },
  }
}
