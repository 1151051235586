export const validate = (values) => {
  const errors = {}

  if (values.buyMarkupRate === "") errors.buyMarkupRate = "Required"
  if (values.buyMarkupRate < 0)
    errors.buyMarkupRate = "Invalid value. Must not be less than 0."
  if (values.buyMarkupRate > 1)
    errors.buyMarkupRate = "Invalid value. Must not be more than 1."

  return errors
}
