import { TLogItem } from "domain/webhook/types"
import { formatDateWithYear } from "domain/core/utils"
import { replaceName } from "domain/webhook/utils"

export const dataToRow = (data) => ({
  id: data.id,
  name: data.name,
  url: data.url,
  apiVersion: data.apiVersion,
  isActive: data.isActive,
  events: data.events,
  secretKey: data.secretKey,
})

export const dataToListRow = (data) => ({
  id: data.webhookEventId,
  webhookId: data.webhookSettingsId,
  name: replaceName(data.eventName),
  status: data.status,
  date: data.createdAt,
})

export const mapToReqModel = (data) => ({
  name: data.name,
  url: data.url,
  apiVersion: data.apiVersion,
  isActive: data.isActive,
  events: data.events,
})

export const mapLogsData = (list: TLogItem[]) => {
  const groups = []
  const groupCounts = []
  const data = []

  list.forEach((item) => {
    const date = formatDateWithYear(item.createdAt)

    const isInGroups = groups.find((item) => item === date)
    if (!isInGroups) {
      groups.push(date)
      groupCounts.push(1)
    } else groupCounts[groupCounts.length - 1] += 1

    data.push({
      id: item.logId,
      ...item,
    })
  })

  return {
    data,
    groupCounts,
    groups,
  }
}
