import { Fragment, useMemo } from "react"

import * as S from "view/components/PnlDataGrid/styled"

export const PnlDataGrid = ({ data }) => {
  if (!data || (!data.pnlGroup.length && !data.commonGroup.length))
    return <S.Empty>—</S.Empty>

  const lengthObj = useMemo(() => {
    return data.pnlGroup.concat(data.commonGroup).reduce(
      (prev, { label, values }) => {
        if (label.length > prev.label) {
          prev.label = label.length
        }

        values.forEach(({ amount, currency }) => {
          if (amount.length > prev.amount) {
            prev.amount = amount.length
          }
          if (currency.length > prev.currency) {
            prev.currency = currency.length
          }
        })

        return prev
      },
      { label: 0, amount: 0, currency: 0 }
    )
  }, [])

  return (
    <>
      <S.PnlGroup>
        {data.pnlGroup.map((item) => {
          const { label, isBoldLabel, values } = item
          return (
            <S.Row key={label}>
              <S.Label length={lengthObj.label} isBold={isBoldLabel}>
                {label}
              </S.Label>
              {values.map((value) => (
                <Fragment key={label + value.currency}>
                  <S.Amount length={lengthObj.amount} isBold={isBoldLabel}>
                    {value.amount}
                  </S.Amount>
                  <S.Currency length={lengthObj.currency}>
                    {value.currency}
                  </S.Currency>
                </Fragment>
              ))}
            </S.Row>
          )
        })}
      </S.PnlGroup>

      {data.commonGroup.map((item) => {
        const { label, isBoldLabel, values } = item
        return (
          <S.Row key={label}>
            <S.Label length={lengthObj.label} isBold={isBoldLabel}>
              {label}
            </S.Label>
            {values.map((value) => (
              <Fragment key={label + value.currency}>
                <S.Amount length={lengthObj.amount} isBold={isBoldLabel}>
                  {value.amount}
                </S.Amount>
                <S.Currency length={lengthObj.currency}>
                  {value.currency}
                </S.Currency>
              </Fragment>
            ))}
          </S.Row>
        )
      })}
    </>
  )
}
