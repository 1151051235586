import { FilterTypeEnum } from "@unlimint/admin-ui-kit"
import { cloneDeep } from "lodash"

import { TFetchListParamReq, TGridContainerParams } from "domain/core/types"
import { timestampToFilterDate } from "domain/core/utils"

export const mapGridState = (
  params: TGridContainerParams,
  filterToReq: any
): TFetchListParamReq => {
  const { filters, pagination, sort } = params

  return {
    filters: filterToReq(filters),
    pagination,
    sort,
  }
}

export const setSavedFiltersToConfig = (savedFilters, filters) => {
  Object.keys(savedFilters).forEach((key) => {
    const value = savedFilters[key]
    const field = filters.items.find((item) => item.field === key)

    if (field) {
      if (field.type === FilterTypeEnum.DateRange) {
        const dateRange = {
          start: timestampToFilterDate(value.from),
          end: timestampToFilterDate(value.to),
        }
        field.appliedDefault = dateRange
        field.value = dateRange
        return
      }

      if (
        field.type === FilterTypeEnum.MultiChoice ||
        field.type === FilterTypeEnum.RadioGroup
      ) {
        const isRadioGroup = field.type === FilterTypeEnum.RadioGroup

        const appliedDefault = cloneDeep(field.defaultValue).map((item) => {
          const isChecked = value.find((name) => name === item.name)
          if (isChecked) {
            if (isRadioGroup) item.selected = true
            else item.checked = true
          }

          return item
        }, [])

        field.appliedDefault = appliedDefault
        field.value = appliedDefault
        return
      }

      field.appliedDefault = value
      field.value = value
    }
  })
}
