import { styled } from "@mui/material"

export const EmptyScreenWrapp = styled("div")(() => ({
  height: "calc(100vh - 293px)",
}))

export const Wrapper = styled("div")(() => ({
  marginTop: "32px",
  display: "flex",
}))

export const Title = styled("div")(() => ({
  marginTop: 24,
  fontWeight: 600,
}))
