import { styled } from "@mui/material"
import { palette } from "view/themes/palette"

export const Wrapper = styled("div")(() => ({
  width: "100%",
  marginLeft: "24px",
}))

export const TopContent = styled("div")(() => ({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
}))

export const Title = styled("div")(() => ({
  color: palette.black,
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "20px",
  lineHeight: "24px",
}))

export const GridRow = styled("div")(() => ({
  height: "20px",
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "13px",
  lineHeight: "20px",
  display: "flex",

  "& + &": {
    marginTop: "12px",
  },
}))

export const GridLabel = styled("div")(() => ({
  color: palette.grey500,
  width: "30px",
  textAlign: "left",
}))

export const GridValue = styled("div")(() => ({
  color: palette.black,
  display: "flex",
}))

export const AlertEventName = styled("span")(() => ({
  color: palette.info500,
}))
