import axios from "axios"
import { storageService } from "infrastructure/services/StorageService"
import { errorService } from "infrastructure/services/ErrorService"
import { keycloakService } from "domain/identity/KeycloakService"

export const apiService = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

apiService.getSource = () => {
  return axios.CancelToken.source()
}

apiService.interceptors.request.use(
  async (config) => {
    try {
      const token = await storageService.getItem("accessToken")
      const merchantId = await storageService.getItem("merchantId")

      if (token) config.headers.Authorization = `Bearer ${token}`
      if (merchantId) config.headers["X-Merchantid"] = merchantId
    } catch (e) {
      errorService.send(e)
    }
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

apiService.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    const originalRequest = error.config

    if (error.response?.status === 401 && !originalRequest._retried) {
      const localRefreshToken = storageService.getItem("refreshToken")

      if (!localRefreshToken) return Promise.reject(error)
      originalRequest._retried = true

      try {
        await keycloakService.updateToken()
        return apiService(originalRequest)
      } catch (e) {
        errorService.send(e)
        return Promise.reject(error)
      }
    }

    return Promise.reject(error)
  }
)
