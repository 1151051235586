import { createContext } from "use-context-selector"

import { createProvider } from "domain/createProvider"
import { getDataGridInitState } from "domain/utils"

const initState = {
  ...getDataGridInitState(),
}

export const WebhookEventsContext = createContext(initState)

export const WebhookEventsProvider = createProvider(
  WebhookEventsContext,
  initState
)
