import React, { useCallback, useEffect, useMemo, useState } from "react"

import { useTranslate } from "infrastructure/hooks/useTranslate"
import { truncate } from "infrastructure/utils/truncate"

import { useTitle } from "domain/application/hooks/useTitle"
import {
  TransactionContext,
  TransactionProvider,
} from "domain/transaction/TransactionContext"
import { transactionSelectors } from "domain/transaction/selectors"
import { GridContainer } from "domain/core/containers/GridContainer"
import { dataToRow } from "domain/transaction/mappers"
import { renderDatetimeCell } from "domain/core/utils"
import { GridStateStoreContainer } from "domain/core/containers/GridStateStoreContainer"
import { PermissionsEnum } from "domain/rbac/PermissionsEnum"
import { AccessPageContainer } from "domain/core/containers/AccessPageContainer"

import { CopiedField } from "view/components/CopiedField"

import { usePrepareTransactionDictionaries } from "./hooks/usePrepareTransactionDictionaries"
import {
  mergeDictionariesWithFilters,
  mapToTransactionFilterReq,
} from "./mappers"
import { filterItems } from "./config"
import * as S from "./styled"

const Page = () => {
  const [initParams, setInitParams] = useState(null)

  const dictionaries = usePrepareTransactionDictionaries()

  const [selectedRow, setSelectedRow] = useState([])

  useEffect(() => {
    if (!dictionaries) return
    if (initParams) return

    setInitParams({
      filters: {
        items: mergeDictionariesWithFilters(filterItems, dictionaries),
      },
      sort: [
        {
          columnName: "startedAt",
          direction: "desc",
        },
      ],
    })
  }, [dictionaries])

  const prefixLocale = "pages.transactions.grid"

  const columns = useMemo(
    () => [
      {
        field: "startedAt",
        headerName: useTranslate(`${prefixLocale}.startedAt`),
        width: 160,
        renderCell: renderDatetimeCell,
      },
      {
        field: "finalAt",
        headerName: useTranslate(`${prefixLocale}.finalAt`),
        width: 160,
        renderCell: renderDatetimeCell,
      },
      {
        field: "operation",
        headerName: useTranslate(`${prefixLocale}.operation`),
        sortable: false,
        width: 140,
        renderCell: ({ value }) => (
          <S.OperationWrapper status={value}>{value}</S.OperationWrapper>
        ),
      },
      {
        field: "orderId",
        headerName: useTranslate(`${prefixLocale}.orderId`),
        sortable: false,
        width: 140,
        renderCell: ({ value, row }) => (
          <CopiedField
            value={value}
            Component={
              <S.Action title={value} to={`/finance/transactions/${row.id}`}>
                {truncate(value) || "No order ID"}
              </S.Action>
            }
          />
        ),
      },
      {
        field: "status",
        headerName: useTranslate(`${prefixLocale}.status`),
        sortable: false,
        width: 160,
        renderCell: ({ value }) => (
          <S.ActionWrapper status={value}>
            {value.replace(/_/, " ")}
          </S.ActionWrapper>
        ),
      },
      {
        field: "externalId",
        headerName: useTranslate(`${prefixLocale}.externalId`),
        sortable: false,
        width: 140,
        renderCell: ({ value }) => {
          if (value !== "—") return <CopiedField value={value} truncate />
          return value
        },
      },
      {
        field: "paymentSystemOrderId",
        headerName: useTranslate(`${prefixLocale}.paymentSystemOrderId`),
        sortable: false,
        width: 180,
        renderCell: ({ value }) => {
          if (value !== "—") return <CopiedField value={value} truncate />
          return value
        },
      },
      {
        field: "customerId",
        headerName: useTranslate(`${prefixLocale}.customerId`),
        sortable: false,
        width: 140,
        renderCell: ({ value }) => <CopiedField value={value} truncate />,
      },
      {
        field: "customerEmail",
        headerName: useTranslate(`${prefixLocale}.customerEmail`),
        sortable: false,
        width: 200,
        renderCell: ({ value }) => <CopiedField value={value} />,
      },
      {
        field: "country",
        headerName: useTranslate(`${prefixLocale}.country`),
        sortable: false,
        width: 80,
      },
      {
        field: "paymentMethodIn",
        headerName: useTranslate(`${prefixLocale}.paymentMethodIn`),
        sortable: false,
        width: 140,
      },
      {
        field: "cardBrand",
        headerName: useTranslate(`${prefixLocale}.cardMethod`),
        sortable: false,
        width: 140,
      },
      {
        field: "cardMaskedPan",
        headerName: useTranslate(`${prefixLocale}.cardPan`),
        sortable: false,
        width: 140,
      },
      {
        field: "cardIssuingCountry",
        headerName: useTranslate(`${prefixLocale}.cardCountry`),
        sortable: false,
        width: 140,
      },
      {
        field: "fullName",
        headerName: useTranslate(`${prefixLocale}.fullName`),
        sortable: false,
        width: 140,
      },
      {
        field: "countryOfResidence",
        headerName: useTranslate(`${prefixLocale}.countryOfResidence`),
        sortable: false,
        width: 140,
      },
      {
        field: "paymentMethodOut",
        headerName: useTranslate(`${prefixLocale}.paymentMethodOut`),
        sortable: false,
        width: 100,
      },
      {
        field: "failReason",
        headerName: useTranslate(`${prefixLocale}.failReason`),
        sortable: false,
        width: 200,
        renderCell: ({ value }) => {
          if (value !== "—") return <CopiedField value={value} />
          return value
        },
      },
      {
        field: "clientPayAmount",
        headerName: useTranslate(`${prefixLocale}.clientPayAmount`),
        sortable: false,
        width: 140,
      },
      {
        field: "clientPayCurrency",
        headerName: useTranslate(`${prefixLocale}.clientPayCurrency`),
        sortable: false,
        width: 140,
      },
      {
        field: "clientGetAmount",
        headerName: useTranslate(`${prefixLocale}.clientGetAmount`),
        sortable: false,
        width: 140,
      },
      {
        field: "clientGetCurrency",
        headerName: useTranslate(`${prefixLocale}.clientGetCurrency`),
        sortable: false,
        width: 140,
      },
      {
        field: "clientPaySystemAmount",
        headerName: useTranslate(`${prefixLocale}.clientPaySystemAmount`),
        width: 140,
      },
      {
        field: "clientPaySystemCurrency",
        headerName: useTranslate(`${prefixLocale}.clientPaySystemCurrency`),
        sortable: false,
        width: 140,
      },
      {
        field: "merchantPnl",
        headerName: useTranslate(`${prefixLocale}.merchantPnl`),
        sortable: false,
        width: 100,
      },
      {
        field: "merchantPnlSystem",
        headerName: useTranslate(`${prefixLocale}.merchantPnlSystem`),
        sortable: false,
        width: 100,
      },
    ],
    []
  )

  const handlerRowClick = useCallback((ids) => {
    setSelectedRow(ids)
  }, [])

  if (!initParams) return null

  return (
    <TransactionProvider>
      <GridStateStoreContainer
        selectors={transactionSelectors}
        context={TransactionContext}
        filterToState={mapToTransactionFilterReq}
        storageGridKey="transactionsList"
      />

      <GridContainer
        url="/v1/transactions/list"
        columns={columns}
        dataToRow={dataToRow}
        filterToReq={mapToTransactionFilterReq}
        selectors={transactionSelectors}
        context={TransactionContext}
        initParams={initParams}
        onRowClick={handlerRowClick}
        selectedRows={selectedRow}
      />
    </TransactionProvider>
  )
}

export const TransactionListPage = () => {
  useTitle("Transactions")

  return (
    <AccessPageContainer permissions={[PermissionsEnum.FinancereportView]}>
      <Page />
    </AccessPageContainer>
  )
}
