import { useMemo } from "react"

import { useTranslate } from "infrastructure/hooks/useTranslate"

import { useTitle } from "domain/application/hooks/useTitle"
import {
  TapOnFeesProvider,
  TapOnFeesContext,
} from "domain/settings/tapOnFees/TapOnFeesContext"
import { tapOnFeesSelectors } from "domain/settings/tapOnFees/selectors"
import { GridContainer } from "domain/core/containers/GridContainer"
import { dataToRow } from "domain/settings/tapOnFees/mappers"
import { AccessPageContainer } from "domain/core/containers/AccessPageContainer"
import { PermissionsEnum } from "domain/rbac/PermissionsEnum"

const Page = () => {
  const searchPlaceholder = useTranslate("pages.tapOnFees.searchPlaceholder")
  const methodCode = useTranslate("pages.tapOnFees.grid.methodCode")
  const tapOnFee = useTranslate("pages.tapOnFees.grid.tapOnFee")

  const initParams = useMemo(
    () => ({
      sort: [
        {
          columnName: "methodCode",
          direction: "asc",
        },
      ],
    }),
    []
  )

  const columns = useMemo(() => {
    return [
      {
        field: "methodCode",
        headerName: methodCode,
        width: 160,
      },
      {
        field: "tapOnFee",
        headerName: tapOnFee,
        minWidth: 160,
        flex: 1,
        renderCell: ({ value }) => `${value}%`,
      },
    ]
  }, [])

  return (
    <TapOnFeesProvider>
      <GridContainer
        url="/v1/methods-tap-on-fees/list"
        columns={columns}
        context={TapOnFeesContext}
        selectors={tapOnFeesSelectors}
        initParams={initParams}
        dataToRow={dataToRow}
        searchBlockProps={{
          placeholder: searchPlaceholder,
          columns: ["methodCode"],
        }}
        isServerSearch
      />
    </TapOnFeesProvider>
  )
}

export const TapOnFeesPage = () => {
  const title = useTranslate("pages.tapOnFees.title")

  useTitle(title)

  return (
    <AccessPageContainer permissions={[PermissionsEnum.DetailsView]}>
      <Page />
    </AccessPageContainer>
  )
}
