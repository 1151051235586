import React, { useCallback, useEffect, useState } from "react"

import { WebhookLogsList } from "./WebhookLogsList"
import { WebhookLogsInfo } from "./WebhookLogsInfo"

import * as S from "./styled"

export const WebhookLogs = ({ list, isLoading, isLoadMore, cbLoadMore }) => {
  const [selectedId, setSelectedId] = useState("")
  const [logData, setLogData] = useState(null)

  const handleOnClick = useCallback(
    (id: string) => {
      setSelectedId(id)
      const data = list.data.find(({ logId }) => logId === id)
      setLogData(data)
    },
    [list, setSelectedId, selectedId]
  )

  useEffect(() => {
    if (isLoading && logData) setLogData(null)
  }, [isLoading, logData])

  useEffect(() => {
    if (list.data.length && !logData) handleOnClick(list.data[0].id)
  }, [list.data, logData])

  return (
    <>
      <S.Title>Logs</S.Title>
      <S.Wrapper>
        <WebhookLogsList
          list={list}
          selectedId={selectedId}
          onClick={handleOnClick}
          isLoading={isLoading}
          isLoadMore={isLoadMore}
          cbLoadMore={cbLoadMore}
        />
        {logData && <WebhookLogsInfo data={logData} />}
      </S.Wrapper>
    </>
  )
}
