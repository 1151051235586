import {
  FilterTypeEnum,
  TFilterPropsItem,
  TFilterValue,
} from "@unlimint/admin-ui-kit"

export const filterItems: TFilterPropsItem<TFilterValue>[] = [
  {
    title: "Created at",
    field: "createdAt",
    type: FilterTypeEnum.DateRange,
    options: {
      withoutTime: true,
    },
  },
  {
    title: "Changed by",
    field: "userEmail",
    type: FilterTypeEnum.String,
  },
  {
    title: "Source",
    field: "source",
    type: FilterTypeEnum.MultiChoice,
  },
  {
    title: "Section",
    field: "objectType",
    type: FilterTypeEnum.MultiChoice,
  },
  {
    title: "Entity ID",
    field: "objectId",
    type: FilterTypeEnum.String,
  },
]
