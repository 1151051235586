export const PermissionsEnum = {
  CommonView: "COMMON_VIEW",
  DetailsView: "DETAILS_VIEW",
  BalanceView: "BALANCE_VIEW",
  FinancereportView: "FINANCEREPORT_VIEW",
  FinancereportDetailsView: "FINANCEREPORT_DETAILS_VIEW",
  ApiEdit: "API_EDIT",
  RolesEdit: "ROLES_EDIT",
  ProfileEdit: "PROFILE_EDIT",
}
