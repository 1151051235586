import React from "react"

import { Field } from "formik"

import { Select } from "@unlimint/admin-ui-kit"

export const SelectField = ({
  name,
  label,
  placeholder,
  disabled,
  options,
  multiple = false,
}) => {
  return (
    <Field name={name}>
      {({ field, form: { errors } }) => (
        <Select
          options={options}
          name={name}
          disabled={disabled}
          onChange={field.onChange}
          placeholder={placeholder}
          header={label}
          error={!!errors[name]}
          helperText={errors[name]}
          defaultValue={field.value}
          multiple={multiple}
        />
      )}
    </Field>
  )
}
