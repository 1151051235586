import { styled } from "@mui/material"
import { palette } from "view/themes/palette"

type TStyles = {
  isError?: boolean
}

export const LabelWrapper = styled("div")(() => ({
  height: "36px",
  textAlign: "left",
  padding: "0 14px",
  paddingTop: 12,
  boxSizing: "content-box",
}))

export const LabelTitle = styled("div")(() => ({
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "20px",
}))

export const Badge = styled("div")<TStyles>(({ isError }) => ({
  position: "absolute",
  top: 0,
  right: 0,
  color: palette.white,
  background: palette.error500,
  borderRadius: "50%",
  width: 16,
  height: 16,
  textAlign: "center",
  padding: "1px 2px",
  fontWeight: 400,
  fontSize: "9px",
  lineHeight: "16px",
}))
