import { useTranslate } from "infrastructure/hooks/useTranslate"

import { useTitle } from "domain/application/hooks/useTitle"
import { ApiKeyProvider, ApiKeyContext } from "domain/apiKey/ApiKeyContext"
import { apiKeySelectors } from "domain/apiKey/selectors"
import { FetchEntityContainer } from "domain/core/containers/FetchEntityContainer"
import { FormApiKeyContainer } from "domain/apiKey/FormApiKeyContainer"
import { AccessPageContainer } from "domain/core/containers/AccessPageContainer"
import { PermissionsEnum } from "domain/rbac/PermissionsEnum"

export const ApiKeyPage = () => {
  const title = useTranslate("pages.apiKey.title")

  useTitle(title)

  return (
    <AccessPageContainer permissions={[PermissionsEnum.ApiEdit]}>
      <ApiKeyProvider>
        <FetchEntityContainer
          url="/v1/access"
          resourceContext={ApiKeyContext}
          resourceSelectors={apiKeySelectors}
        />

        <FormApiKeyContainer />
      </ApiKeyProvider>
    </AccessPageContainer>
  )
}
