import { createContext } from "use-context-selector"

import { createProvider } from "domain/createProvider"

const initState = {
  item: {
    data: null,
    isLoading: false,
    error: null,
  },
}

export const HomeDetailsContext = createContext(initState)

export const HomeDetailsProvider = createProvider(HomeDetailsContext, initState)
