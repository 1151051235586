import { FC, memo, useCallback, useEffect, useState } from "react"
import { useContextSelector } from "use-context-selector"
import { IFilterConfig } from "@unlimint/admin-ui-kit"

import { TDataGridContext, TUpdate } from "domain/types"
import { GridStateStorageService } from "domain/core/services/GridStateStorageService"
import { UserContext } from "domain/user/UserContext"
import { userSelectors } from "domain/user/selectors"

import { mapGridState, setSavedFiltersToConfig } from "./mappers"

type TGridStateStoreContainerProps = {
  context: any
  selectors: any
  storageGridKey: string
  filterToState: (filters: IFilterConfig) => void
}

export const GridStateStoreContainer: FC<TGridStateStoreContainerProps> = memo(
  ({ context, selectors, storageGridKey, filterToState }) => {
    const [stateService, setStateService] = useState(null)

    const gridData: TDataGridContext<any> = useContextSelector(
      context,
      selectors.gridData
    )
    const update: TUpdate<any> = useContextSelector(context, selectors.update)
    const userId: string = useContextSelector(UserContext, userSelectors.userId)

    const applySavedState = useCallback(async () => {
      const savedState = await stateService.getState()

      if (savedState) {
        const { filters } = gridData
        setSavedFiltersToConfig(savedState.filters, filters)

        update({
          gridData: {
            ...gridData,
            filters,
            sort: savedState.sort,
            pagination: savedState.pagination,
            isInited: true,
          },
        })
        return
      }

      update({
        gridData: {
          ...gridData,
          isInited: true,
        },
      })
    }, [update, gridData, stateService])

    useEffect(() => {
      const gridStateStorageService = new GridStateStorageService(
        userId,
        storageGridKey
      )
      setStateService(gridStateStorageService)
    }, [])

    useEffect(() => {
      if (!stateService) return
      if (!gridData.filters) return

      if (!gridData.isInited) {
        applySavedState()
      } else {
        const state = mapGridState(gridData, filterToState)
        stateService.update(state)
      }
    }, [gridData, stateService])

    return null
  }
)
