import { FC, useCallback } from "react"
import { useContextSelector } from "use-context-selector"

import { useTranslate } from "infrastructure/hooks/useTranslate"

import { deleteToGridDataState } from "domain/core/utils"
import { DeleteContainer } from "domain/core/containers/DeleteContainer"

import { WebhookContext } from "../WebhookContext"
import { webhookSelectors } from "../selectors"

type TDeleteWebhookContainerProps = {
  id: string
  name: string
  onClose: () => void
}

export const DeleteWebhookContainer: FC<TDeleteWebhookContainerProps> = ({
  id,
  name,
  onClose,
}) => {
  const title = useTranslate("pages.webhooks.modals.deleteTitle")
  const text = useTranslate("pages.webhooks.modals.deleteText", {
    name,
  })
  const actionButtonTitle = useTranslate("pages.webhooks.modals.deleteButton")

  const gridData = useContextSelector(WebhookContext, webhookSelectors.gridData)
  const update = useContextSelector(WebhookContext, webhookSelectors.update)

  const cbSuccess = useCallback(() => {
    update({
      gridData: deleteToGridDataState(gridData, { id }),
    })
  }, [gridData, id])

  return (
    <DeleteContainer
      url={`/v1/webhooks/${id}`}
      cbSuccess={cbSuccess}
      formProps={{
        title,
        text,
        actionButtonTitle,
        onClose,
      }}
    />
  )
}
