import { amountFormat } from "infrastructure/utils/amountFormat"

const mapToString = (data) => {
  let value = ""

  if (data && data.length) {
    data.forEach((item) => {
      value += `${amountFormat(item.amount)} ${item.currency} `
    })
  }

  return value
}

const mapObjectToArray = (data) => {
  if (data)
    return Object.keys(data).map((key) => ({
      amount: data[key],
      currency: key,
    }))

  return []
}

const statusToString = (status) => status.split("_").join(" ").toLowerCase()

export const dataToRow = (data) => ({
  id: data.id,
  operation: statusToString(data.operation),
  date: data.day,
  initCount: data.initCount,
  failedCount: data.failedCount,
  successCount: data.successCount,
  pnlExpected: mapToString(
    mapObjectToArray(data.initData?.merchantPnlSystem?.pnl.currencyMap)
  ),
  pnlSuccess: mapToString(
    mapObjectToArray(data.successData?.merchantPnlSystem?.pnl.currencyMap)
  ),
  pnlFail: mapToString(
    mapObjectToArray(data.failedData?.merchantPnlSystem?.pnl.currencyMap)
  ),
})

export const rowDataCurrenciesToString = (obj) => {
  if (!obj) return "-"
  return Object.keys(obj).reduce((prev, key) => {
    prev.push({ amount: amountFormat(obj[key]), currency: key })
    return prev
  }, [])
}

const mapRowData = (obj) => {
  const pnlGroup = []
  const commonGroup = []

  if (obj) {
    Object.keys(obj).forEach((key) => {
      if (key !== "revShare") {
        if (key.startsWith("pnl")) {
          const isPnl = key === "pnl"
          pnlGroup.push({
            label: isPnl ? "Total" : key.replace("pnl", ""),
            isBoldLabel: isPnl,
            values: rowDataCurrenciesToString(obj[key].currencyMap),
          })
          return
        }

        const isBoldLabel = !!key.match("Pnl")
        const label = isBoldLabel ? key.replace("Pnl", "") : key

        commonGroup.push({
          label: `${label.charAt(0).toUpperCase()}${label.slice(1)}`,
          isBoldLabel,
          values: rowDataCurrenciesToString(obj[key].currencyMap),
        })
      }
    })
  }

  const sortedCommonGroup = commonGroup.sort((a, b) => {
    const aUppercaseChars = a.label.replace("Initial").match(/[A-Z]/g).join("")
    const bUppercaseChars = b.label.replace("Initial").match(/[A-Z]/g).join("")

    if (aUppercaseChars < bUppercaseChars) {
      return -1
    }
    if (aUppercaseChars > bUppercaseChars) {
      return 1
    }
    return 0
  })

  return { pnlGroup, commonGroup: sortedCommonGroup }
}

export const dataToEntity = (data) => {
  const { cashFlow } = data

  return {
    id: cashFlow.id,
    date: cashFlow.day,
    marchantId: cashFlow.merchant.id,
    marchantName: cashFlow.merchant.name,
    operation: statusToString(cashFlow.operation),
    initCount: cashFlow.initCount,
    failedCount: cashFlow.failedCount,
    successCount: cashFlow.successCount,
    merchantPnl: {
      expected: mapRowData(cashFlow.initData?.merchantPnl),
      success: mapRowData(cashFlow.successData?.merchantPnl),
      failed: mapRowData(cashFlow.failedData?.merchantPnl),
    },
    merchantPnlSystem: {
      expected: mapRowData(cashFlow.initData?.merchantPnlSystem),
      success: mapRowData(cashFlow.successData?.merchantPnlSystem),
      failed: mapRowData(cashFlow.failedData?.merchantPnlSystem),
    },
  }
}
