import React from "react"
import { useContextSelector } from "use-context-selector"
import { Tabs } from "@unlimint/admin-ui-kit"

import { useTranslate } from "infrastructure/hooks/useTranslate"
import { CashFlowContext } from "domain/cashFlow/CashFlowContext"
import { cashFlowSelectors } from "domain/cashFlow/selectors"
import { PnlDataGrid } from "view/components/PnlDataGrid"

import * as S from "./styled"

export const CashFlowTabs = () => {
  const { data } = useContextSelector(CashFlowContext, cashFlowSelectors.item)

  const pnlTabLabel = useTranslate("pages.cashFlowDetails.pnlTabLabel")
  const pnlSystemTabLabel = useTranslate(
    "pages.cashFlowDetails.pnlSystemTabLabel"
  )
  const expectedDataLabel = useTranslate(
    "pages.cashFlowDetails.expectedDataLabel"
  )
  const successDataLabel = useTranslate(
    "pages.cashFlowDetails.successDataLabel"
  )
  const failedDataLabel = useTranslate("pages.cashFlowDetails.failedDataLabel")

  const tabs = [
    {
      value: "MerchantPnl",
      label: pnlTabLabel,
    },
    {
      value: "MerchantPnlSystem",
      label: pnlSystemTabLabel,
    },
  ]

  const tabsContents = [
    {
      value: "MerchantPnl",
      content: (
        <S.Grid>
          <S.Container>
            <S.DataTitle>{expectedDataLabel}</S.DataTitle>
            <PnlDataGrid data={data?.merchantPnl?.expected} />
          </S.Container>

          <S.Container>
            <S.DataTitle>{successDataLabel}</S.DataTitle>
            <PnlDataGrid data={data?.merchantPnl?.success} />
          </S.Container>

          <S.Container>
            <S.DataTitle>{failedDataLabel}</S.DataTitle>
            <PnlDataGrid data={data?.merchantPnl?.failed} />
          </S.Container>
        </S.Grid>
      ),
    },
    {
      value: "MerchantPnlSystem",
      content: (
        <S.Grid>
          <S.Container>
            <S.DataTitle>{expectedDataLabel}</S.DataTitle>
            <PnlDataGrid data={data?.merchantPnlSystem?.expected} />
          </S.Container>

          <S.Container>
            <S.DataTitle>{successDataLabel}</S.DataTitle>
            <PnlDataGrid data={data?.merchantPnlSystem?.success} />
          </S.Container>

          <S.Container>
            <S.DataTitle>{failedDataLabel}</S.DataTitle>
            <PnlDataGrid data={data?.merchantPnlSystem?.failed} />
          </S.Container>
        </S.Grid>
      ),
    },
  ]

  return (
    <Tabs initialTab="MerchantPnl" tabs={tabs} tabsContents={tabsContents} />
  )
}
