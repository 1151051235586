import { amountFormat } from "infrastructure/utils/amountFormat"

import { OperationEnum } from "domain/transaction/enums"

export const currenciesToString = (obj) => {
  if (!obj) return "—"

  const keys = Object.keys(obj)
  const pairs = keys.map((key) => `${key}: ${amountFormat(obj[key])}`)

  return pairs.join(", ")
}

export const dataToRow = (item) => {
  const isOnRamp = item.operation === OperationEnum.OnRamp

  return {
    id: item.orderId,
    orderId: item.orderId,
    externalId: item?.externalId || "—",
    paymentSystemOrderId: item.paymentSystemOrderId || "—",
    operation: item.operation,

    customerId: item?.customer?.id || "—",
    customerEmail: item?.customer?.email || "—",

    country: item?.country || "—",

    merchantId: item?.merchant?.id || "—",

    paymentMethodIn: item?.paymentMethodIn || "—",
    paymentMethodOut: item?.paymentMethodOut || "—",
    failReason: item?.failReason || "—",

    status: item?.status,

    clientPayAmount: isOnRamp
      ? amountFormat(item.clientPayAmount)
      : String(item.clientPayAmount),
    clientPayCurrency: item.clientPayCurrency || "—",
    clientGetAmount: isOnRamp
      ? String(item.clientGetAmount)
      : amountFormat(item.clientGetAmount),
    clientGetCurrency: item.clientGetCurrency || "—",
    clientPaySystemAmount: isOnRamp
      ? amountFormat(item.clientPaySystemAmount)
      : String(item.clientPaySystemAmount),
    clientPaySystemCurrency: item.clientPaySystemCurrency || "—",

    merchantPnl: currenciesToString(item?.data?.merchantPnl?.pnl.currencyMap),
    merchantPnlSystem: currenciesToString(
      item?.data?.merchantPnlSystem?.pnl.currencyMap
    ),

    startedAt: item.startedAt,
    finalAt: item.finalAt,

    countryOfResidence: item.countryOfResidence || "—",
    cardBrand: item.cardBrand || "—",
    cardMaskedPan: item.cardMaskedPan || "—",
    cardIssuingCountry: item.cardIssuingCountry || "—",
    fullName: item.customer?.name || "—",
  }
}

export const rowDataCurrenciesToString = (obj) => {
  if (!obj) return "-"
  return Object.keys(obj).reduce((prev, key) => {
    prev.push({ amount: amountFormat(obj[key]), currency: key })
    return prev
  }, [])
}

const mapRowData = (obj) => {
  const pnlGroup = []
  const commonGroup = []

  if (obj) {
    Object.keys(obj).forEach((key) => {
      if (key !== "revShare") {
        if (key.startsWith("pnl")) {
          const isPnl = key === "pnl"
          pnlGroup.push({
            label: isPnl ? "Total" : key.replace("pnl", ""),
            isBoldLabel: isPnl,
            values: rowDataCurrenciesToString(obj[key].currencyMap),
          })
          return
        }

        const isBoldLabel = !!key.match("Pnl")
        const label = isBoldLabel ? key.replace("Pnl", "") : key

        commonGroup.push({
          label: `${label.charAt(0).toUpperCase()}${label.slice(1)}`,
          isBoldLabel,
          values: rowDataCurrenciesToString(obj[key].currencyMap),
        })
      }
    })
  }

  const sortedCommonGroup = commonGroup.sort((a, b) => {
    const aUppercaseChars = a.label.replace("Initial").match(/[A-Z]/g).join("")
    const bUppercaseChars = b.label.replace("Initial").match(/[A-Z]/g).join("")

    if (aUppercaseChars < bUppercaseChars) {
      return -1
    }
    if (aUppercaseChars > bUppercaseChars) {
      return 1
    }
    return 0
  })

  return { pnlGroup, commonGroup: sortedCommonGroup }
}

export const dataToEntity = (item) => {
  const { transaction } = item
  const isOnRamp = transaction.operation === OperationEnum.OnRamp

  return {
    id: transaction?.orderId,
    orderId: transaction?.orderId,
    externalId: transaction?.externalId,
    paymentSystemOrderId: transaction?.paymentSystemOrderId,
    operation: transaction?.operation,

    customerId: transaction?.customer?.id,
    customerEmail: transaction?.customer?.email,

    country: transaction?.country,

    merchantId: transaction?.merchant?.id,

    paymentMethodIn: transaction?.paymentMethodIn,
    paymentMethodOut: transaction?.paymentMethodOut,

    status: transaction?.status,
    failReason: transaction?.failReason,

    clientPayAmount: isOnRamp
      ? amountFormat(transaction?.clientPayAmount)
      : String(transaction?.clientPayAmount),
    clientPayCurrency: transaction?.clientPayCurrency,
    clientGetAmount: isOnRamp
      ? String(transaction?.clientGetAmount)
      : amountFormat(transaction?.clientGetAmount),
    clientGetCurrency: transaction?.clientGetCurrency,
    clientPaySystemAmount: isOnRamp
      ? amountFormat(transaction?.clientPaySystemAmount)
      : String(transaction?.clientPaySystemAmount),
    clientPaySystemCurrency: transaction?.clientPaySystemCurrency,

    merchantPnl: currenciesToString(
      transaction?.data?.merchantPnl?.pnl.currencyMap
    ),
    merchantPnlSystem: currenciesToString(
      transaction?.data?.merchantPnlSystem?.pnl.currencyMap
    ),

    rawPnlData: {
      merchantPnl: mapRowData(transaction?.data?.merchantPnl),
      merchantPnlSystem: mapRowData(transaction?.data?.merchantPnlSystem),
    },

    rawData: transaction?.rawData,

    startedAt: transaction?.startedAt,
    finalAt: transaction?.finalAt,

    countryOfResidence: transaction.countryOfResidence,
    cardBrand: transaction.cardBrand,
    cardMaskedPan: transaction.cardMaskedPan,
    cardIssuingCountry: transaction.cardIssuingCountry,
    fullName: transaction?.customer?.name,
  }
}
