import { styled } from "@mui/material"
import { Link } from "react-router-dom"
import { palette } from "view/themes/palette"
import { OperationEnum } from "domain/transaction/enums"
import { StatusEnum } from "./enums"

export const Action = styled(Link)(() => ({
  color: palette.info500,
  textDecoration: "none",
  cursor: "pointer",

  "&:hover": {
    textDecorationLine: "underline",
  },
}))

export const ActionWrapper = styled("div")<{ status: string }>(
  ({ status }) => ({
    backgroundColor: (() => {
      switch (status) {
        case StatusEnum.Init:
          return palette.info200
        case StatusEnum.Success:
          return palette.success100
        case StatusEnum.Failed:
          return palette.error100
        default:
          return palette.info200
      }
    })(),
    fontSize: 12,
    padding: "2px 14px",
    borderRadius: 8,
    textTransform: "capitalize",
    textAlign: "center",
  })
)

export const OperationWrapper = styled("div")<{ status: OperationEnum }>(
  ({ status }) => ({
    backgroundColor: (() => {
      switch (status) {
        case OperationEnum.OffRamp:
          return palette.info200
        case OperationEnum.OnRamp:
          return palette.success100
        default:
          return palette.info200
      }
    })(),
    fontSize: 12,
    padding: "2px 14px",
    borderRadius: 8,
    textTransform: "capitalize",
    textAlign: "center",
  })
)
