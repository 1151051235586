import { FC } from "react"

import * as S from "../styled"

type TGridRowProps = {
  label: string
  contentBefore?: string
  contentAfter?: string
}

export const GridRow: FC<TGridRowProps> = ({
  label,
  contentBefore,
  contentAfter,
}) => (
  <S.Rows>
    <S.Cell title={label}>
      <S.Key>{label}</S.Key>
    </S.Cell>
    {contentBefore && <S.Cell>{contentBefore}</S.Cell>}
    {contentAfter && <S.Cell>{contentAfter}</S.Cell>}
  </S.Rows>
)
