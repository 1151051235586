import { useContextSelector } from "use-context-selector"

import { SettingViewProp } from "@unlimint/admin-ui-kit"

import { useTranslate } from "infrastructure/hooks/useTranslate"

import { DetailsContext } from "domain/settings/details/DetailsContext"
import { detailsSelectors } from "domain/settings/details/selectors"
import UnlimitCryptoLogo from "assets/images/unlimitCryptoLogo.svg"

import * as S from "./styled"

export const DetailsGrid = () => {
  const logo = useTranslate("pages.details.logo")
  const name = useTranslate("pages.details.name")
  const id = useTranslate("pages.details.id")
  const host = useTranslate("pages.details.host")
  const descriptor = useTranslate("pages.details.descriptor")
  const tapOnFeeNewMethods = useTranslate("pages.details.tapOnFeeNewMethods")
  const buyingStatus = useTranslate("pages.details.buyingStatus")
  const sellingStatus = useTranslate("pages.details.sellingStatus")
  const status = useTranslate("pages.details.status")
  const walletAddressLockingEnabled = useTranslate(
    "pages.details.walletAddressLockingEnabled"
  )
  const networkPromoNewAssets = useTranslate(
    "pages.details.networkPromoNewAssets"
  )
  const processingPromoNewMethods = useTranslate(
    "pages.details.processingPromoNewMethods"
  )

  const item = useContextSelector(DetailsContext, detailsSelectors.item)
  const data = item.data || {}

  return (
    <S.Wrapper>
      <S.Container>
        <S.Section>
          <div>
            <S.Label>{logo}</S.Label>
            <S.Logo src={data.logo || UnlimitCryptoLogo} alt="Unlimit Crypto" />
          </div>
          <div>
            <S.Label>{name}</S.Label>
            <S.Value>{data.shortName || "—"}</S.Value>
          </div>
          <div>
            <S.Label>{descriptor}</S.Label>
            <S.Value>{data.paymentDescriptor || "—"}</S.Value>
          </div>
        </S.Section>

        <S.Section>
          <div>
            <S.Label>{id}</S.Label>
            <S.Value>{data.merchantId || "—"}</S.Value>
          </div>
          <div>
            <S.Label>{host}</S.Label>
            <S.Value>{data.host || "—"}</S.Value>
          </div>
          <div>
            <S.Label>{walletAddressLockingEnabled}</S.Label>
            <S.Value>
              <SettingViewProp
                currentValue={data.isWalletAddressLockingEnabled}
                withoutResultValue
              />
            </S.Value>
          </div>
        </S.Section>

        <S.Section>
          <div>
            <S.Label>{networkPromoNewAssets}</S.Label>
            <S.Value>
              <SettingViewProp
                currentValue={data.isNetworkPromoForNewAssetsAdded}
                withoutResultValue
              />
            </S.Value>
          </div>
          <div>
            <S.Label>{processingPromoNewMethods}</S.Label>
            <S.Value>
              <SettingViewProp
                currentValue={data.isProcessingPromoForNewMethodsAdded}
                withoutResultValue
              />
            </S.Value>
          </div>
          <div>
            <S.Label>{tapOnFeeNewMethods}</S.Label>
            <S.Value>
              {data.newMethodsTapOnFee ? `${data.newMethodsTapOnFee}%` : "0%"}
            </S.Value>
          </div>
        </S.Section>

        <S.Section>
          <div>
            <S.Label>{status}</S.Label>
            <S.Value>
              <SettingViewProp
                currentValue={data.isEnabled}
                withoutResultValue
              />
            </S.Value>
          </div>
          <div>
            <S.Label>{buyingStatus}</S.Label>
            <S.Value>
              <SettingViewProp
                currentValue={data.canBuyCrypto}
                resultValue={data?.result?.canBuyCrypto}
              />
            </S.Value>
          </div>
          <div>
            <S.Label>{sellingStatus}</S.Label>
            <S.Value>
              <SettingViewProp
                currentValue={data.canSellCrypto}
                resultValue={data?.result?.canSellCrypto}
              />
            </S.Value>
          </div>
        </S.Section>
      </S.Container>
    </S.Wrapper>
  )
}
