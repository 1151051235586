import { useContextSelector } from "use-context-selector"

import { deleteToGridDataState } from "domain/core/utils"
import { TeamContext } from "domain/team/TeamContext"
import { teamSelectors } from "domain/team/selectors"
import { useTranslate } from "infrastructure/hooks/useTranslate"
import { DeleteContainer } from "domain/core/containers/DeleteContainer"

export const DeleteUserContainer = ({ deleteItem, onClose }) => {
  const title = useTranslate("pages.team.modals.deleteTitle")
  const text = useTranslate("pages.team.modals.deleteText", {
    email: deleteItem.email,
  })
  const actionButtonTitle = useTranslate("pages.team.modals.deleteButton")

  const gridData = useContextSelector(TeamContext, teamSelectors.gridData)
  const update = useContextSelector(TeamContext, teamSelectors.update)

  const cbSuccess = () => {
    update({
      gridData: deleteToGridDataState(gridData, deleteItem),
    })
  }

  return (
    <DeleteContainer
      url={`/v1/sso/users/${deleteItem.id}`}
      cbSuccess={cbSuccess}
      formProps={{
        title,
        text,
        actionButtonTitle,
        onClose,
      }}
    />
  )
}
