import { useState } from "react"
import { Formik } from "formik"

import { apiService } from "infrastructure/services/ApiService"
import { errorService } from "infrastructure/services/ErrorService"
import { errorToText } from "infrastructure/utils/errorToText"
import { FormModeEnum } from "domain/enums/FormModeEnum"
import { FormError } from "view/components/FormError"
import { mapToErrorDetails } from "infrastructure/utils/mapToErrorDetails"

export const FormContainer = ({
  mode,
  formProps,
  initialValues,
  validate,
  url,
  mapToReqModel,
  Form,
  cbSuccess,
  cbFailure,
}) => {
  const [commonError, setCommonError] = useState("")

  const handlerSubmit = (values, { setSubmitting, setErrors }) => {
    const method = mode === FormModeEnum.Add ? apiService.post : apiService.put

    method(url, mapToReqModel ? mapToReqModel(values) : values)
      .then((response) => {
        formProps.onClose?.()

        cbSuccess(response?.data)
        setSubmitting(false)
      })
      .catch((err) => {
        if (err?.response?.data) {
          const { message } = err.response.data
          const details = err.response.data?.details

          const errorMessage = errorToText(message)
          setCommonError(errorMessage)

          if (details) {
            const mapDetails = mapToErrorDetails(details)
            setErrors(mapDetails)
          }
        } else setCommonError(err.message)

        cbFailure?.(err)
        errorService.send(err)
        setSubmitting(false)
      })
  }

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validate={validate}
        onSubmit={handlerSubmit}
      >
        {(formikProps) => (
          <Form
            mode={mode}
            title={formProps.title}
            actionButtonTitle={formProps.actionButtonTitle}
            {...formProps}
            {...formikProps}
          />
        )}
      </Formik>

      <FormError error={commonError} />
    </>
  )
}
