import React from "react"
import { Form } from "formik"
import { Button } from "@unlimint/admin-ui-kit"
import { useContextSelector } from "use-context-selector"

import { TeamContext } from "domain/team/TeamContext"
import { teamSelectors } from "domain/team/selectors"
import { InputField } from "view/components/formik/InputField"
import { SelectField } from "view/components/formik/SelectField"
import { useTranslate } from "infrastructure/hooks/useTranslate"

import * as S from "./styled"

export const EditUserForm = ({
  title,
  actionButtonTitle,
  isSubmitting,
  onClose,
}) => {
  const { data } = useContextSelector(TeamContext, teamSelectors.item)

  const close = useTranslate("pages.team.modals.close")
  const firstName = useTranslate("pages.team.modals.firstName")
  const lastName = useTranslate("pages.team.modals.lastName")
  const selectRolesPlaceholder = useTranslate(
    "pages.team.modals.selectRolesPlaceholder"
  )
  const roles = useTranslate("pages.team.grid.roles")
  const email = useTranslate("pages.team.grid.email")

  return (
    <Form>
      <S.Wrapper>
        <S.Title>{title}</S.Title>

        <S.FormRow>
          <InputField label={firstName} name="name" />
        </S.FormRow>
        <S.FormRow>
          <InputField label={lastName} name="surname" />
        </S.FormRow>
        <S.FormRow>
          <SelectField
            label={roles}
            name="groups"
            options={data || []}
            placeholder={selectRolesPlaceholder}
            multiple
          />
        </S.FormRow>

        <S.FormRow>
          <InputField label={email} name="email" disabled />
        </S.FormRow>

        <S.Buttons>
          <Button size="small" variant="outlined" onClick={onClose}>
            {close}
          </Button>
          <Button size="small" type="submit" disabled={isSubmitting}>
            {actionButtonTitle}
          </Button>
        </S.Buttons>
      </S.Wrapper>
    </Form>
  )
}
