import { styled } from "@mui/material"

export const Wrapper = styled("div")(() => ({
  width: 392,
}))

export const Title = styled("div")(() => ({
  fontFamily: "Inter",
  fontWeight: 600,
  fontSize: 16,
  lineHeight: "20px",
  marginBottom: 32,
}))

export const Text = styled("div")(() => ({
  fontFamily: "Inter",
  fontWeight: 500,
  fontSize: 13,
  lineHeight: "16px",
}))

export const Buttons = styled("div")(() => ({
  marginTop: 40,
  display: "flex",
  gap: 12,
}))
