import { FC } from "react"
import {
  TrashIcon28,
  EditIcon28,
  Dialog,
  CopyIcon16,
  Chip,
} from "@unlimint/admin-ui-kit"
import { CopyToClipboard } from "react-copy-to-clipboard"

import { useTranslate } from "infrastructure/hooks/useTranslate"

import { useOpenable } from "domain/hooks/useOpenable"
import { DeleteWebhookContainer } from "domain/webhook/DeleteWebhookContainer"
import { EditWebhookContainer } from "domain/webhook/EditWebhookContainer"

import * as S from "./styled"

type TWebhookHeaderProps = {
  id: string
  name: string
  apiVersion: string
  isActive: boolean
  secretKey: string
  url: string
}

export const WebhookHeader: FC<TWebhookHeaderProps> = (props) => {
  const { id, name, isActive, secretKey, url, apiVersion } = props

  const keyLabel = useTranslate("pages.webhooks.header.key")
  const versionLabel = useTranslate("pages.webhooks.header.version")
  const activeStatus = useTranslate("pages.webhooks.status.active")
  const inactiveStatus = useTranslate("pages.webhooks.status.inactive")

  const deleteModal = useOpenable()
  const editModal = useOpenable()

  return (
    <>
      <S.Wrapper>
        <S.TopContent>
          <S.LeftSideBlock>
            <S.Title>{name}</S.Title>
            <Chip
              label={isActive ? activeStatus : inactiveStatus}
              color={isActive ? "success" : "error"}
            />
          </S.LeftSideBlock>
          <S.RightSideBlock>
            <TrashIcon28 onClick={deleteModal.onOpen} />
            <EditIcon28 onClick={editModal.onOpen} />
          </S.RightSideBlock>
        </S.TopContent>
        <S.Key>
          <span>ID</span>
          <span>{id}</span>
        </S.Key>
        <S.Key>
          <span>{keyLabel}</span>
          <span>{secretKey}</span>
          <CopyToClipboard text={secretKey}>
            <CopyIcon16 />
          </CopyToClipboard>
        </S.Key>
        <S.Key>
          <span>{versionLabel}</span>
          <span>{apiVersion}</span>
        </S.Key>
        <S.Link href={url} target="_blank">
          {url}
        </S.Link>
      </S.Wrapper>

      <Dialog open={deleteModal.open} onClose={deleteModal.onClose}>
        <DeleteWebhookContainer
          id={id}
          name={name}
          onClose={deleteModal.onClose}
        />
      </Dialog>

      <Dialog open={editModal.open} onClose={editModal.onClose}>
        <EditWebhookContainer formData={props} modal={editModal} />
      </Dialog>
    </>
  )
}
