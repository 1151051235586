import { useMemo, useCallback } from "react"
import { useNavigate } from "react-router-dom"

import { useTitle } from "domain/application/hooks/useTitle"
import { useTranslate } from "infrastructure/hooks/useTranslate"
import {
  CashFlowContext,
  CashFlowProvider,
} from "domain/cashFlow/CashFlowContext"
import { cashFlowSelectors } from "domain/cashFlow/selectors"
import { dataToRow } from "domain/cashFlow/mappers"
import { GridContainer } from "domain/core/containers/GridContainer"
import { formatShortDate } from "domain/core/utils"
import { AccessPageContainer } from "domain/core/containers/AccessPageContainer"
import { PermissionsEnum } from "domain/rbac/PermissionsEnum"

const Page = () => {
  const searchPlaceholder = useTranslate("pages.cashFlow.searchPlaceholder")
  const orderType = useTranslate("pages.cashFlow.grid.orderType")
  const date = useTranslate("pages.cashFlow.grid.date")
  const orderStarted = useTranslate("pages.cashFlow.grid.orderStarted")
  const orderFail = useTranslate("pages.cashFlow.grid.orderFail")
  const orderSuccess = useTranslate("pages.cashFlow.grid.orderSuccess")
  const pnlExpected = useTranslate("pages.cashFlow.grid.pnlExpected")
  const pnlSuccess = useTranslate("pages.cashFlow.grid.pnlSuccess")
  const pnlFail = useTranslate("pages.cashFlow.grid.pnlFail")

  const navigate = useNavigate()

  const initParams = useMemo(
    () => ({
      sort: [
        {
          columnName: "date",
          direction: "desc",
        },
      ],
    }),
    []
  )

  const columns = useMemo(
    () => [
      {
        field: "operation",
        headerName: orderType,
        width: 140,
      },
      {
        field: "date",
        headerName: date,
        width: 140,
        renderCell: ({ value }) => {
          return formatShortDate(value)
        },
      },
      {
        field: "initCount",
        headerName: orderStarted,
        width: 160,
      },
      {
        field: "failedCount",
        headerName: orderFail,
        width: 160,
      },
      {
        field: "successCount",
        headerName: orderSuccess,
        width: 160,
      },
      {
        field: "pnlExpected",
        headerName: pnlExpected,
        width: 200,
        align: "right",
      },
      {
        field: "pnlSuccess",
        headerName: pnlSuccess,
        width: 200,
        align: "right",
      },
      {
        field: "pnlFail",
        headerName: pnlFail,
        width: 200,
        align: "right",
      },
    ],
    []
  )

  const handlerRowClick = useCallback((ids) => {
    navigate(`/finance/cash-flow/${ids[0]}`)
  }, [])

  return (
    <CashFlowProvider>
      <GridContainer
        context={CashFlowContext}
        selectors={cashFlowSelectors}
        url="/v1/cash-flows/list"
        columns={columns}
        dataToRow={dataToRow}
        initParams={initParams}
        searchBlockProps={{
          placeholder: searchPlaceholder,
          columns: ["operation"],
        }}
        onRowClick={handlerRowClick}
      />
    </CashFlowProvider>
  )
}

export const CashFlowListPage = () => {
  const title = useTranslate("pages.cashFlow.title")

  useTitle(title)

  return (
    <AccessPageContainer permissions={[PermissionsEnum.FinancereportView]}>
      <Page />
    </AccessPageContainer>
  )
}
