import { styled } from "@mui/material"
import { palette } from "view/themes/palette"

export const Wrapper = styled("div")(() => ({
  maxWidth: "1816px",
}))

export const Container = styled("div")(() => ({
  display: "grid",
  gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
  gridGap: "24px",
}))

export const Section = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "24px",
}))

export const Label = styled("div")(() => ({
  fontSize: 13,
  lineHeight: "20px",
  fontWeight: 400,
  color: palette.grey500,
}))

export const Value = styled("div")(() => ({
  lineHeight: "24px",
}))

export const Logo = styled("img")(() => ({
  marginTop: 4,
  width: 24,
  height: 24,
  borderRadius: "50%",
}))
