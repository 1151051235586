import { createContext } from "use-context-selector"

import { createProvider } from "domain/createProvider"
import { getDataGridInitState } from "domain/utils"

const initState = getDataGridInitState()

export const NetworkFeesContext = createContext(initState)

export const NetworkFeesProvider = createProvider(NetworkFeesContext, initState)
