import { styled } from "@mui/material"
import { palette } from "view/themes/palette"

export const Wrapper = styled("div")(() => ({
  marginLeft: "24px",
  width: "100%",
}))

export const Title = styled("div")(() => ({
  lineHeight: "20px",
  fontWeight: 600,
  marginBottom: "32px",
}))

export const InfoBlock = styled("div")(() => ({
  width: "100%",
  marginBottom: "32px",
}))

export const ResponseBodyBlock = styled("div")(() => ({
  marginTop: 32,
  paddingTop: 32,
  borderTop: `1px solid ${palette.grey200}`,
}))

export const LatestLabel = styled("div")(() => ({
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "11px",
  lineHeight: "20px",

  width: "57px",
  height: "20px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "4px",
  background: palette.info200,
  marginLeft: "8px",
}))

export const BlockTitle = styled("div")(() => ({
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "20px",
}))

export const ResponseBodyWrapper = styled("div")(() => ({
  marginTop: "24px",
  wordBreak: "break-all",
  height: "calc(100vh - 590px)",
  overflow: "auto",
}))

export const Grid = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  marginBottom: "32px",
}))

export const GridRow = styled("div")(() => ({
  height: "20px",
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "13px",
  lineHeight: "20px",
  display: "flex",

  "& + &": {
    marginTop: "12px",
  },
}))

export const GridLabel = styled("div")(() => ({
  color: palette.grey500,
  width: "110px",
  textAlign: "left",
}))

export const GridValue = styled("div")(() => ({
  color: palette.black,
  display: "flex",
}))

export const StatusChip = styled("div")<{ status: string }>(({ status }) => ({
  padding: "2px 12px",
  borderRadius: 4,
  fontSize: 12,
  fontWeight: 500,
  lineHeight: "16px",
  background: status === "succeeded" ? palette.success100 : palette.error100,
}))

export const ResBodyString = styled("div")(() => ({
  fontSize: 13,
}))
