import React, { FC } from "react"

import { useTranslate } from "infrastructure/hooks/useTranslate"

import { WebhooksList } from "view/pages/devSpace/WebhooksPage/components/WebhookList"
import { WebhookAbout } from "view/pages/devSpace/WebhooksPage/components/WebhookAbout"

import * as S from "./styled"

type TTabContentProps = {
  id: string
  isEmpty: boolean
}

export const TabContent: FC<TTabContentProps> = ({ id, isEmpty }) => {
  const title = useTranslate("pages.webhooks.grid.title")

  return (
    <S.BottomContent>
      <S.BottomLeftSide isEmpty={isEmpty}>
        <S.Title>{title}</S.Title>
        <WebhooksList id={id} />
      </S.BottomLeftSide>
      <WebhookAbout />
    </S.BottomContent>
  )
}
