import { createContext } from "use-context-selector"

import { createProvider } from "domain/createProvider"
import { getDataGridInitState } from "domain/utils"

const initState = getDataGridInitState()

export const TapOnFeesContext = createContext(initState)

export const TapOnFeesProvider = createProvider(TapOnFeesContext, initState)
