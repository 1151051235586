export const dataToRow = (item) => ({
  merchantId: item.merchantId,
  shortName: item.shortName,
  host: item.host,
  logo: item.logo,
  isEnabled: item.isEnabled,
  canBuyCrypto: item.canBuyCrypto,
  canSellCrypto: item.canSellCrypto,
  result: {
    canBuyCrypto: item.result.canBuyCrypto,
    canSellCrypto: item.result.canSellCrypto,
  },
})

export const dataToEntity = (item) => dataToRow(item.partner)
