import { createContext } from "use-context-selector"

import { createProvider } from "domain/createProvider"
import { getDataGridInitState } from "domain/utils"

const initState = {
  ...getDataGridInitState(),
  events: [],
  stat: {},
}

export const WebhookContext = createContext(initState)

export const WebhookProvider = createProvider(WebhookContext, initState)
