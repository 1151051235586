import { styled } from "@mui/material"

import { palette } from "view/themes/palette"

export const Title = styled("div")(() => ({
  marginBottom: 16,
  fontWeight: 600,
}))

export const BottomContent = styled("div")(() => ({
  width: "100%",
  marginTop: "24px",
  display: "flex",
  minHeight: "560px",
}))

export const BottomLeftSide = styled("div")<{ isEmpty: boolean }>(
  ({ isEmpty }) => ({
    minWidth: isEmpty ? "100%" : "394px",
    borderRight: isEmpty ? "0px" : `1px solid ${palette.grey200}`,
  })
)
