// see ./types.ts
import { TGridConfig } from "@unlimint/admin-ui-kit"

import { TDataGridContext } from "domain/types"
import {
  TDataGridPagination,
  TDataGridSort,
  TFetchListParamReq,
  TGridContainerParams,
  TReqPagination,
  TReqSort,
  TResPagination,
} from "domain/core/types"

export const paginationToReq = (pagination) => {
  return {
    offset: pagination.offset,
    limit: pagination.limit,
  }
}

export const paginationFromRes = (
  pagination: TResPagination
): TDataGridPagination => {
  return {
    total: pagination.total,
    currentPage: pagination.offset
      ? Math.round(pagination.offset / pagination.limit) + 1
      : 1,
    limit: pagination.limit,
  }
}

export const mapDataGridPaginationToReq = (
  pagination: TDataGridPagination
): TReqPagination => {
  return {
    limit: pagination.limit,
    offset: (pagination.currentPage - 1) * pagination.limit,
  }
}

export const mapDataGridSortToReq = (sort: TDataGridSort[]): TReqSort[] => {
  return sort.map((item) => ({
    field: item.columnName,
    direction: item.direction,
  }))
}

export const mapGridContainerParamsToReq = (
  params: TGridContainerParams,
  filterToReq: any
): TFetchListParamReq => {
  const { filters, pagination, sort } = params

  return {
    filters: filterToReq ? filterToReq(filters) : undefined,
    pagination: mapDataGridPaginationToReq(pagination),
    sort: sort ? mapDataGridSortToReq(sort) : undefined,
  }
}

export const mapDataGridContextToGridConfig = <T>(
  dataGrid: TDataGridContext<T>
): TGridConfig => {
  const gridConfig: TGridConfig = {
    pagination: dataGrid.pagination,
  }

  if (dataGrid.filters) gridConfig.filter = dataGrid.filters
  if (dataGrid.sort) gridConfig.sort = dataGrid.sort

  return gridConfig
}
