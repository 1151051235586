import { useEffect, useState, useMemo } from "react"

import { Dialog, EmptyScreen } from "@unlimint/admin-ui-kit"

import { useTranslate } from "infrastructure/hooks/useTranslate"
import { truncate } from "infrastructure/utils/truncate"

import { useTitle } from "domain/application/hooks/useTitle"
import { GridContainer } from "domain/core/containers/GridContainer"
import { renderDatetimeCell } from "domain/core/utils"
import {
  ActivityLogContext,
  ActivityLogProvider,
} from "domain/activityLog/ActivityLogContext"
import { activityLogSelectors } from "domain/activityLog/selectors"
import { dataToRow } from "domain/activityLog/mappers"
import { GridStateStoreContainer } from "domain/core/containers/GridStateStoreContainer"
import { AccessPageContainer } from "domain/core/containers/AccessPageContainer"
import { PermissionsEnum } from "domain/rbac/PermissionsEnum"

import { ActivityLogDetails } from "view/pages/activityLog/ActivityLogListPage/components/ActivityLogDetails"
import { filterItems } from "view/pages/activityLog/ActivityLogListPage/config"

import {
  mapToActivityLogFilterReq,
  mergeDictionariesWithFilters,
} from "./mappers"
import { usePrepareChangelogDictionaries } from "./hooks/usePrepareChangelogDictionaries"

import * as S from "./styled"

const Page = () => {
  const title = useTranslate("pages.activityLogs.title")
  const action = useTranslate("pages.activityLogs.grid.action")
  const date = useTranslate("pages.activityLogs.grid.date")
  const changedBy = useTranslate("pages.activityLogs.grid.changedBy")
  const source = useTranslate("pages.activityLogs.grid.source")
  const section = useTranslate("pages.activityLogs.grid.section")
  const entityId = useTranslate("pages.activityLogs.grid.entityId")
  const noId = useTranslate("pages.activityLogs.grid.noId")
  const userId = useTranslate("pages.activityLogs.grid.userId")
  const showDetails = useTranslate("gridContainer.actions.showDetails")

  useTitle(title)

  const [isDetailsDialog, setIsDetailsDialog] = useState(false)
  const [rowData, setRowData] = useState(null)
  const [initParams, setInitParams] = useState(null)

  const dictionaries = usePrepareChangelogDictionaries()

  useEffect(() => {
    if (!dictionaries.data) return
    if (initParams) return

    setInitParams({
      filters: {
        items: mergeDictionariesWithFilters(filterItems, dictionaries.data),
      },
      sort: [
        {
          columnName: "createdAt",
          direction: "desc",
        },
      ],
    })
  }, [dictionaries])

  const showDetailsHandler = (row) => {
    setRowData(row)
    setIsDetailsDialog(true)
  }

  const closeDetailsHandler = () => {
    setIsDetailsDialog(false)
  }

  const columns = useMemo(
    () => [
      {
        field: "id",
        headerName: "ID",
        width: 140,
        sortable: false,
        renderCell: ({ value }) => <span title={value}>{truncate(value)}</span>,
      },
      {
        field: "action",
        headerName: action,
        width: 110,
        sortable: false,
        renderCell: ({ value }) => (
          <S.ActionWrapper action={value}>{value}</S.ActionWrapper>
        ),
      },
      {
        field: "createdAt",
        headerName: date,
        width: 170,
        renderCell: renderDatetimeCell,
      },
      {
        field: "userEmail",
        headerName: changedBy,
        width: 260,
      },
      {
        field: "source",
        headerName: source,
        width: 110,
        sortable: false,
        renderCell: ({ value }) => (
          <S.Capitalize title={value}>{value}</S.Capitalize>
        ),
      },
      {
        field: "objectType",
        headerName: section,
        width: 260,
      },
      {
        field: "objectId",
        headerName: entityId,
        width: 160,
        sortable: false,
        renderCell: ({ value, row }) => (
          <S.Action title={value} onClick={() => showDetailsHandler(row)}>
            {truncate(value) || noId}
          </S.Action>
        ),
      },
      {
        field: "userId",
        headerName: userId,
        minWidth: 160,
        flex: 1,
        sortable: false,
        renderCell: ({ value }) => <span title={value}>{truncate(value)}</span>,
      },
    ],
    []
  )

  if (dictionaries.error)
    return (
      <S.EmptyScreenWrapp>
        <EmptyScreen />
      </S.EmptyScreenWrapp>
    )

  if (!initParams) return null

  return (
    <ActivityLogProvider>
      <GridStateStoreContainer
        context={ActivityLogContext}
        selectors={activityLogSelectors}
        storageGridKey="eventsList"
        filterToState={mapToActivityLogFilterReq}
      />

      <GridContainer
        url="/v1/events/list"
        columns={columns}
        dataToRow={dataToRow}
        filterToReq={mapToActivityLogFilterReq}
        selectors={activityLogSelectors}
        context={ActivityLogContext}
        initParams={initParams}
        submenu={[{ title: showDetails, onClick: showDetailsHandler }]}
      />

      <Dialog
        maxWidth="840px"
        open={isDetailsDialog}
        onClose={closeDetailsHandler}
      >
        <ActivityLogDetails data={rowData} onClose={closeDetailsHandler} />
      </Dialog>
    </ActivityLogProvider>
  )
}

export const ActivityLogListPage = () => {
  const title = useTranslate("pages.activityLogs.title")

  useTitle(title)

  return (
    <AccessPageContainer permissions={[PermissionsEnum.CommonView]}>
      <Page />
    </AccessPageContainer>
  )
}
