import { useTranslate } from "infrastructure/hooks/useTranslate"

import { useTitle } from "domain/application/hooks/useTitle"
import { FetchEntityContainer } from "domain/core/containers/FetchEntityContainer"
import {
  DetailsProvider,
  DetailsContext,
} from "domain/settings/details/DetailsContext"
import { detailsSelectors } from "domain/settings/details/selectors"
import { AccessPageContainer } from "domain/core/containers/AccessPageContainer"
import { PermissionsEnum } from "domain/rbac/PermissionsEnum"

import { DetailsGrid } from "./components/DetailsGrid"

export const DetailsPage = () => {
  const title = useTranslate("pages.details.title")

  useTitle(title)

  return (
    <AccessPageContainer permissions={[PermissionsEnum.CommonView]}>
      <DetailsProvider>
        <FetchEntityContainer
          url="/v1/me"
          resourceContext={DetailsContext}
          resourceSelectors={detailsSelectors}
          options={{
            mapper: (data) => data.partner,
          }}
        />

        <DetailsGrid />
      </DetailsProvider>
    </AccessPageContainer>
  )
}
