import React from "react"
import { Form } from "formik"
import { Button } from "@unlimint/admin-ui-kit"

import { useTranslate } from "infrastructure/hooks/useTranslate"

import { InputField } from "view/components/formik/InputField"
import * as S from "./styled"

export const MarkupRuleForm = ({
  title,
  actionButtonTitle,
  isSubmitting,
  onClose,
}) => {
  return (
    <Form>
      <S.Title>{title}</S.Title>

      <S.FormRow>
        <InputField
          name="cryptoAssetCode"
          label={useTranslate("pages.markupRules.modals.asset")}
          disabled
        />
      </S.FormRow>

      <S.FormRow>
        <InputField
          type="number"
          name="buyMarkupRate"
          label={useTranslate("pages.markupRules.modals.asset")}
        />
      </S.FormRow>

      <S.Buttons>
        <Button size="small" variant="outlined" onClick={onClose}>
          {useTranslate("pages.markupRules.modals.close")}
        </Button>
        <Button size="small" type="submit" disabled={isSubmitting}>
          {actionButtonTitle}
        </Button>
      </S.Buttons>
    </Form>
  )
}
