import {
  FilterTypeEnum,
  TFilterPropsItem,
  TFilterValue,
} from "@unlimint/admin-ui-kit"

export const filterItems: TFilterPropsItem<TFilterValue>[] = [
  {
    title: "Started time",
    field: "startedAt",
    type: FilterTypeEnum.DateRange,
    options: {
      withoutTime: true,
    },
  },
  {
    title: "Final time",
    field: "finalAt",
    type: FilterTypeEnum.DateRange,
    options: {
      withoutTime: true,
    },
  },
  {
    title: "Order ID",
    field: "orderId",
    type: FilterTypeEnum.String,
  },
  {
    title: "PSP transaction ID",
    field: "paymentSystemOrderId",
    type: FilterTypeEnum.String,
  },
  {
    title: "External ID",
    field: "externalId",
    type: FilterTypeEnum.String,
  },
  {
    title: "Status",
    field: "status",
    type: FilterTypeEnum.MultiChoice,
  },
  {
    title: "Operation",
    field: "operation",
    type: FilterTypeEnum.MultiChoice,
  },
  // {
  //   title: "Payment system status",
  //   field: "paymentSystemStatus",
  //   type: FilterTypeEnum.MultiChoice,
  // },
  {
    title: "Customer ID",
    field: "customerId",
    type: FilterTypeEnum.String,
  },
  {
    title: "Customer name",
    field: "customerName",
    type: FilterTypeEnum.String,
  },
  {
    title: "Customer email",
    field: "customerEmail",
    type: FilterTypeEnum.String,
  },
  {
    title: "Country",
    field: "country",
    type: FilterTypeEnum.MultiChoice,
  },
  {
    title: "Country of Residence",
    field: "countryOfResidence",
    type: FilterTypeEnum.MultiChoice,
  },
  {
    title: "Country card",
    field: "cardIssuingCountry",
    type: FilterTypeEnum.MultiChoice,
  },
  {
    title: "Payment method",
    field: "paymentMethod",
    type: FilterTypeEnum.MultiChoice,
  },
  {
    title: "Card masked pan",
    field: "cardMaskedPan",
    type: FilterTypeEnum.String,
  },
  {
    title: "Card brand",
    field: "cardBrand",
    type: FilterTypeEnum.MultiChoice,
  },
  // {
  //   title: "Fail reason",
  //   field: "failReason",
  //   type: FilterTypeEnum.String,
  // },
  // {
  //   title: "Payment system decline reason",
  //   field: "paymentSystemDeclineReason",
  //   type: FilterTypeEnum.String,
  // },
  // {
  //   title: "Payment system extended decline reason",
  //   field: "paymentSystemExtendedDeclineReason",
  //   type: FilterTypeEnum.String,
  // },
  // {
  //   title: "Payment system decline code",
  //   field: "paymentSystemDeclineCode",
  //   type: FilterTypeEnum.String,
  // },
  {
    title: "Client pay amount",
    field: "clientPayAmount",
    type: FilterTypeEnum.Amount,
  },
  {
    title: "Client pay currency",
    field: "clientPayCurrency",
    type: FilterTypeEnum.MultiChoice,
  },
  {
    title: "Client get amount",
    field: "clientGetAmount",
    type: FilterTypeEnum.Amount,
  },
  {
    title: "Client get currency",
    field: "clientGetCurrency",
    type: FilterTypeEnum.MultiChoice,
  },
  {
    title: "Client pay system amount",
    field: "clientPaySystemAmount",
    type: FilterTypeEnum.Amount,
  },
  // {
  //   title: "Wallet address",
  //   field: "walletAddress",
  //   type: FilterTypeEnum.String,
  // },
  // {
  //   title: "Withdrawal hashes",
  //   field: "withdrawalHashes",
  //   type: FilterTypeEnum.String,
  // },
  // {
  //   title: "Assets external payment ID",
  //   field: "assetsExternalPaymentId",
  //   type: FilterTypeEnum.String,
  // },
  // {
  //   title: "Withdrawal external payment ID",
  //   field: "withdrawalExternalPaymentId",
  //   type: FilterTypeEnum.String,
  // },
]
