import React, { useCallback } from "react"
import { useContextSelector } from "use-context-selector"

import { useTranslate } from "infrastructure/hooks/useTranslate"

import { FormModeEnum } from "domain/enums/FormModeEnum"
import { ApiKeyContext } from "domain/apiKey/ApiKeyContext"
import { apiKeySelectors } from "domain/apiKey/selectors"
import { FormContainer } from "domain/core/containers/FormContainer"
import { dataToEntity } from "domain/apiKey/mappers"

import { ApiKeyForm } from "view/pages/ApiKeyPage/components/ApiKeyForm"

const initialValues = {
  accessKey: "",
  secretKey: "",
}

export const FormApiKeyContainer = () => {
  const item = useContextSelector(ApiKeyContext, apiKeySelectors.item)
  const update = useContextSelector(ApiKeyContext, apiKeySelectors.update)

  const cbSuccess = useCallback(
    (data) => {
      update({
        item: {
          ...item,
          data: dataToEntity(data),
        },
      })
    },
    [update]
  )

  return (
    <FormContainer
      mode={FormModeEnum.Add}
      initialValues={{ ...initialValues, ...item?.data }}
      url="/v1/access"
      mapToReqModel={() => ({})}
      Form={ApiKeyForm}
      formProps={{
        isLoading: item.isLoading,
        actionButtonTitle: useTranslate("pages.apiKey.addNew"),
        secretKey: item?.data?.secretKey,
        accessKey: item?.data?.accessKey,
      }}
      cbSuccess={cbSuccess}
    />
  )
}
