import React from "react"
import ReactDOM from "react-dom/client"

import { Providers } from "domain/Providers"
import { keycloakService } from "domain/identity/KeycloakService"
import { localizationService } from "infrastructure/services/LocalizationService"

import { App } from "view/App"

import "view/themes/index.css"
import { ErrorBoundary } from "view/ErrorBoundary"
import reportWebVitals from "./reportWebVitals"

keycloakService.checkAuth().then(() => {
  localizationService.init().then(() => {
    const root = ReactDOM.createRoot(document.getElementById("root"))
    root.render(
      <ErrorBoundary>
        <Providers>
          <App />
        </Providers>
      </ErrorBoundary>
    )

    reportWebVitals()
  })
})
