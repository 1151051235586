import { styled } from "@mui/material"

export const Grid = styled("div")(() => ({
  display: "grid",
  gridTemplateColumns: "repeat(3, minmax(350px, 1fr))",
  gridGap: 16,
}))

export const Container = styled("div")(() => ({
  overflow: "auto",
}))

export const DataTitle = styled("div")(() => ({
  margin: "16px 0",
  position: "sticky",
  top: 0,
  left: 0,
  fontSize: 14,
  fontWeight: 500,
}))
