import React from "react"
import { Tabs } from "@unlimint/admin-ui-kit"
import { useContextSelector } from "use-context-selector"

import { useTranslate } from "infrastructure/hooks/useTranslate"

import { TransactionContext } from "domain/transaction/TransactionContext"
import { transactionSelectors } from "domain/transaction/selectors"
import { PnlDataGrid } from "view/components/PnlDataGrid"

import * as S from "./styled"

export const TransactionTabs = () => {
  const { data } = useContextSelector(
    TransactionContext,
    transactionSelectors.item
  )

  const tabs = [
    {
      value: "pnl",
      label: useTranslate("pages.transaction.tabs.pnl"),
    },
    {
      value: "pnlSystem",
      label: useTranslate("pages.transaction.tabs.pnlSystem"),
    },
  ]

  const tabsContents = [
    {
      value: "pnl",
      content: (
        <S.Wrapper>
          <PnlDataGrid data={data?.rawPnlData?.merchantPnl} />
        </S.Wrapper>
      ),
    },
    {
      value: "pnlSystem",
      content: (
        <S.Wrapper>
          <PnlDataGrid data={data?.rawPnlData?.merchantPnlSystem} />
        </S.Wrapper>
      ),
    },
  ]

  return <Tabs initialTab="pnl" tabs={tabs} tabsContents={tabsContents} />
}
