import { useEffect, useState } from "react"

import { ApiMethodEnum } from "infrastructure/enums/ApiMethodEnum"

import { useRequest } from "domain/core/hooks/useRequest"
import { TLogItem } from "domain/webhook/types"
import { mapLogsData } from "domain/webhook/mappers"

type TUseFetchEventLogsProps = {
  webhookId: string
  eventId: string
}

type TUseFetchEventLogs = {
  cbLoadMore: () => void
  isLoading: boolean
  isLoadMore: boolean
  list: {
    data: TLogItem[]
    groupCounts: number[]
    groups: string[]
  }
}

const limit = 100

export const useFetchEventLogs = ({
  webhookId,
  eventId,
}: TUseFetchEventLogsProps): TUseFetchEventLogs => {
  const [getEventId, setEventId] = useState("")
  const [list, setList] = useState({
    data: [],
    groupCounts: [],
    groups: [],
  })
  const [total, setTotal] = useState(0)
  const [loadedCount, setLoadedCount] = useState(0)

  const {
    data,
    send: sendRequest,
    isLoading,
  } = useRequest({
    url: `/v1/webhooks/${webhookId}/events/${eventId}/logs/list`,
    method: ApiMethodEnum.Post,
    body: {
      pagination: {
        limit,
        offset: loadedCount * limit,
      },
    },
    isCancelMode: true,
  })

  useEffect(() => {
    setList({
      data: [],
      groupCounts: [],
      groups: [],
    })
    setLoadedCount(0)
    setTotal(0)
    setEventId(eventId)
  }, [eventId])

  useEffect(() => {
    if (getEventId) sendRequest()
  }, [getEventId])

  useEffect(() => {
    if (data?.list) {
      const newState = !loadedCount ? data?.list : [...list.data, ...data?.list]

      const mappedData = mapLogsData(newState)

      setTotal(data.total)
      setList(mappedData)
      setLoadedCount((prev) => prev + 1)
    }
  }, [data])

  const cbLoadMore = () => {
    sendRequest()
  }

  return {
    cbLoadMore,
    isLoading: !list.data.length && isLoading,
    isLoadMore: list.data.length < total,
    list,
  }
}
