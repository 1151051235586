import { AppProvider } from "domain/application/AppContext"
import { UserProvider } from "domain/user/UserContext"
import { AlertProvider } from "domain/alerts/AlertContext"

const providerList = [[AppProvider], [UserProvider], [AlertProvider]]

export const Providers = ({ children }) => {
  return providerList.reduce((acc, item) => {
    const [Provider, props] = item

    if (props) return <Provider {...props}>{acc}</Provider>

    return <Provider>{acc}</Provider>
  }, children)
}
